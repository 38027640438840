@media screen and (max-width: 362px) {
    .phoneNumber {
        font-size: .80rem;
    }

    .btni18n {
        font-size: .6rem;
    }

}

@media screen and (max-width: 983px) {
    .navbarSmallScreen {
        padding: .5rem;
    }
}

@media screen and (max-width: 301px) {
    .phoneNumber {
        font-size: .75rem;
    }

    .btni18n {
        font-size: .5rem;
    }

}

@media screen and (max-height: 460px) {
    .phoneNumber {
        font-size: .70rem;
    }

    .btni18n {
        font-size: .4rem;
    }

}

@media screen and (max-height: 460px) {
    .navbarSmallScreen {
        padding: .5rem;
    }
}