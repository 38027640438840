.service-grid {
    /* background-color: #fff; */
    padding: 15px;
    /* border-radius: 8px; */
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    transition: none;
    cursor: default;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.service-grid .service-img-container {
    width: 100%;
    overflow: hidden;
    border-radius: 8px;
    margin-bottom: 15px;
    width: 100%;
    height: 200px;
}

.service-grid .service-img-container img {
    width: 100%;
    height: auto;
}

.service-grid .service-info h2 {
    font-size: 1.25rem;
    margin-bottom: 10px;
}

.service-grid .service-info p {
    font-size: 1rem;
    color: #666;
}