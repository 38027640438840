@charset "UTF-8";
@import url(https://fonts.googleapis.com/css?family=Arimo:400,700|Roboto:300i,400,400i,500,700|Material+Icons&display=swap);

/*!
 * Bootstrap v4.5.0 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #FDB813;
  --green: #9DC41E;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --black: #000;
  --primary: #3DB36B;
  --secondary: #f9f9f9;
  --success: #9DC41E;
  --info: #4184F3;
  --warning: #FDB813;
  --danger: #dc3545;
  --light: #EFEFEF;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
}

*,
::after,
::before {
  box-sizing: border-box
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block
}

body {
  margin: 0;
  font-family: Roboto, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.625;
  color: #212529;
  text-align: left;
  background-color: #fff
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: .5rem
}

p {
  margin-top: 0;
  margin-bottom: 1rem
}

abbr[data-original-title],
abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0
}

dt {
  font-weight: 700
}

dd {
  margin-bottom: .5rem;
  margin-left: 0
}

blockquote {
  margin: 0 0 1rem
}

b,
strong {
  font-weight: bolder
}

small {
  font-size: 80%
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline
}

sub {
  bottom: -.25em
}

sup {
  top: -.5em
}

a {
  color: #3db36b;
  text-decoration: none;
  background-color: transparent
}

a:hover {
  color: #2a7a49;
  text-decoration: underline
}

a:not([href]) {
  color: inherit;
  text-decoration: none
}

a:not([href]):hover {
  color: inherit;
  text-decoration: none
}

code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar
}

figure {
  margin: 0 0 1rem
}

img {
  vertical-align: middle;
  border-style: none
}

svg {
  overflow: hidden;
  vertical-align: middle
}

table {
  border-collapse: collapse
}

caption {
  padding-top: .75rem;
  padding-bottom: .75rem;
  color: #9b9b9b;
  text-align: left;
  caption-side: bottom
}

th {
  text-align: inherit
}

label {
  display: inline-block;
  margin-bottom: .5rem
}

button {
  border-radius: 0
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit
}

button,
input {
  overflow: visible
}

button,
select {
  text-transform: none
}

[role=button] {
  cursor: pointer
}

select {
  word-wrap: normal
}

[type=button],
[type=reset],
[type=submit],
button {
  -webkit-appearance: button
}

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
  cursor: pointer
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border-style: none
}

input[type=checkbox],
input[type=radio] {
  box-sizing: border-box;
  padding: 0
}

textarea {
  overflow: auto;
  resize: vertical
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal
}

progress {
  vertical-align: baseline
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button
}

output {
  display: inline-block
}

summary {
  display: list-item;
  cursor: pointer
}

template {
  display: none
}

[hidden] {
  display: none !important
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2
}

.h1,
h1 {
  font-size: 3.125rem
}

.h2,
h2 {
  font-size: 2.5rem
}

.h3,
h3 {
  font-size: 1.5rem
}

.h4,
h4 {
  font-size: 1.125rem
}

.h5,
h5 {
  font-size: 1rem
}

.h6,
h6 {
  font-size: .875rem
}

.lead {
  font-size: 1.25rem;
  font-weight: 300
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, .1)
}

.small,
small {
  font-size: 80%;
  font-weight: 400
}

.mark,
mark {
  padding: .2em;
  background-color: #fcf8e3
}

.list-unstyled {
  padding-left: 0;
  list-style: none
}

.list-inline {
  padding-left: 0;
  list-style: none
}

.list-inline-item {
  display: inline-block
}

.list-inline-item:not(:last-child) {
  margin-right: .5rem
}

.initialism {
  font-size: 90%;
  text-transform: uppercase
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d
}

.blockquote-footer::before {
  content: "\2014\00A0"
}

.img-fluid {
  max-width: 100%;
  height: auto
}

.img-thumbnail {
  padding: .25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  max-width: 100%;
  height: auto
}

.figure {
  display: inline-block
}

.figure-img {
  margin-bottom: .5rem;
  line-height: 1
}

.figure-caption {
  font-size: 90%;
  color: #6c757d
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word
}

a>code {
  color: inherit
}

kbd {
  padding: .2rem .4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto
}

@media (min-width:576px) {
  .container {
    max-width: 540px
  }
}

@media (min-width:768px) {
  .container {
    max-width: 720px
  }
}

@media (min-width:992px) {
  .container {
    max-width: 960px
  }
}

@media (min-width:1200px) {
  .container {
    max-width: 1140px
  }
}

.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto
}

@media (min-width:576px) {

  .container,
  .container-sm {
    max-width: 540px
  }
}

@media (min-width:768px) {

  .container,
  .container-md,
  .container-sm {
    max-width: 720px
  }
}

@media (min-width:992px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px
  }
}

@media (min-width:1200px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px
  }
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px
}

.no-gutters {
  margin-right: 0;
  margin-left: 0
}

.no-gutters>.col,
.no-gutters>[class*=col-] {
  padding-right: 0;
  padding-left: 0
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%
}

.row-cols-1>* {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%
}

.row-cols-2>* {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%
}

.row-cols-3>* {
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%
}

.row-cols-4>* {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%
}

.row-cols-5>* {
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%
}

.row-cols-6>* {
  -ms-flex: 0 0 16.66667%;
  flex: 0 0 16.66667%;
  max-width: 16.66667%
}

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%
}

.col-1 {
  -ms-flex: 0 0 8.33333%;
  flex: 0 0 8.33333%;
  max-width: 8.33333%
}

.col-2 {
  -ms-flex: 0 0 16.66667%;
  flex: 0 0 16.66667%;
  max-width: 16.66667%
}

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%
}

.col-4 {
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%
}

.col-5 {
  -ms-flex: 0 0 41.66667%;
  flex: 0 0 41.66667%;
  max-width: 41.66667%
}

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%
}

.col-7 {
  -ms-flex: 0 0 58.33333%;
  flex: 0 0 58.33333%;
  max-width: 58.33333%
}

.col-8 {
  -ms-flex: 0 0 66.66667%;
  flex: 0 0 66.66667%;
  max-width: 66.66667%
}

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%
}

.col-10 {
  -ms-flex: 0 0 83.33333%;
  flex: 0 0 83.33333%;
  max-width: 83.33333%
}

.col-11 {
  -ms-flex: 0 0 91.66667%;
  flex: 0 0 91.66667%;
  max-width: 91.66667%
}

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%
}

.order-first {
  -ms-flex-order: -1;
  order: -1
}

.order-last {
  -ms-flex-order: 13;
  order: 13
}

.order-0 {
  -ms-flex-order: 0;
  order: 0
}

.order-1 {
  -ms-flex-order: 1;
  order: 1
}

.order-2 {
  -ms-flex-order: 2;
  order: 2
}

.order-3 {
  -ms-flex-order: 3;
  order: 3
}

.order-4 {
  -ms-flex-order: 4;
  order: 4
}

.order-5 {
  -ms-flex-order: 5;
  order: 5
}

.order-6 {
  -ms-flex-order: 6;
  order: 6
}

.order-7 {
  -ms-flex-order: 7;
  order: 7
}

.order-8 {
  -ms-flex-order: 8;
  order: 8
}

.order-9 {
  -ms-flex-order: 9;
  order: 9
}

.order-10 {
  -ms-flex-order: 10;
  order: 10
}

.order-11 {
  -ms-flex-order: 11;
  order: 11
}

.order-12 {
  -ms-flex-order: 12;
  order: 12
}

.offset-1 {
  margin-left: 8.33333%
}

.offset-2 {
  margin-left: 16.66667%
}

.offset-3 {
  margin-left: 25%
}

.offset-4 {
  margin-left: 33.33333%
}

.offset-5 {
  margin-left: 41.66667%
}

.offset-6 {
  margin-left: 50%
}

.offset-7 {
  margin-left: 58.33333%
}

.offset-8 {
  margin-left: 66.66667%
}

.offset-9 {
  margin-left: 75%
}

.offset-10 {
  margin-left: 83.33333%
}

.offset-11 {
  margin-left: 91.66667%
}

@media (min-width:576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%
  }

  .row-cols-sm-1>* {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%
  }

  .row-cols-sm-2>* {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%
  }

  .row-cols-sm-3>* {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%
  }

  .row-cols-sm-4>* {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%
  }

  .row-cols-sm-5>* {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%
  }

  .row-cols-sm-6>* {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%
  }

  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%
  }

  .col-sm-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%
  }

  .col-sm-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%
  }

  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%
  }

  .col-sm-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%
  }

  .col-sm-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%
  }

  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%
  }

  .col-sm-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%
  }

  .col-sm-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%
  }

  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%
  }

  .col-sm-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%
  }

  .col-sm-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%
  }

  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%
  }

  .order-sm-first {
    -ms-flex-order: -1;
    order: -1
  }

  .order-sm-last {
    -ms-flex-order: 13;
    order: 13
  }

  .order-sm-0 {
    -ms-flex-order: 0;
    order: 0
  }

  .order-sm-1 {
    -ms-flex-order: 1;
    order: 1
  }

  .order-sm-2 {
    -ms-flex-order: 2;
    order: 2
  }

  .order-sm-3 {
    -ms-flex-order: 3;
    order: 3
  }

  .order-sm-4 {
    -ms-flex-order: 4;
    order: 4
  }

  .order-sm-5 {
    -ms-flex-order: 5;
    order: 5
  }

  .order-sm-6 {
    -ms-flex-order: 6;
    order: 6
  }

  .order-sm-7 {
    -ms-flex-order: 7;
    order: 7
  }

  .order-sm-8 {
    -ms-flex-order: 8;
    order: 8
  }

  .order-sm-9 {
    -ms-flex-order: 9;
    order: 9
  }

  .order-sm-10 {
    -ms-flex-order: 10;
    order: 10
  }

  .order-sm-11 {
    -ms-flex-order: 11;
    order: 11
  }

  .order-sm-12 {
    -ms-flex-order: 12;
    order: 12
  }

  .offset-sm-0 {
    margin-left: 0
  }

  .offset-sm-1 {
    margin-left: 8.33333%
  }

  .offset-sm-2 {
    margin-left: 16.66667%
  }

  .offset-sm-3 {
    margin-left: 25%
  }

  .offset-sm-4 {
    margin-left: 33.33333%
  }

  .offset-sm-5 {
    margin-left: 41.66667%
  }

  .offset-sm-6 {
    margin-left: 50%
  }

  .offset-sm-7 {
    margin-left: 58.33333%
  }

  .offset-sm-8 {
    margin-left: 66.66667%
  }

  .offset-sm-9 {
    margin-left: 75%
  }

  .offset-sm-10 {
    margin-left: 83.33333%
  }

  .offset-sm-11 {
    margin-left: 91.66667%
  }
}

@media (min-width:768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%
  }

  .row-cols-md-1>* {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%
  }

  .row-cols-md-2>* {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%
  }

  .row-cols-md-3>* {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%
  }

  .row-cols-md-4>* {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%
  }

  .row-cols-md-5>* {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%
  }

  .row-cols-md-6>* {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%
  }

  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%
  }

  .col-md-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%
  }

  .col-md-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%
  }

  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%
  }

  .col-md-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%
  }

  .col-md-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%
  }

  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%
  }

  .col-md-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%
  }

  .col-md-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%
  }

  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%
  }

  .col-md-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%
  }

  .col-md-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%
  }

  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%
  }

  .order-md-first {
    -ms-flex-order: -1;
    order: -1
  }

  .order-md-last {
    -ms-flex-order: 13;
    order: 13
  }

  .order-md-0 {
    -ms-flex-order: 0;
    order: 0
  }

  .order-md-1 {
    -ms-flex-order: 1;
    order: 1
  }

  .order-md-2 {
    -ms-flex-order: 2;
    order: 2
  }

  .order-md-3 {
    -ms-flex-order: 3;
    order: 3
  }

  .order-md-4 {
    -ms-flex-order: 4;
    order: 4
  }

  .order-md-5 {
    -ms-flex-order: 5;
    order: 5
  }

  .order-md-6 {
    -ms-flex-order: 6;
    order: 6
  }

  .order-md-7 {
    -ms-flex-order: 7;
    order: 7
  }

  .order-md-8 {
    -ms-flex-order: 8;
    order: 8
  }

  .order-md-9 {
    -ms-flex-order: 9;
    order: 9
  }

  .order-md-10 {
    -ms-flex-order: 10;
    order: 10
  }

  .order-md-11 {
    -ms-flex-order: 11;
    order: 11
  }

  .order-md-12 {
    -ms-flex-order: 12;
    order: 12
  }

  .offset-md-0 {
    margin-left: 0
  }

  .offset-md-1 {
    margin-left: 8.33333%
  }

  .offset-md-2 {
    margin-left: 16.66667%
  }

  .offset-md-3 {
    margin-left: 25%
  }

  .offset-md-4 {
    margin-left: 33.33333%
  }

  .offset-md-5 {
    margin-left: 41.66667%
  }

  .offset-md-6 {
    margin-left: 50%
  }

  .offset-md-7 {
    margin-left: 58.33333%
  }

  .offset-md-8 {
    margin-left: 66.66667%
  }

  .offset-md-9 {
    margin-left: 75%
  }

  .offset-md-10 {
    margin-left: 83.33333%
  }

  .offset-md-11 {
    margin-left: 91.66667%
  }
}

@media (min-width:992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%
  }

  .row-cols-lg-1>* {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%
  }

  .row-cols-lg-2>* {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%
  }

  .row-cols-lg-3>* {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%
  }

  .row-cols-lg-4>* {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%
  }

  .row-cols-lg-5>* {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%
  }

  .row-cols-lg-6>* {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%
  }

  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%
  }

  .col-lg-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%
  }

  .col-lg-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%
  }

  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%
  }

  .col-lg-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%
  }

  .col-lg-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%
  }

  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%
  }

  .col-lg-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%
  }

  .col-lg-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%
  }

  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%
  }

  .col-lg-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%
  }

  .col-lg-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%
  }

  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%
  }

  .order-lg-first {
    -ms-flex-order: -1;
    order: -1
  }

  .order-lg-last {
    -ms-flex-order: 13;
    order: 13
  }

  .order-lg-0 {
    -ms-flex-order: 0;
    order: 0
  }

  .order-lg-1 {
    -ms-flex-order: 1;
    order: 1
  }

  .order-lg-2 {
    -ms-flex-order: 2;
    order: 2
  }

  .order-lg-3 {
    -ms-flex-order: 3;
    order: 3
  }

  .order-lg-4 {
    -ms-flex-order: 4;
    order: 4
  }

  .order-lg-5 {
    -ms-flex-order: 5;
    order: 5
  }

  .order-lg-6 {
    -ms-flex-order: 6;
    order: 6
  }

  .order-lg-7 {
    -ms-flex-order: 7;
    order: 7
  }

  .order-lg-8 {
    -ms-flex-order: 8;
    order: 8
  }

  .order-lg-9 {
    -ms-flex-order: 9;
    order: 9
  }

  .order-lg-10 {
    -ms-flex-order: 10;
    order: 10
  }

  .order-lg-11 {
    -ms-flex-order: 11;
    order: 11
  }

  .order-lg-12 {
    -ms-flex-order: 12;
    order: 12
  }

  .offset-lg-0 {
    margin-left: 0
  }

  .offset-lg-1 {
    margin-left: 8.33333%
  }

  .offset-lg-2 {
    margin-left: 16.66667%
  }

  .offset-lg-3 {
    margin-left: 25%
  }

  .offset-lg-4 {
    margin-left: 33.33333%
  }

  .offset-lg-5 {
    margin-left: 41.66667%
  }

  .offset-lg-6 {
    margin-left: 50%
  }

  .offset-lg-7 {
    margin-left: 58.33333%
  }

  .offset-lg-8 {
    margin-left: 66.66667%
  }

  .offset-lg-9 {
    margin-left: 75%
  }

  .offset-lg-10 {
    margin-left: 83.33333%
  }

  .offset-lg-11 {
    margin-left: 91.66667%
  }
}

@media (min-width:1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%
  }

  .row-cols-xl-1>* {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%
  }

  .row-cols-xl-2>* {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%
  }

  .row-cols-xl-3>* {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%
  }

  .row-cols-xl-4>* {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%
  }

  .row-cols-xl-5>* {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%
  }

  .row-cols-xl-6>* {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%
  }

  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%
  }

  .col-xl-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%
  }

  .col-xl-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%
  }

  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%
  }

  .col-xl-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%
  }

  .col-xl-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%
  }

  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%
  }

  .col-xl-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%
  }

  .col-xl-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%
  }

  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%
  }

  .col-xl-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%
  }

  .col-xl-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%
  }

  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%
  }

  .order-xl-first {
    -ms-flex-order: -1;
    order: -1
  }

  .order-xl-last {
    -ms-flex-order: 13;
    order: 13
  }

  .order-xl-0 {
    -ms-flex-order: 0;
    order: 0
  }

  .order-xl-1 {
    -ms-flex-order: 1;
    order: 1
  }

  .order-xl-2 {
    -ms-flex-order: 2;
    order: 2
  }

  .order-xl-3 {
    -ms-flex-order: 3;
    order: 3
  }

  .order-xl-4 {
    -ms-flex-order: 4;
    order: 4
  }

  .order-xl-5 {
    -ms-flex-order: 5;
    order: 5
  }

  .order-xl-6 {
    -ms-flex-order: 6;
    order: 6
  }

  .order-xl-7 {
    -ms-flex-order: 7;
    order: 7
  }

  .order-xl-8 {
    -ms-flex-order: 8;
    order: 8
  }

  .order-xl-9 {
    -ms-flex-order: 9;
    order: 9
  }

  .order-xl-10 {
    -ms-flex-order: 10;
    order: 10
  }

  .order-xl-11 {
    -ms-flex-order: 11;
    order: 11
  }

  .order-xl-12 {
    -ms-flex-order: 12;
    order: 12
  }

  .offset-xl-0 {
    margin-left: 0
  }

  .offset-xl-1 {
    margin-left: 8.33333%
  }

  .offset-xl-2 {
    margin-left: 16.66667%
  }

  .offset-xl-3 {
    margin-left: 25%
  }

  .offset-xl-4 {
    margin-left: 33.33333%
  }

  .offset-xl-5 {
    margin-left: 41.66667%
  }

  .offset-xl-6 {
    margin-left: 50%
  }

  .offset-xl-7 {
    margin-left: 58.33333%
  }

  .offset-xl-8 {
    margin-left: 66.66667%
  }

  .offset-xl-9 {
    margin-left: 75%
  }

  .offset-xl-10 {
    margin-left: 83.33333%
  }

  .offset-xl-11 {
    margin-left: 91.66667%
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529
}

.table td,
.table th {
  padding: .75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6
}

.table tbody+tbody {
  border-top: 2px solid #dee2e6
}

.table-sm td,
.table-sm th {
  padding: .3rem
}

.table-bordered {
  border: 1px solid #dee2e6
}

.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6
}

.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px
}

.table-borderless tbody+tbody,
.table-borderless td,
.table-borderless th,
.table-borderless thead th {
  border: 0
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05)
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, .075)
}

.table-primary,
.table-primary>td,
.table-primary>th {
  background-color: #c9ead6
}

.table-primary tbody+tbody,
.table-primary td,
.table-primary th,
.table-primary thead th {
  border-color: #9ad7b2
}

.table-hover .table-primary:hover {
  background-color: #b7e3c8
}

.table-hover .table-primary:hover>td,
.table-hover .table-primary:hover>th {
  background-color: #b7e3c8
}

.table-secondary,
.table-secondary>td,
.table-secondary>th {
  background-color: #fdfdfd
}

.table-secondary tbody+tbody,
.table-secondary td,
.table-secondary th,
.table-secondary thead th {
  border-color: #fcfcfc
}

.table-hover .table-secondary:hover {
  background-color: #f0f0f0
}

.table-hover .table-secondary:hover>td,
.table-hover .table-secondary:hover>th {
  background-color: #f0f0f0
}

.table-success,
.table-success>td,
.table-success>th {
  background-color: #c3e6cb
}

.table-success tbody+tbody,
.table-success td,
.table-success th,
.table-success thead th {
  border-color: #8fd19e
}

.table-hover .table-success:hover {
  background-color: #b1dfbb
}

.table-hover .table-success:hover>td,
.table-hover .table-success:hover>th {
  background-color: #b1dfbb
}

.table-info,
.table-info>td,
.table-info>th {
  background-color: #caddfc
}

.table-info tbody+tbody,
.table-info td,
.table-info th,
.table-info thead th {
  border-color: #9cbff9
}

.table-hover .table-info:hover {
  background-color: #b2cefb
}

.table-hover .table-info:hover>td,
.table-hover .table-info:hover>th {
  background-color: #b2cefb
}

.table-warning,
.table-warning>td,
.table-warning>th {
  background-color: #feebbd
}

.table-warning tbody+tbody,
.table-warning td,
.table-warning th,
.table-warning thead th {
  border-color: #feda84
}

.table-hover .table-warning:hover {
  background-color: #fee3a4
}

.table-hover .table-warning:hover>td,
.table-hover .table-warning:hover>th {
  background-color: #fee3a4
}

.table-danger,
.table-danger>td,
.table-danger>th {
  background-color: #f5c6cb
}

.table-danger tbody+tbody,
.table-danger td,
.table-danger th,
.table-danger thead th {
  border-color: #ed969e
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7
}

.table-hover .table-danger:hover>td,
.table-hover .table-danger:hover>th {
  background-color: #f1b0b7
}

.table-light,
.table-light>td,
.table-light>th {
  background-color: #fbfbfb
}

.table-light tbody+tbody,
.table-light td,
.table-light th,
.table-light thead th {
  border-color: #f7f7f7
}

.table-hover .table-light:hover {
  background-color: #eee
}

.table-hover .table-light:hover>td,
.table-hover .table-light:hover>th {
  background-color: #eee
}

.table-dark,
.table-dark>td,
.table-dark>th {
  background-color: #c6c8ca
}

.table-dark tbody+tbody,
.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #95999c
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe
}

.table-hover .table-dark:hover>td,
.table-hover .table-dark:hover>th {
  background-color: #b9bbbe
}

.table-active,
.table-active>td,
.table-active>th {
  background-color: rgba(0, 0, 0, .075)
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, .075)
}

.table-hover .table-active:hover>td,
.table-hover .table-active:hover>th {
  background-color: rgba(0, 0, 0, .075)
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6
}

.table-dark {
  color: #fff;
  background-color: #343a40
}

.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #454d55
}

.table-dark.table-bordered {
  border: 0
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, .05)
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, .075)
}

@media (max-width:575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
  }

  .table-responsive-sm>.table-bordered {
    border: 0
  }
}

@media (max-width:767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
  }

  .table-responsive-md>.table-bordered {
    border: 0
  }
}

@media (max-width:991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
  }

  .table-responsive-lg>.table-bordered {
    border: 0
  }
}

@media (max-width:1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
  }

  .table-responsive-xl>.table-bordered {
    border: 0
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch
}

.table-responsive>.table-bordered {
  border: 0
}

.form-control {
  display: block;
  width: 100%;
  height: 50px;
  padding: .5rem 1rem;
  font-family: Roboto, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d3d3d3;
  border-radius: 0;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
  .form-control {
    transition: none
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0
}

.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #95dbb0;
  outline: 0;
  box-shadow: none
}

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1
}

.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1
}

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1
}

.form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1
}

input[type=date].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control,
input[type=time].form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%
}

.col-form-label {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5
}

.col-form-label-lg {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: 1rem;
  line-height: 1.5
}

.col-form-label-sm {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: 1rem;
  line-height: 1.375rem
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: .5rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0
}

.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
  padding-right: 0;
  padding-left: 0
}

.form-control-sm {
  height: 40px;
  padding: .5rem .5rem;
  font-size: 1rem;
  line-height: 1.375rem
}

.form-control-lg {
  height: 60px;
  padding: .5rem 1rem;
  font-size: 1rem;
  line-height: 1.5
}

select.form-control[multiple],
select.form-control[size] {
  height: auto
}

textarea.form-control {
  height: auto
}

.form-group {
  margin-bottom: 1.25rem
}

.form-text {
  display: block;
  margin-top: .25rem
}

.form-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px
}

.form-row>.col,
.form-row>[class*=col-] {
  padding-right: 5px;
  padding-left: 5px
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem
}

.form-check-input {
  position: absolute;
  margin-top: .3rem;
  margin-left: -1.25rem
}

.form-check-input:disabled~.form-check-label,
.form-check-input[disabled]~.form-check-label {
  color: #9b9b9b
}

.form-check-label {
  margin-bottom: 0
}

.form-check-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: .75rem
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: .3125rem;
  margin-left: 0
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #9DC41E
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .25rem .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1.625;
  color: #fff;
  background-color: rgba(40, 167, 69, .9)
}

.is-valid~.valid-feedback,
.is-valid~.valid-tooltip,
.was-validated :valid~.valid-feedback,
.was-validated :valid~.valid-tooltip {
  display: block
}

.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: #9DC41E;
  padding-right: calc(1.5em + 1rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(.375em + .25rem) center;
  background-size: calc(.75em + .5rem) calc(.75em + .5rem)
}

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  border-color: #9DC41E;
  box-shadow: 0 0 0 0 rgba(40, 167, 69, .25)
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 1rem);
  background-position: top calc(.375em + .25rem) right calc(.375em + .25rem)
}

.custom-select.is-valid,
.was-validated .custom-select:valid {
  border-color: #9DC41E;
  padding-right: calc(.75em + 2.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 2rem/calc(.75em + .5rem) calc(.75em + .5rem)
}

.custom-select.is-valid:focus,
.was-validated .custom-select:valid:focus {
  border-color: #9DC41E;
  box-shadow: 0 0 0 0 rgba(40, 167, 69, .25)
}

.form-check-input.is-valid~.form-check-label,
.was-validated .form-check-input:valid~.form-check-label {
  color: #9DC41E
}

.form-check-input.is-valid~.valid-feedback,
.form-check-input.is-valid~.valid-tooltip,
.was-validated .form-check-input:valid~.valid-feedback,
.was-validated .form-check-input:valid~.valid-tooltip {
  display: block
}

.custom-control-input.is-valid~.custom-control-label,
.was-validated .custom-control-input:valid~.custom-control-label {
  color: #9DC41E
}

.custom-control-input.is-valid~.custom-control-label::before,
.was-validated .custom-control-input:valid~.custom-control-label::before {
  border-color: #9DC41E
}

.custom-control-input.is-valid:checked~.custom-control-label::before,
.was-validated .custom-control-input:valid:checked~.custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57
}

.custom-control-input.is-valid:focus~.custom-control-label::before,
.was-validated .custom-control-input:valid:focus~.custom-control-label::before {
  box-shadow: 0 0 0 0 rgba(40, 167, 69, .25)
}

.custom-control-input.is-valid:focus:not(:checked)~.custom-control-label::before,
.was-validated .custom-control-input:valid:focus:not(:checked)~.custom-control-label::before {
  border-color: #9DC41E
}

.custom-file-input.is-valid~.custom-file-label,
.was-validated .custom-file-input:valid~.custom-file-label {
  border-color: #9DC41E
}

.custom-file-input.is-valid:focus~.custom-file-label,
.was-validated .custom-file-input:valid:focus~.custom-file-label {
  border-color: #9DC41E;
  box-shadow: 0 0 0 0 rgba(40, 167, 69, .25)
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #dc3545
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .25rem .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1.625;
  color: #fff;
  background-color: rgba(220, 53, 69, .9)
}

.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip,
.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip {
  display: block
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 1rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(.375em + .25rem) center;
  background-size: calc(.75em + .5rem) calc(.75em + .5rem)
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0 rgba(220, 53, 69, .25)
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 1rem);
  background-position: top calc(.375em + .25rem) right calc(.375em + .25rem)
}

.custom-select.is-invalid,
.was-validated .custom-select:invalid {
  border-color: #dc3545;
  padding-right: calc(.75em + 2.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 2rem/calc(.75em + .5rem) calc(.75em + .5rem)
}

.custom-select.is-invalid:focus,
.was-validated .custom-select:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0 rgba(220, 53, 69, .25)
}

.form-check-input.is-invalid~.form-check-label,
.was-validated .form-check-input:invalid~.form-check-label {
  color: #dc3545
}

.form-check-input.is-invalid~.invalid-feedback,
.form-check-input.is-invalid~.invalid-tooltip,
.was-validated .form-check-input:invalid~.invalid-feedback,
.was-validated .form-check-input:invalid~.invalid-tooltip {
  display: block
}

.custom-control-input.is-invalid~.custom-control-label,
.was-validated .custom-control-input:invalid~.custom-control-label {
  color: #dc3545
}

.custom-control-input.is-invalid~.custom-control-label::before,
.was-validated .custom-control-input:invalid~.custom-control-label::before {
  border-color: #dc3545
}

.custom-control-input.is-invalid:checked~.custom-control-label::before,
.was-validated .custom-control-input:invalid:checked~.custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d
}

.custom-control-input.is-invalid:focus~.custom-control-label::before,
.was-validated .custom-control-input:invalid:focus~.custom-control-label::before {
  box-shadow: 0 0 0 0 rgba(220, 53, 69, .25)
}

.custom-control-input.is-invalid:focus:not(:checked)~.custom-control-label::before,
.was-validated .custom-control-input:invalid:focus:not(:checked)~.custom-control-label::before {
  border-color: #dc3545
}

.custom-file-input.is-invalid~.custom-file-label,
.was-validated .custom-file-input:invalid~.custom-file-label {
  border-color: #dc3545
}

.custom-file-input.is-invalid:focus~.custom-file-label,
.was-validated .custom-file-input:invalid:focus~.custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0 rgba(220, 53, 69, .25)
}

.form-inline {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-align: center;
  align-items: center
}

.form-inline .form-check {
  width: 100%
}

@media (min-width:576px) {
  .form-inline label {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0
  }

  .form-inline .form-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0
  }

  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle
  }

  .form-inline .form-control-plaintext {
    display: inline-block
  }

  .form-inline .custom-select,
  .form-inline .input-group {
    width: auto
  }

  .form-inline .form-check {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    padding-left: 0
  }

  .form-inline .form-check-input {
    position: relative;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: .25rem;
    margin-left: 0
  }

  .form-inline .custom-control {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center
  }

  .form-inline .custom-control-label {
    margin-bottom: 0
  }
}

.btn {
  display: inline-block;
  font-family: Arimo, sans-serif;
  font-weight: 700;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .9375rem 1.625rem;
  font-size: .75rem;
  line-height: 1.5;
  border-radius: 0;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
  .btn {
    transition: none
  }
}

.btn:hover {
  color: #212529;
  text-decoration: none
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none
}

.btn.disabled,
.btn:disabled {
  opacity: .65
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none
}

.btn-primary {
  color: #fff;
  background-color: #3db36b;
  border-color: #3db36b
}

.btn-primary:hover {
  color: #fff;
  background-color: #33965a;
  border-color: #308d54
}

.btn-primary.focus,
.btn-primary:focus {
  color: #fff;
  background-color: #33965a;
  border-color: #308d54;
  box-shadow: 0 0 0 0 rgba(90, 190, 129, .5)
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #3db36b;
  border-color: #3db36b
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #308d54;
  border-color: #2d834f
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(90, 190, 129, .5)
}

.btn-secondary {
  color: #212529;
  background-color: #f9f9f9;
  border-color: #f9f9f9
}

.btn-secondary:hover {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #e0e0e0
}

.btn-secondary.focus,
.btn-secondary:focus {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #e0e0e0;
  box-shadow: 0 0 0 0 rgba(217, 217, 218, .5)
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #212529;
  background-color: #f9f9f9;
  border-color: #f9f9f9
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show>.btn-secondary.dropdown-toggle {
  color: #212529;
  background-color: #e0e0e0;
  border-color: #d9d9d9
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(217, 217, 218, .5)
}

.btn-success {
  color: #fff;
  background-color: #9DC41E;
  border-color: #9DC41E
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34
}

.btn-success.focus,
.btn-success:focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0 rgba(72, 180, 97, .5)
}

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #9DC41E;
  border-color: #9DC41E
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show>.btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430
}

.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show>.btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(72, 180, 97, .5)
}

.btn-info {
  color: #fff;
  background-color: #4184f3;
  border-color: #4184f3
}

.btn-info:hover {
  color: #fff;
  background-color: #1d6df1;
  border-color: #1165f0
}

.btn-info.focus,
.btn-info:focus {
  color: #fff;
  background-color: #1d6df1;
  border-color: #1165f0;
  box-shadow: 0 0 0 0 rgba(94, 150, 245, .5)
}

.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #4184f3;
  border-color: #4184f3
}

.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show>.btn-info.dropdown-toggle {
  color: #fff;
  background-color: #1165f0;
  border-color: #0f60e6
}

.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show>.btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(94, 150, 245, .5)
}

.btn-warning {
  color: #212529;
  background-color: #fdb813;
  border-color: #fdb813
}

.btn-warning:hover {
  color: #212529;
  background-color: #e8a402;
  border-color: #db9b02
}

.btn-warning.focus,
.btn-warning:focus {
  color: #212529;
  background-color: #e8a402;
  border-color: #db9b02;
  box-shadow: 0 0 0 0 rgba(220, 162, 22, .5)
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  background-color: #fdb813;
  border-color: #fdb813
}

.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show>.btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #db9b02;
  border-color: #cf9202
}

.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.show>.btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(220, 162, 22, .5)
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130
}

.btn-danger.focus,
.btn-danger:focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0 rgba(225, 83, 97, .5)
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545
}

.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show>.btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d
}

.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.show>.btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(225, 83, 97, .5)
}

.btn-light {
  color: #212529;
  background-color: #efefef;
  border-color: #efefef
}

.btn-light:hover {
  color: #212529;
  background-color: #dcdcdc;
  border-color: #d6d6d6
}

.btn-light.focus,
.btn-light:focus {
  color: #212529;
  background-color: #dcdcdc;
  border-color: #d6d6d6;
  box-shadow: 0 0 0 0 rgba(208, 209, 209, .5)
}

.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #efefef;
  border-color: #efefef
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show>.btn-light.dropdown-toggle {
  color: #212529;
  background-color: #d6d6d6;
  border-color: #cfcfcf
}

.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show>.btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(208, 209, 209, .5)
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124
}

.btn-dark.focus,
.btn-dark:focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0 rgba(82, 88, 93, .5)
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40
}

.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show>.btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d
}

.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show>.btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(82, 88, 93, .5)
}

.btn-outline-primary {
  color: #3db36b;
  border-color: #3db36b
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #3db36b;
  border-color: #3db36b
}

.btn-outline-primary.focus,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0 rgba(61, 179, 107, .5)
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #3db36b;
  background-color: transparent
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show>.btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #3db36b;
  border-color: #3db36b
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(61, 179, 107, .5)
}

.btn-outline-secondary {
  color: #f9f9f9;
  border-color: #f9f9f9
}

.btn-outline-secondary:hover {
  color: #212529;
  background-color: #f9f9f9;
  border-color: #f9f9f9
}

.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0 rgba(249, 249, 249, .5)
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #f9f9f9;
  background-color: transparent
}

.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show>.btn-outline-secondary.dropdown-toggle {
  color: #212529;
  background-color: #f9f9f9;
  border-color: #f9f9f9
}

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(249, 249, 249, .5)
}

.btn-outline-success {
  color: #9DC41E;
  border-color: #9DC41E
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #9DC41E;
  border-color: #9DC41E
}

.btn-outline-success.focus,
.btn-outline-success:focus {
  box-shadow: 0 0 0 0 rgba(40, 167, 69, .5)
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #9DC41E;
  background-color: transparent
}

.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show>.btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #9DC41E;
  border-color: #9DC41E
}

.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(40, 167, 69, .5)
}

.btn-outline-info {
  color: #4184f3;
  border-color: #4184f3
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #4184f3;
  border-color: #4184f3
}

.btn-outline-info.focus,
.btn-outline-info:focus {
  box-shadow: 0 0 0 0 rgba(65, 132, 243, .5)
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #4184f3;
  background-color: transparent
}

.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.show>.btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #4184f3;
  border-color: #4184f3
}

.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(65, 132, 243, .5)
}

.btn-outline-warning {
  color: #fdb813;
  border-color: #fdb813
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #fdb813;
  border-color: #fdb813
}

.btn-outline-warning.focus,
.btn-outline-warning:focus {
  box-shadow: 0 0 0 0 rgba(253, 184, 19, .5)
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #fdb813;
  background-color: transparent
}

.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show>.btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #fdb813;
  border-color: #fdb813
}

.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(253, 184, 19, .5)
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545
}

.btn-outline-danger.focus,
.btn-outline-danger:focus {
  box-shadow: 0 0 0 0 rgba(220, 53, 69, .5)
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent
}

.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.show>.btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545
}

.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(220, 53, 69, .5)
}

.btn-outline-light {
  color: #efefef;
  border-color: #efefef
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #efefef;
  border-color: #efefef
}

.btn-outline-light.focus,
.btn-outline-light:focus {
  box-shadow: 0 0 0 0 rgba(239, 239, 239, .5)
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #efefef;
  background-color: transparent
}

.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show>.btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #efefef;
  border-color: #efefef
}

.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(239, 239, 239, .5)
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40
}

.btn-outline-dark.focus,
.btn-outline-dark:focus {
  box-shadow: 0 0 0 0 rgba(52, 58, 64, .5)
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent
}

.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.show>.btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40
}

.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(52, 58, 64, .5)
}

.btn-link {
  font-weight: 400;
  color: #3db36b;
  text-decoration: none
}

.btn-link:hover {
  color: #2a7a49;
  text-decoration: underline
}

.btn-link.focus,
.btn-link:focus {
  text-decoration: underline
}

.btn-link.disabled,
.btn-link:disabled {
  color: #6c757d;
  pointer-events: none
}

.btn-group-lg>.btn,
.btn-lg {
  padding: 1.25rem 2.65rem;
  font-size: .75rem;
  line-height: 1.5;
  border-radius: 0
}

.btn-group-sm>.btn,
.btn-sm {
  padding: .5rem .5rem;
  font-size: .75rem;
  line-height: 1.375rem;
  border-radius: 0
}

.btn-block {
  display: block;
  width: 100%
}

.btn-block+.btn-block {
  margin-top: .5rem
}

input[type=button].btn-block,
input[type=reset].btn-block,
input[type=submit].btn-block {
  width: 100%
}

.fade {
  transition: opacity .15s linear
}

@media (prefers-reduced-motion:reduce) {
  .fade {
    transition: none
  }
}

.fade:not(.show) {
  opacity: 0
}

.collapse:not(.show) {
  display: none
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height .35s ease
}

@media (prefers-reduced-motion:reduce) {
  .collapsing {
    transition: none
  }
}

.dropdown,
.dropleft,
.dropright,
.dropup {
  position: relative
}

.dropdown-toggle {
  white-space: nowrap
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent
}

.dropdown-toggle:empty::after {
  margin-left: 0
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0 0;
  margin: 0 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, .15)
}

.dropdown-menu-left {
  right: auto;
  left: 0
}

.dropdown-menu-right {
  right: 0;
  left: auto
}

@media (min-width:576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto
  }
}

@media (min-width:768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto
  }
}

@media (min-width:992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto
  }
}

@media (min-width:1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: 0;
  border-right: .3em solid transparent;
  border-bottom: .3em solid;
  border-left: .3em solid transparent
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid transparent;
  border-right: 0;
  border-bottom: .3em solid transparent;
  border-left: .3em solid
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0
}

.dropright .dropdown-toggle::after {
  vertical-align: 0
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: ""
}

.dropleft .dropdown-toggle::after {
  display: none
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid transparent;
  border-right: .3em solid;
  border-bottom: .3em solid transparent
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0
}

.dropdown-menu[x-placement^=bottom],
.dropdown-menu[x-placement^=left],
.dropdown-menu[x-placement^=right],
.dropdown-menu[x-placement^=top] {
  right: auto;
  bottom: auto
}

.dropdown-divider {
  height: 0;
  margin: .5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: .5rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #fff;
  text-decoration: none;
  background-color: #3db36b
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #3db36b
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent
}

.dropdown-menu.show {
  display: block
}

.dropdown-header {
  display: block;
  padding: 0 1rem;
  margin-bottom: 0;
  font-size: .875rem;
  color: #6c757d;
  white-space: nowrap
}

.dropdown-item-text {
  display: block;
  padding: .5rem 1rem;
  color: #212529
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle
}

.btn-group-vertical>.btn,
.btn-group>.btn {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto
}

.btn-group-vertical>.btn:hover,
.btn-group>.btn:hover {
  z-index: 1
}

.btn-group-vertical>.btn.active,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn:focus,
.btn-group>.btn.active,
.btn-group>.btn:active,
.btn-group>.btn:focus {
  z-index: 1
}

.btn-toolbar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: start;
  justify-content: flex-start
}

.btn-toolbar .input-group {
  width: auto
}

.btn-group>.btn-group:not(:first-child),
.btn-group>.btn:not(:first-child) {
  margin-left: -1px
}

.dropdown-toggle-split {
  padding-right: 1.21875rem;
  padding-left: 1.21875rem
}

.dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after {
  margin-left: 0
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0
}

.btn-group-sm>.btn+.dropdown-toggle-split,
.btn-sm+.dropdown-toggle-split {
  padding-right: .375rem;
  padding-left: .375rem
}

.btn-group-lg>.btn+.dropdown-toggle-split,
.btn-lg+.dropdown-toggle-split {
  padding-right: 1.9875rem;
  padding-left: 1.9875rem
}

.btn-group-vertical {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: center;
  justify-content: center
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group {
  width: 100%
}

.btn-group-vertical>.btn-group:not(:first-child),
.btn-group-vertical>.btn:not(:first-child) {
  margin-top: -1px
}

.btn-group-toggle>.btn,
.btn-group-toggle>.btn-group>.btn {
  margin-bottom: 0
}

.btn-group-toggle>.btn input[type=checkbox],
.btn-group-toggle>.btn input[type=radio],
.btn-group-toggle>.btn-group>.btn input[type=checkbox],
.btn-group-toggle>.btn-group>.btn input[type=radio] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none
}

.input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%
}

.input-group>.custom-file,
.input-group>.custom-select,
.input-group>.form-control,
.input-group>.form-control-plaintext {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0
}

.input-group>.custom-file+.custom-file,
.input-group>.custom-file+.custom-select,
.input-group>.custom-file+.form-control,
.input-group>.custom-select+.custom-file,
.input-group>.custom-select+.custom-select,
.input-group>.custom-select+.form-control,
.input-group>.form-control+.custom-file,
.input-group>.form-control+.custom-select,
.input-group>.form-control+.form-control,
.input-group>.form-control-plaintext+.custom-file,
.input-group>.form-control-plaintext+.custom-select,
.input-group>.form-control-plaintext+.form-control {
  margin-left: -1px
}

.input-group>.custom-file .custom-file-input:focus~.custom-file-label,
.input-group>.custom-select:focus,
.input-group>.form-control:focus {
  z-index: 3
}

.input-group>.custom-file .custom-file-input:focus {
  z-index: 4
}

.input-group>.custom-file {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center
}

.input-group-append,
.input-group-prepend {
  display: -ms-flexbox;
  display: flex
}

.input-group-append .btn,
.input-group-prepend .btn {
  position: relative;
  z-index: 2
}

.input-group-append .btn:focus,
.input-group-prepend .btn:focus {
  z-index: 3
}

.input-group-append .btn+.btn,
.input-group-append .btn+.input-group-text,
.input-group-append .input-group-text+.btn,
.input-group-append .input-group-text+.input-group-text,
.input-group-prepend .btn+.btn,
.input-group-prepend .btn+.input-group-text,
.input-group-prepend .input-group-text+.btn,
.input-group-prepend .input-group-text+.input-group-text {
  margin-left: -1px
}

.input-group-prepend {
  margin-right: -1px
}

.input-group-append {
  margin-left: -1px
}

.input-group-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: .5rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #d3d3d3
}

.input-group-text input[type=checkbox],
.input-group-text input[type=radio] {
  margin-top: 0
}

.input-group-lg>.custom-select,
.input-group-lg>.form-control:not(textarea) {
  height: 60px
}

.input-group-lg>.custom-select,
.input-group-lg>.form-control,
.input-group-lg>.input-group-append>.btn,
.input-group-lg>.input-group-append>.input-group-text,
.input-group-lg>.input-group-prepend>.btn,
.input-group-lg>.input-group-prepend>.input-group-text {
  padding: .5rem 1rem;
  font-size: 1rem;
  line-height: 1.5
}

.input-group-sm>.custom-select,
.input-group-sm>.form-control:not(textarea) {
  height: 40px
}

.input-group-sm>.custom-select,
.input-group-sm>.form-control,
.input-group-sm>.input-group-append>.btn,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn,
.input-group-sm>.input-group-prepend>.input-group-text {
  padding: .5rem .5rem;
  font-size: 1rem;
  line-height: 1.375rem
}

.input-group-lg>.custom-select,
.input-group-sm>.custom-select {
  padding-right: 2rem
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.625rem;
  padding-left: 1.5rem
}

.custom-control-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.3125rem;
  opacity: 0
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #3db36b;
  background-color: #3db36b
}

.custom-control-input:focus~.custom-control-label::before {
  box-shadow: none
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
  border-color: #95dbb0
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
  color: #fff;
  background-color: #bbe8cc;
  border-color: #bbe8cc
}

.custom-control-input:disabled~.custom-control-label,
.custom-control-input[disabled]~.custom-control-label {
  color: #6c757d
}

.custom-control-input:disabled~.custom-control-label::before,
.custom-control-input[disabled]~.custom-control-label::before {
  background-color: #e9ecef
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top
}

.custom-control-label::before {
  position: absolute;
  top: .3125rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px
}

.custom-control-label::after {
  position: absolute;
  top: .3125rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50%/50% 50%
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e")
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
  border-color: #3db36b;
  background-color: #3db36b
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e")
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: rgba(61, 179, 107, .5)
}

.custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label::before {
  background-color: rgba(61, 179, 107, .5)
}

.custom-radio .custom-control-label::before {
  border-radius: 50%
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e")
}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: rgba(61, 179, 107, .5)
}

.custom-switch {
  padding-left: 2.25rem
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: .5rem
}

.custom-switch .custom-control-label::after {
  top: calc(.3125rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: .5rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out;
  transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
  .custom-switch .custom-control-label::after {
    transition: none
  }
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
  background-color: #fff;
  -webkit-transform: translateX(.75rem);
  transform: translateX(.75rem)
}

.custom-switch .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: rgba(61, 179, 107, .5)
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: 50px;
  padding: .5rem 2rem .5rem 1rem;
  font-family: Roboto, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px;
  border: 1px solid #d3d3d3;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none
}

.custom-select:focus {
  border-color: #95dbb0;
  outline: 0;
  box-shadow: 0 0 0 0 transparent
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 1rem;
  background-image: none
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef
}

.custom-select::-ms-expand {
  display: none
}

.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057
}

.custom-select-sm {
  height: 40px;
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: .5rem;
  font-size: 1rem
}

.custom-select-lg {
  height: 60px;
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-size: 1rem
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 50px;
  margin-bottom: 0
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 50px;
  margin: 0;
  opacity: 0
}

.custom-file-input:focus~.custom-file-label {
  border-color: #95dbb0;
  box-shadow: none
}

.custom-file-input:disabled~.custom-file-label,
.custom-file-input[disabled]~.custom-file-label {
  background-color: #e9ecef
}

.custom-file-input:lang(en)~.custom-file-label::after {
  content: "Browse"
}

.custom-file-input~.custom-file-label[data-browse]::after {
  content: attr(data-browse)
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 50px;
  padding: .75rem 1rem;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #d3d3d3
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 48px;
  padding: .75rem 1rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit
}

.custom-range {
  width: 100%;
  height: 1rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none
}

.custom-range:focus {
  outline: 0
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, none
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, none
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, none
}

.custom-range::-moz-focus-outer {
  border: 0
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -.25rem;
  background-color: #3db36b;
  border: 0;
  -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  -webkit-appearance: none;
  appearance: none
}

@media (prefers-reduced-motion:reduce) {
  .custom-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #bbe8cc
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: .5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #3db36b;
  border: 0;
  -moz-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  -moz-appearance: none;
  appearance: none
}

@media (prefers-reduced-motion:reduce) {
  .custom-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #bbe8cc
}

.custom-range::-moz-range-track {
  width: 100%;
  height: .5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;
  background-color: #3db36b;
  border: 0;
  -ms-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  appearance: none
}

@media (prefers-reduced-motion:reduce) {
  .custom-range::-ms-thumb {
    -ms-transition: none;
    transition: none
  }
}

.custom-range::-ms-thumb:active {
  background-color: #bbe8cc
}

.custom-range::-ms-track {
  width: 100%;
  height: .5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: .5rem
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd
}

.custom-range:disabled::-moz-range-track {
  cursor: default
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {

  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none
  }
}

.nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none
}

.nav-link {
  display: block;
  padding: .25rem .5rem
}

.nav-link:focus,
.nav-link:hover {
  text-decoration: none
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6
}

.nav-tabs .nav-item {
  margin-bottom: -1px
}

.nav-tabs .nav-link {
  border: 1px solid transparent
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #dee2e6
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff
}

.nav-tabs .dropdown-menu {
  margin-top: -1px
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #fff;
  background-color: #3db36b
}

.nav-fill .nav-item {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center
}

.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center
}

.tab-content>.tab-pane {
  display: none
}

.tab-content>.active {
  display: block
}

.navbar {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 2.25rem 1rem
}

.navbar .container,
.navbar .container-fluid,
.navbar .container-lg,
.navbar .container-md,
.navbar .container-sm,
.navbar .container-xl {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between
}

.navbar-brand {
  display: inline-block;
  margin-right: 1rem;
  font-size: 1.875rem;
  line-height: inherit;
  white-space: nowrap
}

.navbar-brand:focus,
.navbar-brand:hover {
  text-decoration: none
}

.navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none
}

.navbar-text {
  display: inline-block;
  padding-top: .5rem;
  padding-bottom: .5rem
}

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-align: center;
  align-items: center
}

.navbar-toggler {
  padding: .25rem .75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent
}

.navbar-toggler:focus,
.navbar-toggler:hover {
  text-decoration: none
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%
}

@media (max-width:575.98px) {

  .navbar-expand-sm>.container,
  .navbar-expand-sm>.container-fluid,
  .navbar-expand-sm>.container-lg,
  .navbar-expand-sm>.container-md,
  .navbar-expand-sm>.container-sm,
  .navbar-expand-sm>.container-xl {
    padding-right: 0;
    padding-left: 0
  }
}

@media (min-width:576px) {
  .navbar-expand-sm {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start
  }

  .navbar-expand-sm .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 2rem;
    padding-left: 2rem
  }

  .navbar-expand-sm>.container,
  .navbar-expand-sm>.container-fluid,
  .navbar-expand-sm>.container-lg,
  .navbar-expand-sm>.container-md,
  .navbar-expand-sm>.container-sm,
  .navbar-expand-sm>.container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap
  }

  .navbar-expand-sm .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto
  }

  .navbar-expand-sm .navbar-toggler {
    display: none
  }
}

@media (max-width:767.98px) {

  .navbar-expand-md>.container,
  .navbar-expand-md>.container-fluid,
  .navbar-expand-md>.container-lg,
  .navbar-expand-md>.container-md,
  .navbar-expand-md>.container-sm,
  .navbar-expand-md>.container-xl {
    padding-right: 0;
    padding-left: 0
  }
}

@media (min-width:768px) {
  .navbar-expand-md {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start
  }

  .navbar-expand-md .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 2rem;
    padding-left: 2rem
  }

  .navbar-expand-md>.container,
  .navbar-expand-md>.container-fluid,
  .navbar-expand-md>.container-lg,
  .navbar-expand-md>.container-md,
  .navbar-expand-md>.container-sm,
  .navbar-expand-md>.container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap
  }

  .navbar-expand-md .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto
  }

  .navbar-expand-md .navbar-toggler {
    display: none
  }
}

@media (max-width:991.98px) {

  .navbar-expand-lg>.container,
  .navbar-expand-lg>.container-fluid,
  .navbar-expand-lg>.container-lg,
  .navbar-expand-lg>.container-md,
  .navbar-expand-lg>.container-sm,
  .navbar-expand-lg>.container-xl {
    padding-right: 0;
    padding-left: 0
  }
}

@media (min-width:992px) {
  .navbar-expand-lg {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start
  }

  .navbar-expand-lg .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 2rem;
    padding-left: 2rem
  }

  .navbar-expand-lg>.container,
  .navbar-expand-lg>.container-fluid,
  .navbar-expand-lg>.container-lg,
  .navbar-expand-lg>.container-md,
  .navbar-expand-lg>.container-sm,
  .navbar-expand-lg>.container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap
  }

  .navbar-expand-lg .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto
  }

  .navbar-expand-lg .navbar-toggler {
    display: none
  }
}

@media (max-width:1199.98px) {

  .navbar-expand-xl>.container,
  .navbar-expand-xl>.container-fluid,
  .navbar-expand-xl>.container-lg,
  .navbar-expand-xl>.container-md,
  .navbar-expand-xl>.container-sm,
  .navbar-expand-xl>.container-xl {
    padding-right: 0;
    padding-left: 0
  }
}

@media (min-width:1200px) {
  .navbar-expand-xl {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start
  }

  .navbar-expand-xl .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 2rem;
    padding-left: 2rem
  }

  .navbar-expand-xl>.container,
  .navbar-expand-xl>.container-fluid,
  .navbar-expand-xl>.container-lg,
  .navbar-expand-xl>.container-md,
  .navbar-expand-xl>.container-sm,
  .navbar-expand-xl>.container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap
  }

  .navbar-expand-xl .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto
  }

  .navbar-expand-xl .navbar-toggler {
    display: none
  }
}

.navbar-expand {
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -ms-flex-pack: start;
  justify-content: flex-start
}

.navbar-expand>.container,
.navbar-expand>.container-fluid,
.navbar-expand>.container-lg,
.navbar-expand>.container-md,
.navbar-expand>.container-sm,
.navbar-expand>.container-xl {
  padding-right: 0;
  padding-left: 0
}

.navbar-expand .navbar-nav {
  -ms-flex-direction: row;
  flex-direction: row
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 2rem;
  padding-left: 2rem
}

.navbar-expand>.container,
.navbar-expand>.container-fluid,
.navbar-expand>.container-lg,
.navbar-expand>.container-md,
.navbar-expand>.container-sm,
.navbar-expand>.container-xl {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap
}

.navbar-expand .navbar-collapse {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
  flex-basis: auto
}

.navbar-expand .navbar-toggler {
  display: none
}

.navbar-light .navbar-brand {
  color: #000
}

.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
  color: #000
}

.navbar-light .navbar-nav .nav-link {
  color: #000
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: #3db36b
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, .3)
}

.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show>.nav-link {
  color: #3db36b
}

.navbar-light .navbar-toggler {
  color: #000;
  border-color: rgba(0, 0, 0, .1)
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='%23000' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}

.navbar-light .navbar-text {
  color: #000
}

.navbar-light .navbar-text a {
  color: #3db36b
}

.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
  color: #3db36b
}

.navbar-dark .navbar-brand {
  color: #fff
}

.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
  color: #fff
}

.navbar-dark .navbar-nav .nav-link {
  color: #fff
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: #3db36b
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, .25)
}

.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show>.nav-link {
  color: #3db36b
}

.navbar-dark .navbar-toggler {
  color: #fff;
  border-color: rgba(255, 255, 255, .5)
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='%23fff' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}

.navbar-dark .navbar-text {
  color: #fff
}

.navbar-dark .navbar-text a {
  color: #3db36b
}

.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
  color: #3db36b
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, .125)
}

.card>hr {
  margin-right: 0;
  margin-left: 0
}

.card>.list-group {
  border-top: inherit;
  border-bottom: inherit
}

.card>.list-group:first-child {
  border-top-width: 0
}

.card>.list-group:last-child {
  border-bottom-width: 0
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem
}

.card-title {
  margin-bottom: .75rem
}

.card-subtitle {
  margin-top: -.375rem;
  margin-bottom: 0
}

.card-text:last-child {
  margin-bottom: 0
}

.card-link:hover {
  text-decoration: none
}

.card-link+.card-link {
  margin-left: 1.25rem
}

.card-header {
  padding: .75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, .03);
  border-bottom: 1px solid rgba(0, 0, 0, .125)
}

.card-header+.list-group .list-group-item:first-child {
  border-top: 0
}

.card-footer {
  padding: .75rem 1.25rem;
  background-color: rgba(0, 0, 0, .03);
  border-top: 1px solid rgba(0, 0, 0, .125)
}

.card-header-tabs {
  margin-right: -.625rem;
  margin-bottom: -.75rem;
  margin-left: -.625rem;
  border-bottom: 0
}

.card-header-pills {
  margin-right: -.625rem;
  margin-left: -.625rem
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem
}

.card-img,
.card-img-bottom,
.card-img-top {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%
}

.card-deck .card {
  margin-bottom: 15px
}

@media (min-width:576px) {
  .card-deck {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px
  }

  .card-deck .card {
    -ms-flex: 1 0 0%;
    flex: 1 0;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px
  }
}

.card-group>.card {
  margin-bottom: 15px
}

@media (min-width:576px) {
  .card-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap
  }

  .card-group>.card {
    -ms-flex: 1 0 0%;
    flex: 1 0;
    margin-bottom: 0
  }

  .card-group>.card+.card {
    margin-left: 0;
    border-left: 0
  }
}

.card-columns .card {
  margin-bottom: .75rem
}

@media (min-width:576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1
  }

  .card-columns .card {
    display: inline-block;
    width: 100%
  }
}

.accordion>.card {
  overflow: hidden
}

.accordion>.card:not(:last-of-type) {
  border-bottom: 0
}

.accordion>.card>.card-header {
  margin-bottom: -1px
}

.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: .75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef
}

.breadcrumb-item {
  display: -ms-flexbox;
  display: flex
}

.breadcrumb-item+.breadcrumb-item {
  padding-left: .5rem
}

.breadcrumb-item+.breadcrumb-item::before {
  display: inline-block;
  padding-right: .5rem;
  color: #6c757d;
  content: "/"
}

.breadcrumb-item+.breadcrumb-item:hover::before {
  text-decoration: underline
}

.breadcrumb-item+.breadcrumb-item:hover::before {
  text-decoration: none
}

.breadcrumb-item.active {
  color: #6c757d
}

.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none
}

.page-link {
  position: relative;
  display: block;
  padding: .5rem .75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #3db36b;
  background-color: #fff;
  border: 1px solid #dee2e6
}

.page-link:hover {
  z-index: 2;
  color: #2a7a49;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: none
}

.page-item:first-child .page-link {
  margin-left: 0
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #3db36b;
  border-color: #3db36b
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6
}

.pagination-lg .page-link {
  padding: .75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5
}

.pagination-sm .page-link {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5
}

.badge {
  display: inline-block;
  padding: .5rem .5rem;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
  .badge {
    transition: none
  }
}

a.badge:focus,
a.badge:hover {
  text-decoration: none
}

.badge:empty {
  display: none
}

.btn .badge {
  position: relative;
  top: -1px
}

.badge-pill {
  padding-right: 1.125rem;
  padding-left: 1.125rem
}

.badge-primary {
  color: #fff;
  background-color: #3db36b
}

a.badge-primary:focus,
a.badge-primary:hover {
  color: #fff;
  background-color: #308d54
}

a.badge-primary.focus,
a.badge-primary:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(61, 179, 107, .5)
}

.badge-secondary {
  color: #212529;
  background-color: #f9f9f9
}

a.badge-secondary:focus,
a.badge-secondary:hover {
  color: #212529;
  background-color: #e0e0e0
}

a.badge-secondary.focus,
a.badge-secondary:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(249, 249, 249, .5)
}

.badge-success {
  color: #fff;
  background-color: #9DC41E
}

a.badge-success:focus,
a.badge-success:hover {
  color: #fff;
  background-color: #1e7e34
}

a.badge-success.focus,
a.badge-success:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(40, 167, 69, .5)
}

.badge-info {
  color: #fff;
  background-color: #4184f3
}

a.badge-info:focus,
a.badge-info:hover {
  color: #fff;
  background-color: #1165f0
}

a.badge-info.focus,
a.badge-info:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(65, 132, 243, .5)
}

.badge-warning {
  color: #212529;
  background-color: #fdb813
}

a.badge-warning:focus,
a.badge-warning:hover {
  color: #212529;
  background-color: #db9b02
}

a.badge-warning.focus,
a.badge-warning:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(253, 184, 19, .5)
}

.badge-danger {
  color: #fff;
  background-color: #dc3545
}

a.badge-danger:focus,
a.badge-danger:hover {
  color: #fff;
  background-color: #bd2130
}

a.badge-danger.focus,
a.badge-danger:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(220, 53, 69, .5)
}

.badge-light {
  color: #212529;
  background-color: #efefef
}

a.badge-light:focus,
a.badge-light:hover {
  color: #212529;
  background-color: #d6d6d6
}

a.badge-light.focus,
a.badge-light:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(239, 239, 239, .5)
}

.badge-dark {
  color: #fff;
  background-color: #343a40
}

a.badge-dark:focus,
a.badge-dark:hover {
  color: #fff;
  background-color: #1d2124
}

a.badge-dark.focus,
a.badge-dark:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(52, 58, 64, .5)
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  color: #000;
  background-color: #f9f9f9
}

@media (min-width:576px) {
  .jumbotron {
    padding: 4rem 2rem
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0
}

.alert {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent
}

.alert-heading {
  color: inherit
}

.alert-link {
  font-weight: 700
}

.alert-dismissible {
  padding-right: 4rem
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: .75rem 1.25rem;
  color: inherit
}

.alert-primary {
  color: #205d38;
  background-color: #d8f0e1;
  border-color: #c9ead6
}

.alert-primary hr {
  border-top-color: #b7e3c8
}

.alert-primary .alert-link {
  color: #133721
}

.alert-secondary {
  color: #818181;
  background-color: #fefefe;
  border-color: #fdfdfd
}

.alert-secondary hr {
  border-top-color: #f0f0f0
}

.alert-secondary .alert-link {
  color: #686868
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb
}

.alert-success hr {
  border-top-color: #b1dfbb
}

.alert-success .alert-link {
  color: #0b2e13
}

.alert-info {
  color: #22457e;
  background-color: #d9e6fd;
  border-color: #caddfc
}

.alert-info hr {
  border-top-color: #b2cefb
}

.alert-info .alert-link {
  color: #172f56
}

.alert-warning {
  color: #84600a;
  background-color: #fff1d0;
  border-color: #feebbd
}

.alert-warning hr {
  border-top-color: #fee3a4
}

.alert-warning .alert-link {
  color: #553e06
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb
}

.alert-danger hr {
  border-top-color: #f1b0b7
}

.alert-danger .alert-link {
  color: #491217
}

.alert-light {
  color: #7c7c7c;
  background-color: #fcfcfc;
  border-color: #fbfbfb
}

.alert-light hr {
  border-top-color: #eee
}

.alert-light .alert-link {
  color: #636363
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca
}

.alert-dark hr {
  border-top-color: #b9bbbe
}

.alert-dark .alert-link {
  color: #040505
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0
  }

  to {
    background-position: 0 0
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0
  }

  to {
    background-position: 0 0
  }
}

.progress {
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: .75rem;
  background-color: #e9ecef
}

.progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #3db36b;
  transition: width .6s ease
}

@media (prefers-reduced-motion:reduce) {
  .progress-bar {
    transition: none
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite
}

@media (prefers-reduced-motion:reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none
  }
}

.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start
}

.media-body {
  -ms-flex: 1;
  flex: 1 1
}

.list-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit
}

.list-group-item-action:focus,
.list-group-item-action:hover {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef
}

.list-group-item {
  position: relative;
  display: block;
  padding: .75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, .125)
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #3db36b;
  border-color: #3db36b
}

.list-group-item+.list-group-item {
  border-top-width: 0
}

.list-group-item+.list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px
}

.list-group-horizontal {
  -ms-flex-direction: row;
  flex-direction: row
}

.list-group-horizontal>.list-group-item.active {
  margin-top: 0
}

.list-group-horizontal>.list-group-item+.list-group-item {
  border-top-width: 1px;
  border-left-width: 0
}

.list-group-horizontal>.list-group-item+.list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px
}

@media (min-width:576px) {
  .list-group-horizontal-sm {
    -ms-flex-direction: row;
    flex-direction: row
  }

  .list-group-horizontal-sm>.list-group-item.active {
    margin-top: 0
  }

  .list-group-horizontal-sm>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0
  }

  .list-group-horizontal-sm>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px
  }
}

@media (min-width:768px) {
  .list-group-horizontal-md {
    -ms-flex-direction: row;
    flex-direction: row
  }

  .list-group-horizontal-md>.list-group-item.active {
    margin-top: 0
  }

  .list-group-horizontal-md>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0
  }

  .list-group-horizontal-md>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px
  }
}

@media (min-width:992px) {
  .list-group-horizontal-lg {
    -ms-flex-direction: row;
    flex-direction: row
  }

  .list-group-horizontal-lg>.list-group-item.active {
    margin-top: 0
  }

  .list-group-horizontal-lg>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0
  }

  .list-group-horizontal-lg>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px
  }
}

@media (min-width:1200px) {
  .list-group-horizontal-xl {
    -ms-flex-direction: row;
    flex-direction: row
  }

  .list-group-horizontal-xl>.list-group-item.active {
    margin-top: 0
  }

  .list-group-horizontal-xl>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0
  }

  .list-group-horizontal-xl>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px
  }
}

.list-group-flush>.list-group-item {
  border-width: 0 0 1px
}

.list-group-flush>.list-group-item:last-child {
  border-bottom-width: 0
}

.list-group-item-primary {
  color: #205d38;
  background-color: #c9ead6
}

.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
  color: #205d38;
  background-color: #b7e3c8
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #205d38;
  border-color: #205d38
}

.list-group-item-secondary {
  color: #818181;
  background-color: #fdfdfd
}

.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
  color: #818181;
  background-color: #f0f0f0
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #818181;
  border-color: #818181
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb
}

.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
  color: #155724;
  background-color: #b1dfbb
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724
}

.list-group-item-info {
  color: #22457e;
  background-color: #caddfc
}

.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
  color: #22457e;
  background-color: #b2cefb
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #22457e;
  border-color: #22457e
}

.list-group-item-warning {
  color: #84600a;
  background-color: #feebbd
}

.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
  color: #84600a;
  background-color: #fee3a4
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #84600a;
  border-color: #84600a
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb
}

.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
  color: #721c24;
  background-color: #f1b0b7
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24
}

.list-group-item-light {
  color: #7c7c7c;
  background-color: #fbfbfb
}

.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
  color: #7c7c7c;
  background-color: #eee
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #7c7c7c;
  border-color: #7c7c7c
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca
}

.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
  color: #1b1e21;
  background-color: #b9bbbe
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5
}

.close:hover {
  color: #000;
  text-decoration: none
}

.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
  opacity: .75
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0
}

a.close.disabled {
  pointer-events: none
}

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: .875rem;
  background-color: rgba(255, 255, 255, .85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .1);
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  opacity: 0
}

.toast:not(:last-child) {
  margin-bottom: .75rem
}

.toast.showing {
  opacity: 1
}

.toast.show {
  display: block;
  opacity: 1
}

.toast.hide {
  display: none
}

.toast-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: .25rem .75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, .85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, .05)
}

.toast-body {
  padding: .75rem
}

.modal-open {
  overflow: hidden
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: .5rem;
  pointer-events: none
}

.modal.fade .modal-dialog {
  transition: -webkit-transform .3s ease-out;
  transition: transform .3s ease-out;
  transition: transform .3s ease-out, -webkit-transform .3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px)
}

@media (prefers-reduced-motion:reduce) {
  .modal.fade .modal-dialog {
    transition: none
  }
}

.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none
}

.modal.modal-static .modal-dialog {
  -webkit-transform: scale(1.02);
  transform: scale(1.02)
}

.modal-dialog-scrollable {
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100% - 1rem)
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden
}

.modal-dialog-scrollable .modal-footer,
.modal-dialog-scrollable .modal-header {
  -ms-flex-negative: 0;
  flex-shrink: 0
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto
}

.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem)
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  content: ""
}

.modal-dialog-centered.modal-dialog-scrollable {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  outline: 0
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000
}

.modal-backdrop.fade {
  opacity: 0
}

.modal-backdrop.show {
  opacity: .8
}

.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.625
}

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem
}

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: .75rem;
  border-top: 1px solid #dee2e6
}

.modal-footer>* {
  margin: .25rem
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll
}

@media (min-width:576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem)
  }

  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem)
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem)
  }

  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content
  }

  .modal-sm {
    max-width: 390px
  }
}

@media (min-width:992px) {

  .modal-lg,
  .modal-xl {
    max-width: 800px
  }
}

@media (min-width:1200px) {
  .modal-xl {
    max-width: 1140px
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.625;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: .875rem;
  word-wrap: break-word;
  opacity: 0
}

.tooltip.show {
  opacity: .9
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: .8rem;
  height: .4rem
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid
}

.bs-tooltip-auto[x-placement^=top],
.bs-tooltip-top {
  padding: .4rem 0
}

.bs-tooltip-auto[x-placement^=top] .arrow,
.bs-tooltip-top .arrow {
  bottom: 0
}

.bs-tooltip-auto[x-placement^=top] .arrow::before,
.bs-tooltip-top .arrow::before {
  top: 0;
  border-width: .4rem .4rem 0;
  border-top-color: #000
}

.bs-tooltip-auto[x-placement^=right],
.bs-tooltip-right {
  padding: 0 .4rem
}

.bs-tooltip-auto[x-placement^=right] .arrow,
.bs-tooltip-right .arrow {
  left: 0;
  width: .4rem;
  height: .8rem
}

.bs-tooltip-auto[x-placement^=right] .arrow::before,
.bs-tooltip-right .arrow::before {
  right: 0;
  border-width: .4rem .4rem .4rem 0;
  border-right-color: #000
}

.bs-tooltip-auto[x-placement^=bottom],
.bs-tooltip-bottom {
  padding: .4rem 0
}

.bs-tooltip-auto[x-placement^=bottom] .arrow,
.bs-tooltip-bottom .arrow {
  top: 0
}

.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  bottom: 0;
  border-width: 0 .4rem .4rem;
  border-bottom-color: #000
}

.bs-tooltip-auto[x-placement^=left],
.bs-tooltip-left {
  padding: 0 .4rem
}

.bs-tooltip-auto[x-placement^=left] .arrow,
.bs-tooltip-left .arrow {
  right: 0;
  width: .4rem;
  height: .8rem
}

.bs-tooltip-auto[x-placement^=left] .arrow::before,
.bs-tooltip-left .arrow::before {
  left: 0;
  border-width: .4rem 0 .4rem .4rem;
  border-left-color: #000
}

.tooltip-inner {
  max-width: 200px;
  padding: .25rem .5rem;
  color: #fff;
  text-align: center;
  background-color: #000
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.625;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: .75rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, .2)
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: .5rem;
  margin: 0 .3rem
}

.popover .arrow::after,
.popover .arrow::before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid
}

.bs-popover-auto[x-placement^=top],
.bs-popover-top {
  margin-bottom: .5rem
}

.bs-popover-auto[x-placement^=top]>.arrow,
.bs-popover-top>.arrow {
  bottom: -.5rem
}

.bs-popover-auto[x-placement^=top]>.arrow::before,
.bs-popover-top>.arrow::before {
  bottom: 0;
  border-width: .5rem .5rem 0;
  border-top-color: rgba(0, 0, 0, .25)
}

.bs-popover-auto[x-placement^=top]>.arrow::after,
.bs-popover-top>.arrow::after {
  bottom: 0;
  border-width: .5rem .5rem 0;
  border-top-color: #fff
}

.bs-popover-auto[x-placement^=right],
.bs-popover-right {
  margin-left: .5rem
}

.bs-popover-auto[x-placement^=right]>.arrow,
.bs-popover-right>.arrow {
  left: -.5rem;
  width: .5rem;
  height: 1rem;
  margin: .3rem 0
}

.bs-popover-auto[x-placement^=right]>.arrow::before,
.bs-popover-right>.arrow::before {
  left: 0;
  border-width: .5rem .5rem .5rem 0;
  border-right-color: rgba(0, 0, 0, .25)
}

.bs-popover-auto[x-placement^=right]>.arrow::after,
.bs-popover-right>.arrow::after {
  left: 0;
  border-width: .5rem .5rem .5rem 0;
  border-right-color: #fff
}

.bs-popover-auto[x-placement^=bottom],
.bs-popover-bottom {
  margin-top: .5rem
}

.bs-popover-auto[x-placement^=bottom]>.arrow,
.bs-popover-bottom>.arrow {
  top: -.5rem
}

.bs-popover-auto[x-placement^=bottom]>.arrow::before,
.bs-popover-bottom>.arrow::before {
  top: 0;
  border-width: 0 .5rem .5rem .5rem;
  border-bottom-color: rgba(0, 0, 0, .25)
}

.bs-popover-auto[x-placement^=bottom]>.arrow::after,
.bs-popover-bottom>.arrow::after {
  top: 0;
  border-width: 0 .5rem .5rem .5rem;
  border-bottom-color: #fff
}

.bs-popover-auto[x-placement^=bottom] .popover-header::before,
.bs-popover-bottom .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -.5rem;
  content: "";
  border-bottom: 0 solid #f7f7f7
}

.bs-popover-auto[x-placement^=left],
.bs-popover-left {
  margin-right: .5rem
}

.bs-popover-auto[x-placement^=left]>.arrow,
.bs-popover-left>.arrow {
  right: -.5rem;
  width: .5rem;
  height: 1rem;
  margin: .3rem 0
}

.bs-popover-auto[x-placement^=left]>.arrow::before,
.bs-popover-left>.arrow::before {
  right: 0;
  border-width: .5rem 0 .5rem .5rem;
  border-left-color: rgba(0, 0, 0, .25)
}

.bs-popover-auto[x-placement^=left]>.arrow::after,
.bs-popover-left>.arrow::after {
  right: 0;
  border-width: .5rem 0 .5rem .5rem;
  border-left-color: #fff
}

.popover-header {
  padding: .5rem .75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 0 solid #ebebeb
}

.popover-header:empty {
  display: none
}

.popover-body {
  padding: .875rem 1.25rem;
  color: #4a4a4a
}

.carousel {
  position: relative
}

.carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: ""
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: -webkit-transform .6s ease-in-out;
  transition: transform .6s ease-in-out;
  transition: transform .6s ease-in-out, -webkit-transform .6s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
  .carousel-item {
    transition: none
  }
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block
}

.active.carousel-item-right,
.carousel-item-next:not(.carousel-item-left) {
  -webkit-transform: translateX(100%);
  transform: translateX(100%)
}

.active.carousel-item-left,
.carousel-item-prev:not(.carousel-item-right) {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%)
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
  transform: none
}

.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right,
.carousel-fade .carousel-item.active {
  z-index: 1;
  opacity: 1
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s
}

@media (prefers-reduced-motion:reduce) {

  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none
  }
}

.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: .5;
  transition: opacity .15s ease
}

@media (prefers-reduced-motion:reduce) {

  .carousel-control-next,
  .carousel-control-prev {
    transition: none
  }
}

.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: .9
}

.carousel-control-prev {
  left: 0
}

.carousel-control-next {
  right: 0
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e")
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e")
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none
}

.carousel-indicators li {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity .6s ease
}

@media (prefers-reduced-motion:reduce) {
  .carousel-indicators li {
    transition: none
  }
}

.carousel-indicators .active {
  opacity: 1
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: .25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
  animation: spinner-border .75s linear infinite
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: .2em
}

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0)
  }

  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none
  }
}

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0)
  }

  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow .75s linear infinite;
  animation: spinner-grow .75s linear infinite
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem
}

.align-baseline {
  vertical-align: baseline !important
}

.align-top {
  vertical-align: top !important
}

.align-middle {
  vertical-align: middle !important
}

.align-bottom {
  vertical-align: bottom !important
}

.align-text-bottom {
  vertical-align: text-bottom !important
}

.align-text-top {
  vertical-align: text-top !important
}

.bg-primary {
  background-color: #3db36b !important
}

a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
  background-color: #308d54 !important
}

.bg-secondary {
  background-color: #f9f9f9 !important
}

a.bg-secondary:focus,
a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover {
  background-color: #e0e0e0 !important
}

.bg-success {
  background-color: #9DC41E !important
}

a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
  background-color: #1e7e34 !important
}

.bg-info {
  background-color: #4184f3 !important
}

a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
  background-color: #1165f0 !important
}

.bg-warning {
  background-color: #fdb813 !important
}

a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
  background-color: #db9b02 !important
}

.bg-danger {
  background-color: #dc3545 !important
}

a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
  background-color: #bd2130 !important
}

.bg-light {
  background-color: #efefef !important
}

a.bg-light:focus,
a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
  background-color: #d6d6d6 !important
}

.bg-dark {
  background-color: #343a40 !important
}

a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
  background-color: #1d2124 !important
}

.bg-white {
  background-color: #fff !important
}

.bg-transparent {
  background-color: transparent !important
}

.border {
  border: 1px solid #dee2e6 !important
}

.border-top {
  border-top: 1px solid #dee2e6 !important
}

.border-right {
  border-right: 1px solid #dee2e6 !important
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important
}

.border-left {
  border-left: 1px solid #dee2e6 !important
}

.border-0 {
  border: 0 !important
}

.border-top-0 {
  border-top: 0 !important
}

.border-right-0 {
  border-right: 0 !important
}

.border-bottom-0 {
  border-bottom: 0 !important
}

.border-left-0 {
  border-left: 0 !important
}

.border-primary {
  border-color: #3db36b !important
}

.border-secondary {
  border-color: #f9f9f9 !important
}

.border-success {
  border-color: #9DC41E !important
}

.border-info {
  border-color: #4184f3 !important
}

.border-warning {
  border-color: #fdb813 !important
}

.border-danger {
  border-color: #dc3545 !important
}

.border-light {
  border-color: #efefef !important
}

.border-dark {
  border-color: #343a40 !important
}

.border-white {
  border-color: #fff !important
}

.rounded-sm {
  border-radius: .2rem !important
}

.rounded {
  border-radius: .25rem !important
}

.rounded-top {
  border-top-left-radius: .25rem !important;
  border-top-right-radius: .25rem !important
}

.rounded-right {
  border-top-right-radius: .25rem !important;
  border-bottom-right-radius: .25rem !important
}

.rounded-bottom {
  border-bottom-right-radius: .25rem !important;
  border-bottom-left-radius: .25rem !important
}

.rounded-left {
  border-top-left-radius: .25rem !important;
  border-bottom-left-radius: .25rem !important
}

.rounded-lg {
  border-radius: .3rem !important
}

.rounded-circle {
  border-radius: 50% !important
}

.rounded-pill {
  border-radius: 50rem !important
}

.rounded-0 {
  border-radius: 0 !important
}

.clearfix::after {
  display: block;
  clear: both;
  content: ""
}

.d-none {
  display: none !important
}

.d-inline {
  display: inline !important
}

.d-inline-block {
  display: inline-block !important
}

.d-block {
  display: block !important
}

.d-table {
  display: table !important
}

.d-table-row {
  display: table-row !important
}

.d-table-cell {
  display: table-cell !important
}

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important
}

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important
}

@media (min-width:576px) {
  .d-sm-none {
    display: none !important
  }

  .d-sm-inline {
    display: inline !important
  }

  .d-sm-inline-block {
    display: inline-block !important
  }

  .d-sm-block {
    display: block !important
  }

  .d-sm-table {
    display: table !important
  }

  .d-sm-table-row {
    display: table-row !important
  }

  .d-sm-table-cell {
    display: table-cell !important
  }

  .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important
  }

  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important
  }
}

@media (min-width:768px) {
  .d-md-none {
    display: none !important
  }

  .d-md-inline {
    display: inline !important
  }

  .d-md-inline-block {
    display: inline-block !important
  }

  .d-md-block {
    display: block !important
  }

  .d-md-table {
    display: table !important
  }

  .d-md-table-row {
    display: table-row !important
  }

  .d-md-table-cell {
    display: table-cell !important
  }

  .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important
  }

  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important
  }
}

@media (min-width:992px) {
  .d-lg-none {
    display: none !important
  }

  .d-lg-inline {
    display: inline !important
  }

  .d-lg-inline-block {
    display: inline-block !important
  }

  .d-lg-block {
    display: block !important
  }

  .d-lg-table {
    display: table !important
  }

  .d-lg-table-row {
    display: table-row !important
  }

  .d-lg-table-cell {
    display: table-cell !important
  }

  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important
  }

  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important
  }
}

@media (min-width:1200px) {
  .d-xl-none {
    display: none !important
  }

  .d-xl-inline {
    display: inline !important
  }

  .d-xl-inline-block {
    display: inline-block !important
  }

  .d-xl-block {
    display: block !important
  }

  .d-xl-table {
    display: table !important
  }

  .d-xl-table-row {
    display: table-row !important
  }

  .d-xl-table-cell {
    display: table-cell !important
  }

  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important
  }

  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important
  }
}

@media print {
  .d-print-none {
    display: none !important
  }

  .d-print-inline {
    display: inline !important
  }

  .d-print-inline-block {
    display: inline-block !important
  }

  .d-print-block {
    display: block !important
  }

  .d-print-table {
    display: table !important
  }

  .d-print-table-row {
    display: table-row !important
  }

  .d-print-table-cell {
    display: table-cell !important
  }

  .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important
  }

  .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden
}

.embed-responsive::before {
  display: block;
  content: ""
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%
}

.embed-responsive-16by9::before {
  padding-top: 56.25%
}

.embed-responsive-4by3::before {
  padding-top: 75%
}

.embed-responsive-1by1::before {
  padding-top: 100%
}

.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important
}

.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important
}

.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important
}

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important
}

.flex-fill {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important
}

.flex-grow-0 {
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important
}

.flex-grow-1 {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important
}

.justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important
}

.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important
}

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important
}

.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important
}

.align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important
}

.align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important
}

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important
}

.align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important
}

.align-items-stretch {
  -ms-flex-align: stretch !important;
  align-items: stretch !important
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important
}

@media (min-width:576px) {
  .flex-sm-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important
  }

  .flex-sm-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important
  }

  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important
  }

  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important
  }

  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important
  }

  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important
  }

  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important
  }

  .flex-sm-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important
  }

  .flex-sm-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important
  }

  .flex-sm-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important
  }

  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important
  }

  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important
  }

  .justify-content-sm-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important
  }

  .justify-content-sm-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important
  }

  .justify-content-sm-center {
    -ms-flex-pack: center !important;
    justify-content: center !important
  }

  .justify-content-sm-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important
  }

  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important
  }

  .align-items-sm-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important
  }

  .align-items-sm-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important
  }

  .align-items-sm-center {
    -ms-flex-align: center !important;
    align-items: center !important
  }

  .align-items-sm-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important
  }

  .align-items-sm-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important
  }

  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important
  }

  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important
  }

  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important
  }

  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important
  }

  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important
  }

  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important
  }

  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important
  }

  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important
  }

  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important
  }

  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important
  }

  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important
  }

  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important
  }
}

@media (min-width:768px) {
  .flex-md-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important
  }

  .flex-md-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important
  }

  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important
  }

  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important
  }

  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important
  }

  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important
  }

  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important
  }

  .flex-md-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important
  }

  .flex-md-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important
  }

  .flex-md-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important
  }

  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important
  }

  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important
  }

  .justify-content-md-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important
  }

  .justify-content-md-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important
  }

  .justify-content-md-center {
    -ms-flex-pack: center !important;
    justify-content: center !important
  }

  .justify-content-md-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important
  }

  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important
  }

  .align-items-md-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important
  }

  .align-items-md-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important
  }

  .align-items-md-center {
    -ms-flex-align: center !important;
    align-items: center !important
  }

  .align-items-md-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important
  }

  .align-items-md-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important
  }

  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important
  }

  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important
  }

  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important
  }

  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important
  }

  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important
  }

  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important
  }

  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important
  }

  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important
  }

  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important
  }

  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important
  }

  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important
  }

  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important
  }
}

@media (min-width:992px) {
  .flex-lg-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important
  }

  .flex-lg-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important
  }

  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important
  }

  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important
  }

  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important
  }

  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important
  }

  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important
  }

  .flex-lg-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important
  }

  .flex-lg-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important
  }

  .flex-lg-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important
  }

  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important
  }

  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important
  }

  .justify-content-lg-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important
  }

  .justify-content-lg-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important
  }

  .justify-content-lg-center {
    -ms-flex-pack: center !important;
    justify-content: center !important
  }

  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important
  }

  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important
  }

  .align-items-lg-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important
  }

  .align-items-lg-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important
  }

  .align-items-lg-center {
    -ms-flex-align: center !important;
    align-items: center !important
  }

  .align-items-lg-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important
  }

  .align-items-lg-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important
  }

  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important
  }

  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important
  }

  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important
  }

  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important
  }

  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important
  }

  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important
  }

  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important
  }

  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important
  }

  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important
  }

  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important
  }

  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important
  }

  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important
  }
}

@media (min-width:1200px) {
  .flex-xl-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important
  }

  .flex-xl-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important
  }

  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important
  }

  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important
  }

  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important
  }

  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important
  }

  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important
  }

  .flex-xl-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important
  }

  .flex-xl-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important
  }

  .flex-xl-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important
  }

  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important
  }

  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important
  }

  .justify-content-xl-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important
  }

  .justify-content-xl-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important
  }

  .justify-content-xl-center {
    -ms-flex-pack: center !important;
    justify-content: center !important
  }

  .justify-content-xl-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important
  }

  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important
  }

  .align-items-xl-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important
  }

  .align-items-xl-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important
  }

  .align-items-xl-center {
    -ms-flex-align: center !important;
    align-items: center !important
  }

  .align-items-xl-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important
  }

  .align-items-xl-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important
  }

  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important
  }

  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important
  }

  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important
  }

  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important
  }

  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important
  }

  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important
  }

  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important
  }

  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important
  }

  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important
  }

  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important
  }

  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important
  }

  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important
  }
}

.float-left {
  float: left !important
}

.float-right {
  float: right !important
}

.float-none {
  float: none !important
}

@media (min-width:576px) {
  .float-sm-left {
    float: left !important
  }

  .float-sm-right {
    float: right !important
  }

  .float-sm-none {
    float: none !important
  }
}

@media (min-width:768px) {
  .float-md-left {
    float: left !important
  }

  .float-md-right {
    float: right !important
  }

  .float-md-none {
    float: none !important
  }
}

@media (min-width:992px) {
  .float-lg-left {
    float: left !important
  }

  .float-lg-right {
    float: right !important
  }

  .float-lg-none {
    float: none !important
  }
}

@media (min-width:1200px) {
  .float-xl-left {
    float: left !important
  }

  .float-xl-right {
    float: right !important
  }

  .float-xl-none {
    float: none !important
  }
}

.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  -ms-user-select: all !important;
  user-select: all !important
}

.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important
}

.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important
}

.overflow-auto {
  overflow: auto !important
}

.overflow-hidden {
  overflow: hidden !important
}

.position-static {
  position: static !important
}

.position-relative {
  position: relative !important
}

.position-absolute {
  position: absolute !important
}

.position-fixed {
  position: fixed !important
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030
}

@supports ((position:-webkit-sticky) or (position:sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal
}

.shadow-sm {
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important
}

.shadow {
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important
}

.shadow-none {
  box-shadow: none !important
}

.w-25 {
  width: 25% !important
}

.w-50 {
  width: 50% !important
}

.w-75 {
  width: 75% !important
}

.w-100 {
  width: 100% !important
}

.w-auto {
  width: auto !important
}

.h-25 {
  height: 25% !important
}

.h-50 {
  height: 50% !important
}

.h-75 {
  height: 75% !important
}

.h-100 {
  height: 100% !important
}

.h-auto {
  height: auto !important
}

.mw-100 {
  max-width: 100% !important
}

.mh-100 {
  max-height: 100% !important
}

.min-vw-100 {
  min-width: 100vw !important
}

.min-vh-100 {
  min-height: 100vh !important
}

.vw-100 {
  width: 100vw !important
}

.vh-100 {
  height: 100vh !important
}

.m-0 {
  margin: 0 !important
}

.mt-0,
.my-0 {
  margin-top: 0 !important
}

.mr-0,
.mx-0 {
  margin-right: 0 !important
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important
}

.ml-0,
.mx-0 {
  margin-left: 0 !important
}

.m-1 {
  margin: .25rem !important
}

.mt-1,
.my-1 {
  margin-top: .25rem !important
}

.mr-1,
.mx-1 {
  margin-right: .25rem !important
}

.mb-1,
.my-1 {
  margin-bottom: .25rem !important
}

.ml-1,
.mx-1 {
  margin-left: .25rem !important
}

.m-2 {
  margin: .375rem !important
}

.mt-2,
.my-2 {
  margin-top: .375rem !important
}

.mr-2,
.mx-2 {
  margin-right: .375rem !important
}

.mb-2,
.my-2 {
  margin-bottom: .375rem !important
}

.ml-2,
.mx-2 {
  margin-left: .375rem !important
}

.m-3 {
  margin: .5rem !important
}

.mt-3,
.my-3 {
  margin-top: .5rem !important
}

.mr-3,
.mx-3 {
  margin-right: .5rem !important
}

.mb-3,
.my-3 {
  margin-bottom: .5rem !important
}

.ml-3,
.mx-3 {
  margin-left: .5rem !important
}

.m-4 {
  margin: .625rem !important
}

.mt-4,
.my-4 {
  margin-top: .625rem !important
}

.mr-4,
.mx-4 {
  margin-right: .625rem !important
}

.mb-4,
.my-4 {
  margin-bottom: .625rem !important
}

.ml-4,
.mx-4 {
  margin-left: .625rem !important
}

.m-5 {
  margin: .75rem !important
}

.mt-5,
.my-5 {
  margin-top: .75rem !important
}

.mr-5,
.mx-5 {
  margin-right: .75rem !important
}

.mb-5,
.my-5 {
  margin-bottom: .75rem !important
}

.ml-5,
.mx-5 {
  margin-left: .75rem !important
}

.m-6 {
  margin: .875rem !important
}

.mt-6,
.my-6 {
  margin-top: .875rem !important
}

.mr-6,
.mx-6 {
  margin-right: .875rem !important
}

.mb-6,
.my-6 {
  margin-bottom: .875rem !important
}

.ml-6,
.mx-6 {
  margin-left: .875rem !important
}

.m-7 {
  margin: 1rem !important
}

.mt-7,
.my-7 {
  margin-top: 1rem !important
}

.mr-7,
.mx-7 {
  margin-right: 1rem !important
}

.mb-7,
.my-7 {
  margin-bottom: 1rem !important
}

.ml-7,
.mx-7 {
  margin-left: 1rem !important
}

.m-8 {
  margin: 1.125rem !important
}

.mt-8,
.my-8 {
  margin-top: 1.125rem !important
}

.mr-8,
.mx-8 {
  margin-right: 1.125rem !important
}

.mb-8,
.my-8 {
  margin-bottom: 1.125rem !important
}

.ml-8,
.mx-8 {
  margin-left: 1.125rem !important
}

.m-9 {
  margin: 1.25rem !important
}

.mt-9,
.my-9 {
  margin-top: 1.25rem !important
}

.mr-9,
.mx-9 {
  margin-right: 1.25rem !important
}

.mb-9,
.my-9 {
  margin-bottom: 1.25rem !important
}

.ml-9,
.mx-9 {
  margin-left: 1.25rem !important
}

.m-10 {
  margin: 1.375rem !important
}

.mt-10,
.my-10 {
  margin-top: 1.375rem !important
}

.mr-10,
.mx-10 {
  margin-right: 1.375rem !important
}

.mb-10,
.my-10 {
  margin-bottom: 1.375rem !important
}

.ml-10,
.mx-10 {
  margin-left: 1.375rem !important
}

.m-11 {
  margin: 1.5rem !important
}

.mt-11,
.my-11 {
  margin-top: 1.5rem !important
}

.mr-11,
.mx-11 {
  margin-right: 1.5rem !important
}

.mb-11,
.my-11 {
  margin-bottom: 1.5rem !important
}

.ml-11,
.mx-11 {
  margin-left: 1.5rem !important
}

.m-12 {
  margin: 1.625rem !important
}

.mt-12,
.my-12 {
  margin-top: 1.625rem !important
}

.mr-12,
.mx-12 {
  margin-right: 1.625rem !important
}

.mb-12,
.my-12 {
  margin-bottom: 1.625rem !important
}

.ml-12,
.mx-12 {
  margin-left: 1.625rem !important
}

.m-13 {
  margin: 1.75rem !important
}

.mt-13,
.my-13 {
  margin-top: 1.75rem !important
}

.mr-13,
.mx-13 {
  margin-right: 1.75rem !important
}

.mb-13,
.my-13 {
  margin-bottom: 1.75rem !important
}

.ml-13,
.mx-13 {
  margin-left: 1.75rem !important
}

.m-14 {
  margin: 1.875rem !important
}

.mt-14,
.my-14 {
  margin-top: 1.875rem !important
}

.mr-14,
.mx-14 {
  margin-right: 1.875rem !important
}

.mb-14,
.my-14 {
  margin-bottom: 1.875rem !important
}

.ml-14,
.mx-14 {
  margin-left: 1.875rem !important
}

.m-15 {
  margin: 2rem !important
}

.mt-15,
.my-15 {
  margin-top: 2rem !important
}

.mr-15,
.mx-15 {
  margin-right: 2rem !important
}

.mb-15,
.my-15 {
  margin-bottom: 2rem !important
}

.ml-15,
.mx-15 {
  margin-left: 2rem !important
}

.m-16 {
  margin: 2.125rem !important
}

.mt-16,
.my-16 {
  margin-top: 2.125rem !important
}

.mr-16,
.mx-16 {
  margin-right: 2.125rem !important
}

.mb-16,
.my-16 {
  margin-bottom: 2.125rem !important
}

.ml-16,
.mx-16 {
  margin-left: 2.125rem !important
}

.m-17 {
  margin: 2.25rem !important
}

.mt-17,
.my-17 {
  margin-top: 2.25rem !important
}

.mr-17,
.mx-17 {
  margin-right: 2.25rem !important
}

.mb-17,
.my-17 {
  margin-bottom: 2.25rem !important
}

.ml-17,
.mx-17 {
  margin-left: 2.25rem !important
}

.m-18 {
  margin: 2.375rem !important
}

.mt-18,
.my-18 {
  margin-top: 2.375rem !important
}

.mr-18,
.mx-18 {
  margin-right: 2.375rem !important
}

.mb-18,
.my-18 {
  margin-bottom: 2.375rem !important
}

.ml-18,
.mx-18 {
  margin-left: 2.375rem !important
}

.m-19 {
  margin: 2.5rem !important
}

.mt-19,
.my-19 {
  margin-top: 2.5rem !important
}

.mr-19,
.mx-19 {
  margin-right: 2.5rem !important
}

.mb-19,
.my-19 {
  margin-bottom: 2.5rem !important
}

.ml-19,
.mx-19 {
  margin-left: 2.5rem !important
}

.m-20 {
  margin: 3.125rem !important
}

.mt-20,
.my-20 {
  margin-top: 3.125rem !important
}

.mr-20,
.mx-20 {
  margin-right: 3.125rem !important
}

.mb-20,
.my-20 {
  margin-bottom: 3.125rem !important
}

.ml-20,
.mx-20 {
  margin-left: 3.125rem !important
}

.m-21 {
  margin: 3.75rem !important
}

.mt-21,
.my-21 {
  margin-top: 3.75rem !important
}

.mr-21,
.mx-21 {
  margin-right: 3.75rem !important
}

.mb-21,
.my-21 {
  margin-bottom: 3.75rem !important
}

.ml-21,
.mx-21 {
  margin-left: 3.75rem !important
}

.m-22 {
  margin: 4.375rem !important
}

.mt-22,
.my-22 {
  margin-top: 4.375rem !important
}

.mr-22,
.mx-22 {
  margin-right: 4.375rem !important
}

.mb-22,
.my-22 {
  margin-bottom: 4.375rem !important
}

.ml-22,
.mx-22 {
  margin-left: 4.375rem !important
}

.m-23 {
  margin: 5rem !important
}

.mt-23,
.my-23 {
  margin-top: 5rem !important
}

.mr-23,
.mx-23 {
  margin-right: 5rem !important
}

.mb-23,
.my-23 {
  margin-bottom: 5rem !important
}

.ml-23,
.mx-23 {
  margin-left: 5rem !important
}

.m-24 {
  margin: 5.625rem !important
}

.mt-24,
.my-24 {
  margin-top: 5.625rem !important
}

.mr-24,
.mx-24 {
  margin-right: 5.625rem !important
}

.mb-24,
.my-24 {
  margin-bottom: 5.625rem !important
}

.ml-24,
.mx-24 {
  margin-left: 5.625rem !important
}

.m-25 {
  margin: 6.25rem !important
}

.mt-25,
.my-25 {
  margin-top: 6.25rem !important
}

.mr-25,
.mx-25 {
  margin-right: 6.25rem !important
}

.mb-25,
.my-25 {
  margin-bottom: 6.25rem !important
}

.ml-25,
.mx-25 {
  margin-left: 6.25rem !important
}

.p-0 {
  padding: 0 !important
}

.pt-0,
.py-0 {
  padding-top: 0 !important
}

.pr-0,
.px-0 {
  padding-right: 0 !important
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important
}

.pl-0,
.px-0 {
  padding-left: 0 !important
}

.p-1 {
  padding: .25rem !important
}

.pt-1,
.py-1 {
  padding-top: .25rem !important
}

.pr-1,
.px-1 {
  padding-right: .25rem !important
}

.pb-1,
.py-1 {
  padding-bottom: .25rem !important
}

.pl-1,
.px-1 {
  padding-left: .25rem !important
}

.p-2 {
  padding: .375rem !important
}

.pt-2,
.py-2 {
  padding-top: .375rem !important
}

.pr-2,
.px-2 {
  padding-right: .375rem !important
}

.pb-2,
.py-2 {
  padding-bottom: .375rem !important
}

.pl-2,
.px-2 {
  padding-left: .375rem !important
}

.p-3 {
  padding: .5rem !important
}

.pt-3,
.py-3 {
  padding-top: .5rem !important
}

.pr-3,
.px-3 {
  padding-right: .5rem !important
}

.pb-3,
.py-3 {
  padding-bottom: .5rem !important
}

.pl-3,
.px-3 {
  padding-left: .5rem !important
}

.p-4 {
  padding: .625rem !important
}

.pt-4,
.py-4 {
  padding-top: .625rem !important
}

.pr-4,
.px-4 {
  padding-right: .625rem !important
}

.pb-4,
.py-4 {
  padding-bottom: .625rem !important
}

.pl-4,
.px-4 {
  padding-left: .625rem !important
}

.p-5 {
  padding: .75rem !important
}

.pt-5,
.py-5 {
  padding-top: .75rem !important
}

.pr-5,
.px-5 {
  padding-right: .75rem !important
}

.pb-5,
.py-5 {
  padding-bottom: .75rem !important
}

.pl-5,
.px-5 {
  padding-left: .75rem !important
}

.p-6 {
  padding: .875rem !important
}

.pt-6,
.py-6 {
  padding-top: .875rem !important
}

.pr-6,
.px-6 {
  padding-right: .875rem !important
}

.pb-6,
.py-6 {
  padding-bottom: .875rem !important
}

.pl-6,
.px-6 {
  padding-left: .875rem !important
}

.p-7 {
  padding: 1rem !important
}

.pt-7,
.py-7 {
  padding-top: 1rem !important
}

.pr-7,
.px-7 {
  padding-right: 1rem !important
}

.pb-7,
.py-7 {
  padding-bottom: 1rem !important
}

.pl-7,
.px-7 {
  padding-left: 1rem !important
}

.p-8 {
  padding: 1.125rem !important
}

.pt-8,
.py-8 {
  padding-top: 1.125rem !important
}

.pr-8,
.px-8 {
  padding-right: 1.125rem !important
}

.pb-8,
.py-8 {
  padding-bottom: 1.125rem !important
}

.pl-8,
.px-8 {
  padding-left: 1.125rem !important
}

.p-9 {
  padding: 1.25rem !important
}

.pt-9,
.py-9 {
  padding-top: 1.25rem !important
}

.pr-9,
.px-9 {
  padding-right: 1.25rem !important
}

.pb-9,
.py-9 {
  padding-bottom: 1.25rem !important
}

.pl-9,
.px-9 {
  padding-left: 1.25rem !important
}

.p-10 {
  padding: 1.375rem !important
}

.pt-10,
.py-10 {
  padding-top: 1.375rem !important
}

.pr-10,
.px-10 {
  padding-right: 1.375rem !important
}

.pb-10,
.py-10 {
  padding-bottom: 1.375rem !important
}

.pl-10,
.px-10 {
  padding-left: 1.375rem !important
}

.p-11 {
  padding: 1.5rem !important
}

.pt-11,
.py-11 {
  padding-top: 1.5rem !important
}

.pr-11,
.px-11 {
  padding-right: 1.5rem !important
}

.pb-11,
.py-11 {
  padding-bottom: 1.5rem !important
}

.pl-11,
.px-11 {
  padding-left: 1.5rem !important
}

.p-12 {
  padding: 1.625rem !important
}

.pt-12,
.py-12 {
  padding-top: 1.625rem !important
}

.pr-12,
.px-12 {
  padding-right: 1.625rem !important
}

.pb-12,
.py-12 {
  padding-bottom: 1.625rem !important
}

.pl-12,
.px-12 {
  padding-left: 1.625rem !important
}

.p-13 {
  padding: 1.75rem !important
}

.pt-13,
.py-13 {
  padding-top: 1.75rem !important
}

.pr-13,
.px-13 {
  padding-right: 1.75rem !important
}

.pb-13,
.py-13 {
  padding-bottom: 1.75rem !important
}

.pl-13,
.px-13 {
  padding-left: 1.75rem !important
}

.p-14 {
  padding: 1.875rem !important
}

.pt-14,
.py-14 {
  padding-top: 1.875rem !important
}

.pr-14,
.px-14 {
  padding-right: 1.875rem !important
}

.pb-14,
.py-14 {
  padding-bottom: 1.875rem !important
}

.pl-14,
.px-14 {
  padding-left: 1.875rem !important
}

.p-15 {
  padding: 2rem !important
}

.pt-15,
.py-15 {
  padding-top: 2rem !important
}

.pr-15,
.px-15 {
  padding-right: 2rem !important
}

.pb-15,
.py-15 {
  padding-bottom: 2rem !important
}

.pl-15,
.px-15 {
  padding-left: 2rem !important
}

.p-16 {
  padding: 2.125rem !important
}

.pt-16,
.py-16 {
  padding-top: 2.125rem !important
}

.pr-16,
.px-16 {
  padding-right: 2.125rem !important
}

.pb-16,
.py-16 {
  padding-bottom: 2.125rem !important
}

.pl-16,
.px-16 {
  padding-left: 2.125rem !important
}

.p-17 {
  padding: 2.25rem !important
}

.pt-17,
.py-17 {
  padding-top: 2.25rem !important
}

.pr-17,
.px-17 {
  padding-right: 2.25rem !important
}

.pb-17,
.py-17 {
  padding-bottom: 2.25rem !important
}

.pl-17,
.px-17 {
  padding-left: 2.25rem !important
}

.p-18 {
  padding: 2.375rem !important
}

.pt-18,
.py-18 {
  padding-top: 2.375rem !important
}

.pr-18,
.px-18 {
  padding-right: 2.375rem !important
}

.pb-18,
.py-18 {
  padding-bottom: 2.375rem !important
}

.pl-18,
.px-18 {
  padding-left: 2.375rem !important
}

.p-19 {
  padding: 2.5rem !important
}

.pt-19,
.py-19 {
  padding-top: 2.5rem !important
}

.pr-19,
.px-19 {
  padding-right: 2.5rem !important
}

.pb-19,
.py-19 {
  padding-bottom: 2.5rem !important
}

.pl-19,
.px-19 {
  padding-left: 2.5rem !important
}

.p-20 {
  padding: 3.125rem !important
}

.pt-20,
.py-20 {
  padding-top: 3.125rem !important
}

.pr-20,
.px-20 {
  padding-right: 3.125rem !important
}

.pb-20,
.py-20 {
  padding-bottom: 3.125rem !important
}

.pl-20,
.px-20 {
  padding-left: 3.125rem !important
}

.p-21 {
  padding: 3.75rem !important
}

.pt-21,
.py-21 {
  padding-top: 3.75rem !important
}

.pr-21,
.px-21 {
  padding-right: 3.75rem !important
}

.pb-21,
.py-21 {
  padding-bottom: 3.75rem !important
}

.pl-21,
.px-21 {
  padding-left: 3.75rem !important
}

.p-22 {
  padding: 4.375rem !important
}

.pt-22,
.py-22 {
  padding-top: 4.375rem !important
}

.pr-22,
.px-22 {
  padding-right: 4.375rem !important
}

.pb-22,
.py-22 {
  padding-bottom: 4.375rem !important
}

.pl-22,
.px-22 {
  padding-left: 4.375rem !important
}

.p-23 {
  padding: 5rem !important
}

.pt-23,
.py-23 {
  padding-top: 5rem !important
}

.pr-23,
.px-23 {
  padding-right: 5rem !important
}

.pb-23,
.py-23 {
  padding-bottom: 5rem !important
}

.pl-23,
.px-23 {
  padding-left: 5rem !important
}

.p-24 {
  padding: 5.625rem !important
}

.pt-24,
.py-24 {
  padding-top: 5.625rem !important
}

.pr-24,
.px-24 {
  padding-right: 5.625rem !important
}

.pb-24,
.py-24 {
  padding-bottom: 5.625rem !important
}

.pl-24,
.px-24 {
  padding-left: 5.625rem !important
}

.p-25 {
  padding: 6.25rem !important
}

.pt-25,
.py-25 {
  padding-top: 6.25rem !important
}

.pr-25,
.px-25 {
  padding-right: 6.25rem !important
}

.pb-25,
.py-25 {
  padding-bottom: 6.25rem !important
}

.pl-25,
.px-25 {
  padding-left: 6.25rem !important
}

.m-n1 {
  margin: -.25rem !important
}

.mt-n1,
.my-n1 {
  margin-top: -.25rem !important
}

.mr-n1,
.mx-n1 {
  margin-right: -.25rem !important
}

.mb-n1,
.my-n1 {
  margin-bottom: -.25rem !important
}

.ml-n1,
.mx-n1 {
  margin-left: -.25rem !important
}

.m-n2 {
  margin: -.375rem !important
}

.mt-n2,
.my-n2 {
  margin-top: -.375rem !important
}

.mr-n2,
.mx-n2 {
  margin-right: -.375rem !important
}

.mb-n2,
.my-n2 {
  margin-bottom: -.375rem !important
}

.ml-n2,
.mx-n2 {
  margin-left: -.375rem !important
}

.m-n3 {
  margin: -.5rem !important
}

.mt-n3,
.my-n3 {
  margin-top: -.5rem !important
}

.mr-n3,
.mx-n3 {
  margin-right: -.5rem !important
}

.mb-n3,
.my-n3 {
  margin-bottom: -.5rem !important
}

.ml-n3,
.mx-n3 {
  margin-left: -.5rem !important
}

.m-n4 {
  margin: -.625rem !important
}

.mt-n4,
.my-n4 {
  margin-top: -.625rem !important
}

.mr-n4,
.mx-n4 {
  margin-right: -.625rem !important
}

.mb-n4,
.my-n4 {
  margin-bottom: -.625rem !important
}

.ml-n4,
.mx-n4 {
  margin-left: -.625rem !important
}

.m-n5 {
  margin: -.75rem !important
}

.mt-n5,
.my-n5 {
  margin-top: -.75rem !important
}

.mr-n5,
.mx-n5 {
  margin-right: -.75rem !important
}

.mb-n5,
.my-n5 {
  margin-bottom: -.75rem !important
}

.ml-n5,
.mx-n5 {
  margin-left: -.75rem !important
}

.m-n6 {
  margin: -.875rem !important
}

.mt-n6,
.my-n6 {
  margin-top: -.875rem !important
}

.mr-n6,
.mx-n6 {
  margin-right: -.875rem !important
}

.mb-n6,
.my-n6 {
  margin-bottom: -.875rem !important
}

.ml-n6,
.mx-n6 {
  margin-left: -.875rem !important
}

.m-n7 {
  margin: -1rem !important
}

.mt-n7,
.my-n7 {
  margin-top: -1rem !important
}

.mr-n7,
.mx-n7 {
  margin-right: -1rem !important
}

.mb-n7,
.my-n7 {
  margin-bottom: -1rem !important
}

.ml-n7,
.mx-n7 {
  margin-left: -1rem !important
}

.m-n8 {
  margin: -1.125rem !important
}

.mt-n8,
.my-n8 {
  margin-top: -1.125rem !important
}

.mr-n8,
.mx-n8 {
  margin-right: -1.125rem !important
}

.mb-n8,
.my-n8 {
  margin-bottom: -1.125rem !important
}

.ml-n8,
.mx-n8 {
  margin-left: -1.125rem !important
}

.m-n9 {
  margin: -1.25rem !important
}

.mt-n9,
.my-n9 {
  margin-top: -1.25rem !important
}

.mr-n9,
.mx-n9 {
  margin-right: -1.25rem !important
}

.mb-n9,
.my-n9 {
  margin-bottom: -1.25rem !important
}

.ml-n9,
.mx-n9 {
  margin-left: -1.25rem !important
}

.m-n10 {
  margin: -1.375rem !important
}

.mt-n10,
.my-n10 {
  margin-top: -1.375rem !important
}

.mr-n10,
.mx-n10 {
  margin-right: -1.375rem !important
}

.mb-n10,
.my-n10 {
  margin-bottom: -1.375rem !important
}

.ml-n10,
.mx-n10 {
  margin-left: -1.375rem !important
}

.m-n11 {
  margin: -1.5rem !important
}

.mt-n11,
.my-n11 {
  margin-top: -1.5rem !important
}

.mr-n11,
.mx-n11 {
  margin-right: -1.5rem !important
}

.mb-n11,
.my-n11 {
  margin-bottom: -1.5rem !important
}

.ml-n11,
.mx-n11 {
  margin-left: -1.5rem !important
}

.m-n12 {
  margin: -1.625rem !important
}

.mt-n12,
.my-n12 {
  margin-top: -1.625rem !important
}

.mr-n12,
.mx-n12 {
  margin-right: -1.625rem !important
}

.mb-n12,
.my-n12 {
  margin-bottom: -1.625rem !important
}

.ml-n12,
.mx-n12 {
  margin-left: -1.625rem !important
}

.m-n13 {
  margin: -1.75rem !important
}

.mt-n13,
.my-n13 {
  margin-top: -1.75rem !important
}

.mr-n13,
.mx-n13 {
  margin-right: -1.75rem !important
}

.mb-n13,
.my-n13 {
  margin-bottom: -1.75rem !important
}

.ml-n13,
.mx-n13 {
  margin-left: -1.75rem !important
}

.m-n14 {
  margin: -1.875rem !important
}

.mt-n14,
.my-n14 {
  margin-top: -1.875rem !important
}

.mr-n14,
.mx-n14 {
  margin-right: -1.875rem !important
}

.mb-n14,
.my-n14 {
  margin-bottom: -1.875rem !important
}

.ml-n14,
.mx-n14 {
  margin-left: -1.875rem !important
}

.m-n15 {
  margin: -2rem !important
}

.mt-n15,
.my-n15 {
  margin-top: -2rem !important
}

.mr-n15,
.mx-n15 {
  margin-right: -2rem !important
}

.mb-n15,
.my-n15 {
  margin-bottom: -2rem !important
}

.ml-n15,
.mx-n15 {
  margin-left: -2rem !important
}

.m-n16 {
  margin: -2.125rem !important
}

.mt-n16,
.my-n16 {
  margin-top: -2.125rem !important
}

.mr-n16,
.mx-n16 {
  margin-right: -2.125rem !important
}

.mb-n16,
.my-n16 {
  margin-bottom: -2.125rem !important
}

.ml-n16,
.mx-n16 {
  margin-left: -2.125rem !important
}

.m-n17 {
  margin: -2.25rem !important
}

.mt-n17,
.my-n17 {
  margin-top: -2.25rem !important
}

.mr-n17,
.mx-n17 {
  margin-right: -2.25rem !important
}

.mb-n17,
.my-n17 {
  margin-bottom: -2.25rem !important
}

.ml-n17,
.mx-n17 {
  margin-left: -2.25rem !important
}

.m-n18 {
  margin: -2.375rem !important
}

.mt-n18,
.my-n18 {
  margin-top: -2.375rem !important
}

.mr-n18,
.mx-n18 {
  margin-right: -2.375rem !important
}

.mb-n18,
.my-n18 {
  margin-bottom: -2.375rem !important
}

.ml-n18,
.mx-n18 {
  margin-left: -2.375rem !important
}

.m-n19 {
  margin: -2.5rem !important
}

.mt-n19,
.my-n19 {
  margin-top: -2.5rem !important
}

.mr-n19,
.mx-n19 {
  margin-right: -2.5rem !important
}

.mb-n19,
.my-n19 {
  margin-bottom: -2.5rem !important
}

.ml-n19,
.mx-n19 {
  margin-left: -2.5rem !important
}

.m-n20 {
  margin: -3.125rem !important
}

.mt-n20,
.my-n20 {
  margin-top: -3.125rem !important
}

.mr-n20,
.mx-n20 {
  margin-right: -3.125rem !important
}

.mb-n20,
.my-n20 {
  margin-bottom: -3.125rem !important
}

.ml-n20,
.mx-n20 {
  margin-left: -3.125rem !important
}

.m-n21 {
  margin: -3.75rem !important
}

.mt-n21,
.my-n21 {
  margin-top: -3.75rem !important
}

.mr-n21,
.mx-n21 {
  margin-right: -3.75rem !important
}

.mb-n21,
.my-n21 {
  margin-bottom: -3.75rem !important
}

.ml-n21,
.mx-n21 {
  margin-left: -3.75rem !important
}

.m-n22 {
  margin: -4.375rem !important
}

.mt-n22,
.my-n22 {
  margin-top: -4.375rem !important
}

.mr-n22,
.mx-n22 {
  margin-right: -4.375rem !important
}

.mb-n22,
.my-n22 {
  margin-bottom: -4.375rem !important
}

.ml-n22,
.mx-n22 {
  margin-left: -4.375rem !important
}

.m-n23 {
  margin: -5rem !important
}

.mt-n23,
.my-n23 {
  margin-top: -5rem !important
}

.mr-n23,
.mx-n23 {
  margin-right: -5rem !important
}

.mb-n23,
.my-n23 {
  margin-bottom: -5rem !important
}

.ml-n23,
.mx-n23 {
  margin-left: -5rem !important
}

.m-n24 {
  margin: -5.625rem !important
}

.mt-n24,
.my-n24 {
  margin-top: -5.625rem !important
}

.mr-n24,
.mx-n24 {
  margin-right: -5.625rem !important
}

.mb-n24,
.my-n24 {
  margin-bottom: -5.625rem !important
}

.ml-n24,
.mx-n24 {
  margin-left: -5.625rem !important
}

.m-n25 {
  margin: -6.25rem !important
}

.mt-n25,
.my-n25 {
  margin-top: -6.25rem !important
}

.mr-n25,
.mx-n25 {
  margin-right: -6.25rem !important
}

.mb-n25,
.my-n25 {
  margin-bottom: -6.25rem !important
}

.ml-n25,
.mx-n25 {
  margin-left: -6.25rem !important
}

.m-auto {
  margin: auto !important
}

.mt-auto,
.my-auto {
  margin-top: auto !important
}

.mr-auto,
.mx-auto {
  margin-right: auto !important
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important
}

.ml-auto,
.mx-auto {
  margin-left: auto !important
}

@media (min-width:576px) {
  .m-sm-0 {
    margin: 0 !important
  }

  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important
  }

  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important
  }

  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important
  }

  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important
  }

  .m-sm-1 {
    margin: .25rem !important
  }

  .mt-sm-1,
  .my-sm-1 {
    margin-top: .25rem !important
  }

  .mr-sm-1,
  .mx-sm-1 {
    margin-right: .25rem !important
  }

  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: .25rem !important
  }

  .ml-sm-1,
  .mx-sm-1 {
    margin-left: .25rem !important
  }

  .m-sm-2 {
    margin: .375rem !important
  }

  .mt-sm-2,
  .my-sm-2 {
    margin-top: .375rem !important
  }

  .mr-sm-2,
  .mx-sm-2 {
    margin-right: .375rem !important
  }

  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: .375rem !important
  }

  .ml-sm-2,
  .mx-sm-2 {
    margin-left: .375rem !important
  }

  .m-sm-3 {
    margin: .5rem !important
  }

  .mt-sm-3,
  .my-sm-3 {
    margin-top: .5rem !important
  }

  .mr-sm-3,
  .mx-sm-3 {
    margin-right: .5rem !important
  }

  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: .5rem !important
  }

  .ml-sm-3,
  .mx-sm-3 {
    margin-left: .5rem !important
  }

  .m-sm-4 {
    margin: .625rem !important
  }

  .mt-sm-4,
  .my-sm-4 {
    margin-top: .625rem !important
  }

  .mr-sm-4,
  .mx-sm-4 {
    margin-right: .625rem !important
  }

  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: .625rem !important
  }

  .ml-sm-4,
  .mx-sm-4 {
    margin-left: .625rem !important
  }

  .m-sm-5 {
    margin: .75rem !important
  }

  .mt-sm-5,
  .my-sm-5 {
    margin-top: .75rem !important
  }

  .mr-sm-5,
  .mx-sm-5 {
    margin-right: .75rem !important
  }

  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: .75rem !important
  }

  .ml-sm-5,
  .mx-sm-5 {
    margin-left: .75rem !important
  }

  .m-sm-6 {
    margin: .875rem !important
  }

  .mt-sm-6,
  .my-sm-6 {
    margin-top: .875rem !important
  }

  .mr-sm-6,
  .mx-sm-6 {
    margin-right: .875rem !important
  }

  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: .875rem !important
  }

  .ml-sm-6,
  .mx-sm-6 {
    margin-left: .875rem !important
  }

  .m-sm-7 {
    margin: 1rem !important
  }

  .mt-sm-7,
  .my-sm-7 {
    margin-top: 1rem !important
  }

  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 1rem !important
  }

  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 1rem !important
  }

  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 1rem !important
  }

  .m-sm-8 {
    margin: 1.125rem !important
  }

  .mt-sm-8,
  .my-sm-8 {
    margin-top: 1.125rem !important
  }

  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 1.125rem !important
  }

  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 1.125rem !important
  }

  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 1.125rem !important
  }

  .m-sm-9 {
    margin: 1.25rem !important
  }

  .mt-sm-9,
  .my-sm-9 {
    margin-top: 1.25rem !important
  }

  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 1.25rem !important
  }

  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 1.25rem !important
  }

  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 1.25rem !important
  }

  .m-sm-10 {
    margin: 1.375rem !important
  }

  .mt-sm-10,
  .my-sm-10 {
    margin-top: 1.375rem !important
  }

  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 1.375rem !important
  }

  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 1.375rem !important
  }

  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 1.375rem !important
  }

  .m-sm-11 {
    margin: 1.5rem !important
  }

  .mt-sm-11,
  .my-sm-11 {
    margin-top: 1.5rem !important
  }

  .mr-sm-11,
  .mx-sm-11 {
    margin-right: 1.5rem !important
  }

  .mb-sm-11,
  .my-sm-11 {
    margin-bottom: 1.5rem !important
  }

  .ml-sm-11,
  .mx-sm-11 {
    margin-left: 1.5rem !important
  }

  .m-sm-12 {
    margin: 1.625rem !important
  }

  .mt-sm-12,
  .my-sm-12 {
    margin-top: 1.625rem !important
  }

  .mr-sm-12,
  .mx-sm-12 {
    margin-right: 1.625rem !important
  }

  .mb-sm-12,
  .my-sm-12 {
    margin-bottom: 1.625rem !important
  }

  .ml-sm-12,
  .mx-sm-12 {
    margin-left: 1.625rem !important
  }

  .m-sm-13 {
    margin: 1.75rem !important
  }

  .mt-sm-13,
  .my-sm-13 {
    margin-top: 1.75rem !important
  }

  .mr-sm-13,
  .mx-sm-13 {
    margin-right: 1.75rem !important
  }

  .mb-sm-13,
  .my-sm-13 {
    margin-bottom: 1.75rem !important
  }

  .ml-sm-13,
  .mx-sm-13 {
    margin-left: 1.75rem !important
  }

  .m-sm-14 {
    margin: 1.875rem !important
  }

  .mt-sm-14,
  .my-sm-14 {
    margin-top: 1.875rem !important
  }

  .mr-sm-14,
  .mx-sm-14 {
    margin-right: 1.875rem !important
  }

  .mb-sm-14,
  .my-sm-14 {
    margin-bottom: 1.875rem !important
  }

  .ml-sm-14,
  .mx-sm-14 {
    margin-left: 1.875rem !important
  }

  .m-sm-15 {
    margin: 2rem !important
  }

  .mt-sm-15,
  .my-sm-15 {
    margin-top: 2rem !important
  }

  .mr-sm-15,
  .mx-sm-15 {
    margin-right: 2rem !important
  }

  .mb-sm-15,
  .my-sm-15 {
    margin-bottom: 2rem !important
  }

  .ml-sm-15,
  .mx-sm-15 {
    margin-left: 2rem !important
  }

  .m-sm-16 {
    margin: 2.125rem !important
  }

  .mt-sm-16,
  .my-sm-16 {
    margin-top: 2.125rem !important
  }

  .mr-sm-16,
  .mx-sm-16 {
    margin-right: 2.125rem !important
  }

  .mb-sm-16,
  .my-sm-16 {
    margin-bottom: 2.125rem !important
  }

  .ml-sm-16,
  .mx-sm-16 {
    margin-left: 2.125rem !important
  }

  .m-sm-17 {
    margin: 2.25rem !important
  }

  .mt-sm-17,
  .my-sm-17 {
    margin-top: 2.25rem !important
  }

  .mr-sm-17,
  .mx-sm-17 {
    margin-right: 2.25rem !important
  }

  .mb-sm-17,
  .my-sm-17 {
    margin-bottom: 2.25rem !important
  }

  .ml-sm-17,
  .mx-sm-17 {
    margin-left: 2.25rem !important
  }

  .m-sm-18 {
    margin: 2.375rem !important
  }

  .mt-sm-18,
  .my-sm-18 {
    margin-top: 2.375rem !important
  }

  .mr-sm-18,
  .mx-sm-18 {
    margin-right: 2.375rem !important
  }

  .mb-sm-18,
  .my-sm-18 {
    margin-bottom: 2.375rem !important
  }

  .ml-sm-18,
  .mx-sm-18 {
    margin-left: 2.375rem !important
  }

  .m-sm-19 {
    margin: 2.5rem !important
  }

  .mt-sm-19,
  .my-sm-19 {
    margin-top: 2.5rem !important
  }

  .mr-sm-19,
  .mx-sm-19 {
    margin-right: 2.5rem !important
  }

  .mb-sm-19,
  .my-sm-19 {
    margin-bottom: 2.5rem !important
  }

  .ml-sm-19,
  .mx-sm-19 {
    margin-left: 2.5rem !important
  }

  .m-sm-20 {
    margin: 3.125rem !important
  }

  .mt-sm-20,
  .my-sm-20 {
    margin-top: 3.125rem !important
  }

  .mr-sm-20,
  .mx-sm-20 {
    margin-right: 3.125rem !important
  }

  .mb-sm-20,
  .my-sm-20 {
    margin-bottom: 3.125rem !important
  }

  .ml-sm-20,
  .mx-sm-20 {
    margin-left: 3.125rem !important
  }

  .m-sm-21 {
    margin: 3.75rem !important
  }

  .mt-sm-21,
  .my-sm-21 {
    margin-top: 3.75rem !important
  }

  .mr-sm-21,
  .mx-sm-21 {
    margin-right: 3.75rem !important
  }

  .mb-sm-21,
  .my-sm-21 {
    margin-bottom: 3.75rem !important
  }

  .ml-sm-21,
  .mx-sm-21 {
    margin-left: 3.75rem !important
  }

  .m-sm-22 {
    margin: 4.375rem !important
  }

  .mt-sm-22,
  .my-sm-22 {
    margin-top: 4.375rem !important
  }

  .mr-sm-22,
  .mx-sm-22 {
    margin-right: 4.375rem !important
  }

  .mb-sm-22,
  .my-sm-22 {
    margin-bottom: 4.375rem !important
  }

  .ml-sm-22,
  .mx-sm-22 {
    margin-left: 4.375rem !important
  }

  .m-sm-23 {
    margin: 5rem !important
  }

  .mt-sm-23,
  .my-sm-23 {
    margin-top: 5rem !important
  }

  .mr-sm-23,
  .mx-sm-23 {
    margin-right: 5rem !important
  }

  .mb-sm-23,
  .my-sm-23 {
    margin-bottom: 5rem !important
  }

  .ml-sm-23,
  .mx-sm-23 {
    margin-left: 5rem !important
  }

  .m-sm-24 {
    margin: 5.625rem !important
  }

  .mt-sm-24,
  .my-sm-24 {
    margin-top: 5.625rem !important
  }

  .mr-sm-24,
  .mx-sm-24 {
    margin-right: 5.625rem !important
  }

  .mb-sm-24,
  .my-sm-24 {
    margin-bottom: 5.625rem !important
  }

  .ml-sm-24,
  .mx-sm-24 {
    margin-left: 5.625rem !important
  }

  .m-sm-25 {
    margin: 6.25rem !important
  }

  .mt-sm-25,
  .my-sm-25 {
    margin-top: 6.25rem !important
  }

  .mr-sm-25,
  .mx-sm-25 {
    margin-right: 6.25rem !important
  }

  .mb-sm-25,
  .my-sm-25 {
    margin-bottom: 6.25rem !important
  }

  .ml-sm-25,
  .mx-sm-25 {
    margin-left: 6.25rem !important
  }

  .p-sm-0 {
    padding: 0 !important
  }

  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important
  }

  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important
  }

  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important
  }

  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important
  }

  .p-sm-1 {
    padding: .25rem !important
  }

  .pt-sm-1,
  .py-sm-1 {
    padding-top: .25rem !important
  }

  .pr-sm-1,
  .px-sm-1 {
    padding-right: .25rem !important
  }

  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: .25rem !important
  }

  .pl-sm-1,
  .px-sm-1 {
    padding-left: .25rem !important
  }

  .p-sm-2 {
    padding: .375rem !important
  }

  .pt-sm-2,
  .py-sm-2 {
    padding-top: .375rem !important
  }

  .pr-sm-2,
  .px-sm-2 {
    padding-right: .375rem !important
  }

  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: .375rem !important
  }

  .pl-sm-2,
  .px-sm-2 {
    padding-left: .375rem !important
  }

  .p-sm-3 {
    padding: .5rem !important
  }

  .pt-sm-3,
  .py-sm-3 {
    padding-top: .5rem !important
  }

  .pr-sm-3,
  .px-sm-3 {
    padding-right: .5rem !important
  }

  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: .5rem !important
  }

  .pl-sm-3,
  .px-sm-3 {
    padding-left: .5rem !important
  }

  .p-sm-4 {
    padding: .625rem !important
  }

  .pt-sm-4,
  .py-sm-4 {
    padding-top: .625rem !important
  }

  .pr-sm-4,
  .px-sm-4 {
    padding-right: .625rem !important
  }

  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: .625rem !important
  }

  .pl-sm-4,
  .px-sm-4 {
    padding-left: .625rem !important
  }

  .p-sm-5 {
    padding: .75rem !important
  }

  .pt-sm-5,
  .py-sm-5 {
    padding-top: .75rem !important
  }

  .pr-sm-5,
  .px-sm-5 {
    padding-right: .75rem !important
  }

  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: .75rem !important
  }

  .pl-sm-5,
  .px-sm-5 {
    padding-left: .75rem !important
  }

  .p-sm-6 {
    padding: .875rem !important
  }

  .pt-sm-6,
  .py-sm-6 {
    padding-top: .875rem !important
  }

  .pr-sm-6,
  .px-sm-6 {
    padding-right: .875rem !important
  }

  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: .875rem !important
  }

  .pl-sm-6,
  .px-sm-6 {
    padding-left: .875rem !important
  }

  .p-sm-7 {
    padding: 1rem !important
  }

  .pt-sm-7,
  .py-sm-7 {
    padding-top: 1rem !important
  }

  .pr-sm-7,
  .px-sm-7 {
    padding-right: 1rem !important
  }

  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 1rem !important
  }

  .pl-sm-7,
  .px-sm-7 {
    padding-left: 1rem !important
  }

  .p-sm-8 {
    padding: 1.125rem !important
  }

  .pt-sm-8,
  .py-sm-8 {
    padding-top: 1.125rem !important
  }

  .pr-sm-8,
  .px-sm-8 {
    padding-right: 1.125rem !important
  }

  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 1.125rem !important
  }

  .pl-sm-8,
  .px-sm-8 {
    padding-left: 1.125rem !important
  }

  .p-sm-9 {
    padding: 1.25rem !important
  }

  .pt-sm-9,
  .py-sm-9 {
    padding-top: 1.25rem !important
  }

  .pr-sm-9,
  .px-sm-9 {
    padding-right: 1.25rem !important
  }

  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 1.25rem !important
  }

  .pl-sm-9,
  .px-sm-9 {
    padding-left: 1.25rem !important
  }

  .p-sm-10 {
    padding: 1.375rem !important
  }

  .pt-sm-10,
  .py-sm-10 {
    padding-top: 1.375rem !important
  }

  .pr-sm-10,
  .px-sm-10 {
    padding-right: 1.375rem !important
  }

  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 1.375rem !important
  }

  .pl-sm-10,
  .px-sm-10 {
    padding-left: 1.375rem !important
  }

  .p-sm-11 {
    padding: 1.5rem !important
  }

  .pt-sm-11,
  .py-sm-11 {
    padding-top: 1.5rem !important
  }

  .pr-sm-11,
  .px-sm-11 {
    padding-right: 1.5rem !important
  }

  .pb-sm-11,
  .py-sm-11 {
    padding-bottom: 1.5rem !important
  }

  .pl-sm-11,
  .px-sm-11 {
    padding-left: 1.5rem !important
  }

  .p-sm-12 {
    padding: 1.625rem !important
  }

  .pt-sm-12,
  .py-sm-12 {
    padding-top: 1.625rem !important
  }

  .pr-sm-12,
  .px-sm-12 {
    padding-right: 1.625rem !important
  }

  .pb-sm-12,
  .py-sm-12 {
    padding-bottom: 1.625rem !important
  }

  .pl-sm-12,
  .px-sm-12 {
    padding-left: 1.625rem !important
  }

  .p-sm-13 {
    padding: 1.75rem !important
  }

  .pt-sm-13,
  .py-sm-13 {
    padding-top: 1.75rem !important
  }

  .pr-sm-13,
  .px-sm-13 {
    padding-right: 1.75rem !important
  }

  .pb-sm-13,
  .py-sm-13 {
    padding-bottom: 1.75rem !important
  }

  .pl-sm-13,
  .px-sm-13 {
    padding-left: 1.75rem !important
  }

  .p-sm-14 {
    padding: 1.875rem !important
  }

  .pt-sm-14,
  .py-sm-14 {
    padding-top: 1.875rem !important
  }

  .pr-sm-14,
  .px-sm-14 {
    padding-right: 1.875rem !important
  }

  .pb-sm-14,
  .py-sm-14 {
    padding-bottom: 1.875rem !important
  }

  .pl-sm-14,
  .px-sm-14 {
    padding-left: 1.875rem !important
  }

  .p-sm-15 {
    padding: 2rem !important
  }

  .pt-sm-15,
  .py-sm-15 {
    padding-top: 2rem !important
  }

  .pr-sm-15,
  .px-sm-15 {
    padding-right: 2rem !important
  }

  .pb-sm-15,
  .py-sm-15 {
    padding-bottom: 2rem !important
  }

  .pl-sm-15,
  .px-sm-15 {
    padding-left: 2rem !important
  }

  .p-sm-16 {
    padding: 2.125rem !important
  }

  .pt-sm-16,
  .py-sm-16 {
    padding-top: 2.125rem !important
  }

  .pr-sm-16,
  .px-sm-16 {
    padding-right: 2.125rem !important
  }

  .pb-sm-16,
  .py-sm-16 {
    padding-bottom: 2.125rem !important
  }

  .pl-sm-16,
  .px-sm-16 {
    padding-left: 2.125rem !important
  }

  .p-sm-17 {
    padding: 2.25rem !important
  }

  .pt-sm-17,
  .py-sm-17 {
    padding-top: 2.25rem !important
  }

  .pr-sm-17,
  .px-sm-17 {
    padding-right: 2.25rem !important
  }

  .pb-sm-17,
  .py-sm-17 {
    padding-bottom: 2.25rem !important
  }

  .pl-sm-17,
  .px-sm-17 {
    padding-left: 2.25rem !important
  }

  .p-sm-18 {
    padding: 2.375rem !important
  }

  .pt-sm-18,
  .py-sm-18 {
    padding-top: 2.375rem !important
  }

  .pr-sm-18,
  .px-sm-18 {
    padding-right: 2.375rem !important
  }

  .pb-sm-18,
  .py-sm-18 {
    padding-bottom: 2.375rem !important
  }

  .pl-sm-18,
  .px-sm-18 {
    padding-left: 2.375rem !important
  }

  .p-sm-19 {
    padding: 2.5rem !important
  }

  .pt-sm-19,
  .py-sm-19 {
    padding-top: 2.5rem !important
  }

  .pr-sm-19,
  .px-sm-19 {
    padding-right: 2.5rem !important
  }

  .pb-sm-19,
  .py-sm-19 {
    padding-bottom: 2.5rem !important
  }

  .pl-sm-19,
  .px-sm-19 {
    padding-left: 2.5rem !important
  }

  .p-sm-20 {
    padding: 3.125rem !important
  }

  .pt-sm-20,
  .py-sm-20 {
    padding-top: 3.125rem !important
  }

  .pr-sm-20,
  .px-sm-20 {
    padding-right: 3.125rem !important
  }

  .pb-sm-20,
  .py-sm-20 {
    padding-bottom: 3.125rem !important
  }

  .pl-sm-20,
  .px-sm-20 {
    padding-left: 3.125rem !important
  }

  .p-sm-21 {
    padding: 3.75rem !important
  }

  .pt-sm-21,
  .py-sm-21 {
    padding-top: 3.75rem !important
  }

  .pr-sm-21,
  .px-sm-21 {
    padding-right: 3.75rem !important
  }

  .pb-sm-21,
  .py-sm-21 {
    padding-bottom: 3.75rem !important
  }

  .pl-sm-21,
  .px-sm-21 {
    padding-left: 3.75rem !important
  }

  .p-sm-22 {
    padding: 4.375rem !important
  }

  .pt-sm-22,
  .py-sm-22 {
    padding-top: 4.375rem !important
  }

  .pr-sm-22,
  .px-sm-22 {
    padding-right: 4.375rem !important
  }

  .pb-sm-22,
  .py-sm-22 {
    padding-bottom: 4.375rem !important
  }

  .pl-sm-22,
  .px-sm-22 {
    padding-left: 4.375rem !important
  }

  .p-sm-23 {
    padding: 5rem !important
  }

  .pt-sm-23,
  .py-sm-23 {
    padding-top: 5rem !important
  }

  .pr-sm-23,
  .px-sm-23 {
    padding-right: 5rem !important
  }

  .pb-sm-23,
  .py-sm-23 {
    padding-bottom: 5rem !important
  }

  .pl-sm-23,
  .px-sm-23 {
    padding-left: 5rem !important
  }

  .p-sm-24 {
    padding: 5.625rem !important
  }

  .pt-sm-24,
  .py-sm-24 {
    padding-top: 5.625rem !important
  }

  .pr-sm-24,
  .px-sm-24 {
    padding-right: 5.625rem !important
  }

  .pb-sm-24,
  .py-sm-24 {
    padding-bottom: 5.625rem !important
  }

  .pl-sm-24,
  .px-sm-24 {
    padding-left: 5.625rem !important
  }

  .p-sm-25 {
    padding: 6.25rem !important
  }

  .pt-sm-25,
  .py-sm-25 {
    padding-top: 6.25rem !important
  }

  .pr-sm-25,
  .px-sm-25 {
    padding-right: 6.25rem !important
  }

  .pb-sm-25,
  .py-sm-25 {
    padding-bottom: 6.25rem !important
  }

  .pl-sm-25,
  .px-sm-25 {
    padding-left: 6.25rem !important
  }

  .m-sm-n1 {
    margin: -.25rem !important
  }

  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -.25rem !important
  }

  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -.25rem !important
  }

  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -.25rem !important
  }

  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -.25rem !important
  }

  .m-sm-n2 {
    margin: -.375rem !important
  }

  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -.375rem !important
  }

  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -.375rem !important
  }

  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -.375rem !important
  }

  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -.375rem !important
  }

  .m-sm-n3 {
    margin: -.5rem !important
  }

  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -.5rem !important
  }

  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -.5rem !important
  }

  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -.5rem !important
  }

  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -.5rem !important
  }

  .m-sm-n4 {
    margin: -.625rem !important
  }

  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -.625rem !important
  }

  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -.625rem !important
  }

  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -.625rem !important
  }

  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -.625rem !important
  }

  .m-sm-n5 {
    margin: -.75rem !important
  }

  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -.75rem !important
  }

  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -.75rem !important
  }

  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -.75rem !important
  }

  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -.75rem !important
  }

  .m-sm-n6 {
    margin: -.875rem !important
  }

  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -.875rem !important
  }

  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -.875rem !important
  }

  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -.875rem !important
  }

  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -.875rem !important
  }

  .m-sm-n7 {
    margin: -1rem !important
  }

  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -1rem !important
  }

  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -1rem !important
  }

  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -1rem !important
  }

  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -1rem !important
  }

  .m-sm-n8 {
    margin: -1.125rem !important
  }

  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -1.125rem !important
  }

  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -1.125rem !important
  }

  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -1.125rem !important
  }

  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -1.125rem !important
  }

  .m-sm-n9 {
    margin: -1.25rem !important
  }

  .mt-sm-n9,
  .my-sm-n9 {
    margin-top: -1.25rem !important
  }

  .mr-sm-n9,
  .mx-sm-n9 {
    margin-right: -1.25rem !important
  }

  .mb-sm-n9,
  .my-sm-n9 {
    margin-bottom: -1.25rem !important
  }

  .ml-sm-n9,
  .mx-sm-n9 {
    margin-left: -1.25rem !important
  }

  .m-sm-n10 {
    margin: -1.375rem !important
  }

  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -1.375rem !important
  }

  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -1.375rem !important
  }

  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -1.375rem !important
  }

  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -1.375rem !important
  }

  .m-sm-n11 {
    margin: -1.5rem !important
  }

  .mt-sm-n11,
  .my-sm-n11 {
    margin-top: -1.5rem !important
  }

  .mr-sm-n11,
  .mx-sm-n11 {
    margin-right: -1.5rem !important
  }

  .mb-sm-n11,
  .my-sm-n11 {
    margin-bottom: -1.5rem !important
  }

  .ml-sm-n11,
  .mx-sm-n11 {
    margin-left: -1.5rem !important
  }

  .m-sm-n12 {
    margin: -1.625rem !important
  }

  .mt-sm-n12,
  .my-sm-n12 {
    margin-top: -1.625rem !important
  }

  .mr-sm-n12,
  .mx-sm-n12 {
    margin-right: -1.625rem !important
  }

  .mb-sm-n12,
  .my-sm-n12 {
    margin-bottom: -1.625rem !important
  }

  .ml-sm-n12,
  .mx-sm-n12 {
    margin-left: -1.625rem !important
  }

  .m-sm-n13 {
    margin: -1.75rem !important
  }

  .mt-sm-n13,
  .my-sm-n13 {
    margin-top: -1.75rem !important
  }

  .mr-sm-n13,
  .mx-sm-n13 {
    margin-right: -1.75rem !important
  }

  .mb-sm-n13,
  .my-sm-n13 {
    margin-bottom: -1.75rem !important
  }

  .ml-sm-n13,
  .mx-sm-n13 {
    margin-left: -1.75rem !important
  }

  .m-sm-n14 {
    margin: -1.875rem !important
  }

  .mt-sm-n14,
  .my-sm-n14 {
    margin-top: -1.875rem !important
  }

  .mr-sm-n14,
  .mx-sm-n14 {
    margin-right: -1.875rem !important
  }

  .mb-sm-n14,
  .my-sm-n14 {
    margin-bottom: -1.875rem !important
  }

  .ml-sm-n14,
  .mx-sm-n14 {
    margin-left: -1.875rem !important
  }

  .m-sm-n15 {
    margin: -2rem !important
  }

  .mt-sm-n15,
  .my-sm-n15 {
    margin-top: -2rem !important
  }

  .mr-sm-n15,
  .mx-sm-n15 {
    margin-right: -2rem !important
  }

  .mb-sm-n15,
  .my-sm-n15 {
    margin-bottom: -2rem !important
  }

  .ml-sm-n15,
  .mx-sm-n15 {
    margin-left: -2rem !important
  }

  .m-sm-n16 {
    margin: -2.125rem !important
  }

  .mt-sm-n16,
  .my-sm-n16 {
    margin-top: -2.125rem !important
  }

  .mr-sm-n16,
  .mx-sm-n16 {
    margin-right: -2.125rem !important
  }

  .mb-sm-n16,
  .my-sm-n16 {
    margin-bottom: -2.125rem !important
  }

  .ml-sm-n16,
  .mx-sm-n16 {
    margin-left: -2.125rem !important
  }

  .m-sm-n17 {
    margin: -2.25rem !important
  }

  .mt-sm-n17,
  .my-sm-n17 {
    margin-top: -2.25rem !important
  }

  .mr-sm-n17,
  .mx-sm-n17 {
    margin-right: -2.25rem !important
  }

  .mb-sm-n17,
  .my-sm-n17 {
    margin-bottom: -2.25rem !important
  }

  .ml-sm-n17,
  .mx-sm-n17 {
    margin-left: -2.25rem !important
  }

  .m-sm-n18 {
    margin: -2.375rem !important
  }

  .mt-sm-n18,
  .my-sm-n18 {
    margin-top: -2.375rem !important
  }

  .mr-sm-n18,
  .mx-sm-n18 {
    margin-right: -2.375rem !important
  }

  .mb-sm-n18,
  .my-sm-n18 {
    margin-bottom: -2.375rem !important
  }

  .ml-sm-n18,
  .mx-sm-n18 {
    margin-left: -2.375rem !important
  }

  .m-sm-n19 {
    margin: -2.5rem !important
  }

  .mt-sm-n19,
  .my-sm-n19 {
    margin-top: -2.5rem !important
  }

  .mr-sm-n19,
  .mx-sm-n19 {
    margin-right: -2.5rem !important
  }

  .mb-sm-n19,
  .my-sm-n19 {
    margin-bottom: -2.5rem !important
  }

  .ml-sm-n19,
  .mx-sm-n19 {
    margin-left: -2.5rem !important
  }

  .m-sm-n20 {
    margin: -3.125rem !important
  }

  .mt-sm-n20,
  .my-sm-n20 {
    margin-top: -3.125rem !important
  }

  .mr-sm-n20,
  .mx-sm-n20 {
    margin-right: -3.125rem !important
  }

  .mb-sm-n20,
  .my-sm-n20 {
    margin-bottom: -3.125rem !important
  }

  .ml-sm-n20,
  .mx-sm-n20 {
    margin-left: -3.125rem !important
  }

  .m-sm-n21 {
    margin: -3.75rem !important
  }

  .mt-sm-n21,
  .my-sm-n21 {
    margin-top: -3.75rem !important
  }

  .mr-sm-n21,
  .mx-sm-n21 {
    margin-right: -3.75rem !important
  }

  .mb-sm-n21,
  .my-sm-n21 {
    margin-bottom: -3.75rem !important
  }

  .ml-sm-n21,
  .mx-sm-n21 {
    margin-left: -3.75rem !important
  }

  .m-sm-n22 {
    margin: -4.375rem !important
  }

  .mt-sm-n22,
  .my-sm-n22 {
    margin-top: -4.375rem !important
  }

  .mr-sm-n22,
  .mx-sm-n22 {
    margin-right: -4.375rem !important
  }

  .mb-sm-n22,
  .my-sm-n22 {
    margin-bottom: -4.375rem !important
  }

  .ml-sm-n22,
  .mx-sm-n22 {
    margin-left: -4.375rem !important
  }

  .m-sm-n23 {
    margin: -5rem !important
  }

  .mt-sm-n23,
  .my-sm-n23 {
    margin-top: -5rem !important
  }

  .mr-sm-n23,
  .mx-sm-n23 {
    margin-right: -5rem !important
  }

  .mb-sm-n23,
  .my-sm-n23 {
    margin-bottom: -5rem !important
  }

  .ml-sm-n23,
  .mx-sm-n23 {
    margin-left: -5rem !important
  }

  .m-sm-n24 {
    margin: -5.625rem !important
  }

  .mt-sm-n24,
  .my-sm-n24 {
    margin-top: -5.625rem !important
  }

  .mr-sm-n24,
  .mx-sm-n24 {
    margin-right: -5.625rem !important
  }

  .mb-sm-n24,
  .my-sm-n24 {
    margin-bottom: -5.625rem !important
  }

  .ml-sm-n24,
  .mx-sm-n24 {
    margin-left: -5.625rem !important
  }

  .m-sm-n25 {
    margin: -6.25rem !important
  }

  .mt-sm-n25,
  .my-sm-n25 {
    margin-top: -6.25rem !important
  }

  .mr-sm-n25,
  .mx-sm-n25 {
    margin-right: -6.25rem !important
  }

  .mb-sm-n25,
  .my-sm-n25 {
    margin-bottom: -6.25rem !important
  }

  .ml-sm-n25,
  .mx-sm-n25 {
    margin-left: -6.25rem !important
  }

  .m-sm-auto {
    margin: auto !important
  }

  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important
  }

  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important
  }

  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important
  }

  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important
  }
}

@media (min-width:768px) {
  .m-md-0 {
    margin: 0 !important
  }

  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important
  }

  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important
  }

  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important
  }

  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important
  }

  .m-md-1 {
    margin: .25rem !important
  }

  .mt-md-1,
  .my-md-1 {
    margin-top: .25rem !important
  }

  .mr-md-1,
  .mx-md-1 {
    margin-right: .25rem !important
  }

  .mb-md-1,
  .my-md-1 {
    margin-bottom: .25rem !important
  }

  .ml-md-1,
  .mx-md-1 {
    margin-left: .25rem !important
  }

  .m-md-2 {
    margin: .375rem !important
  }

  .mt-md-2,
  .my-md-2 {
    margin-top: .375rem !important
  }

  .mr-md-2,
  .mx-md-2 {
    margin-right: .375rem !important
  }

  .mb-md-2,
  .my-md-2 {
    margin-bottom: .375rem !important
  }

  .ml-md-2,
  .mx-md-2 {
    margin-left: .375rem !important
  }

  .m-md-3 {
    margin: .5rem !important
  }

  .mt-md-3,
  .my-md-3 {
    margin-top: .5rem !important
  }

  .mr-md-3,
  .mx-md-3 {
    margin-right: .5rem !important
  }

  .mb-md-3,
  .my-md-3 {
    margin-bottom: .5rem !important
  }

  .ml-md-3,
  .mx-md-3 {
    margin-left: .5rem !important
  }

  .m-md-4 {
    margin: .625rem !important
  }

  .mt-md-4,
  .my-md-4 {
    margin-top: .625rem !important
  }

  .mr-md-4,
  .mx-md-4 {
    margin-right: .625rem !important
  }

  .mb-md-4,
  .my-md-4 {
    margin-bottom: .625rem !important
  }

  .ml-md-4,
  .mx-md-4 {
    margin-left: .625rem !important
  }

  .m-md-5 {
    margin: .75rem !important
  }

  .mt-md-5,
  .my-md-5 {
    margin-top: .75rem !important
  }

  .mr-md-5,
  .mx-md-5 {
    margin-right: .75rem !important
  }

  .mb-md-5,
  .my-md-5 {
    margin-bottom: .75rem !important
  }

  .ml-md-5,
  .mx-md-5 {
    margin-left: .75rem !important
  }

  .m-md-6 {
    margin: .875rem !important
  }

  .mt-md-6,
  .my-md-6 {
    margin-top: .875rem !important
  }

  .mr-md-6,
  .mx-md-6 {
    margin-right: .875rem !important
  }

  .mb-md-6,
  .my-md-6 {
    margin-bottom: .875rem !important
  }

  .ml-md-6,
  .mx-md-6 {
    margin-left: .875rem !important
  }

  .m-md-7 {
    margin: 1rem !important
  }

  .mt-md-7,
  .my-md-7 {
    margin-top: 1rem !important
  }

  .mr-md-7,
  .mx-md-7 {
    margin-right: 1rem !important
  }

  .mb-md-7,
  .my-md-7 {
    margin-bottom: 1rem !important
  }

  .ml-md-7,
  .mx-md-7 {
    margin-left: 1rem !important
  }

  .m-md-8 {
    margin: 1.125rem !important
  }

  .mt-md-8,
  .my-md-8 {
    margin-top: 1.125rem !important
  }

  .mr-md-8,
  .mx-md-8 {
    margin-right: 1.125rem !important
  }

  .mb-md-8,
  .my-md-8 {
    margin-bottom: 1.125rem !important
  }

  .ml-md-8,
  .mx-md-8 {
    margin-left: 1.125rem !important
  }

  .m-md-9 {
    margin: 1.25rem !important
  }

  .mt-md-9,
  .my-md-9 {
    margin-top: 1.25rem !important
  }

  .mr-md-9,
  .mx-md-9 {
    margin-right: 1.25rem !important
  }

  .mb-md-9,
  .my-md-9 {
    margin-bottom: 1.25rem !important
  }

  .ml-md-9,
  .mx-md-9 {
    margin-left: 1.25rem !important
  }

  .m-md-10 {
    margin: 1.375rem !important
  }

  .mt-md-10,
  .my-md-10 {
    margin-top: 1.375rem !important
  }

  .mr-md-10,
  .mx-md-10 {
    margin-right: 1.375rem !important
  }

  .mb-md-10,
  .my-md-10 {
    margin-bottom: 1.375rem !important
  }

  .ml-md-10,
  .mx-md-10 {
    margin-left: 1.375rem !important
  }

  .m-md-11 {
    margin: 1.5rem !important
  }

  .mt-md-11,
  .my-md-11 {
    margin-top: 1.5rem !important
  }

  .mr-md-11,
  .mx-md-11 {
    margin-right: 1.5rem !important
  }

  .mb-md-11,
  .my-md-11 {
    margin-bottom: 1.5rem !important
  }

  .ml-md-11,
  .mx-md-11 {
    margin-left: 1.5rem !important
  }

  .m-md-12 {
    margin: 1.625rem !important
  }

  .mt-md-12,
  .my-md-12 {
    margin-top: 1.625rem !important
  }

  .mr-md-12,
  .mx-md-12 {
    margin-right: 1.625rem !important
  }

  .mb-md-12,
  .my-md-12 {
    margin-bottom: 1.625rem !important
  }

  .ml-md-12,
  .mx-md-12 {
    margin-left: 1.625rem !important
  }

  .m-md-13 {
    margin: 1.75rem !important
  }

  .mt-md-13,
  .my-md-13 {
    margin-top: 1.75rem !important
  }

  .mr-md-13,
  .mx-md-13 {
    margin-right: 1.75rem !important
  }

  .mb-md-13,
  .my-md-13 {
    margin-bottom: 1.75rem !important
  }

  .ml-md-13,
  .mx-md-13 {
    margin-left: 1.75rem !important
  }

  .m-md-14 {
    margin: 1.875rem !important
  }

  .mt-md-14,
  .my-md-14 {
    margin-top: 1.875rem !important
  }

  .mr-md-14,
  .mx-md-14 {
    margin-right: 1.875rem !important
  }

  .mb-md-14,
  .my-md-14 {
    margin-bottom: 1.875rem !important
  }

  .ml-md-14,
  .mx-md-14 {
    margin-left: 1.875rem !important
  }

  .m-md-15 {
    margin: 2rem !important
  }

  .mt-md-15,
  .my-md-15 {
    margin-top: 2rem !important
  }

  .mr-md-15,
  .mx-md-15 {
    margin-right: 2rem !important
  }

  .mb-md-15,
  .my-md-15 {
    margin-bottom: 2rem !important
  }

  .ml-md-15,
  .mx-md-15 {
    margin-left: 2rem !important
  }

  .m-md-16 {
    margin: 2.125rem !important
  }

  .mt-md-16,
  .my-md-16 {
    margin-top: 2.125rem !important
  }

  .mr-md-16,
  .mx-md-16 {
    margin-right: 2.125rem !important
  }

  .mb-md-16,
  .my-md-16 {
    margin-bottom: 2.125rem !important
  }

  .ml-md-16,
  .mx-md-16 {
    margin-left: 2.125rem !important
  }

  .m-md-17 {
    margin: 2.25rem !important
  }

  .mt-md-17,
  .my-md-17 {
    margin-top: 2.25rem !important
  }

  .mr-md-17,
  .mx-md-17 {
    margin-right: 2.25rem !important
  }

  .mb-md-17,
  .my-md-17 {
    margin-bottom: 2.25rem !important
  }

  .ml-md-17,
  .mx-md-17 {
    margin-left: 2.25rem !important
  }

  .m-md-18 {
    margin: 2.375rem !important
  }

  .mt-md-18,
  .my-md-18 {
    margin-top: 2.375rem !important
  }

  .mr-md-18,
  .mx-md-18 {
    margin-right: 2.375rem !important
  }

  .mb-md-18,
  .my-md-18 {
    margin-bottom: 2.375rem !important
  }

  .ml-md-18,
  .mx-md-18 {
    margin-left: 2.375rem !important
  }

  .m-md-19 {
    margin: 2.5rem !important
  }

  .mt-md-19,
  .my-md-19 {
    margin-top: 2.5rem !important
  }

  .mr-md-19,
  .mx-md-19 {
    margin-right: 2.5rem !important
  }

  .mb-md-19,
  .my-md-19 {
    margin-bottom: 2.5rem !important
  }

  .ml-md-19,
  .mx-md-19 {
    margin-left: 2.5rem !important
  }

  .m-md-20 {
    margin: 3.125rem !important
  }

  .mt-md-20,
  .my-md-20 {
    margin-top: 3.125rem !important
  }

  .mr-md-20,
  .mx-md-20 {
    margin-right: 3.125rem !important
  }

  .mb-md-20,
  .my-md-20 {
    margin-bottom: 3.125rem !important
  }

  .ml-md-20,
  .mx-md-20 {
    margin-left: 3.125rem !important
  }

  .m-md-21 {
    margin: 3.75rem !important
  }

  .mt-md-21,
  .my-md-21 {
    margin-top: 3.75rem !important
  }

  .mr-md-21,
  .mx-md-21 {
    margin-right: 3.75rem !important
  }

  .mb-md-21,
  .my-md-21 {
    margin-bottom: 3.75rem !important
  }

  .ml-md-21,
  .mx-md-21 {
    margin-left: 3.75rem !important
  }

  .m-md-22 {
    margin: 4.375rem !important
  }

  .mt-md-22,
  .my-md-22 {
    margin-top: 4.375rem !important
  }

  .mr-md-22,
  .mx-md-22 {
    margin-right: 4.375rem !important
  }

  .mb-md-22,
  .my-md-22 {
    margin-bottom: 4.375rem !important
  }

  .ml-md-22,
  .mx-md-22 {
    margin-left: 4.375rem !important
  }

  .m-md-23 {
    margin: 5rem !important
  }

  .mt-md-23,
  .my-md-23 {
    margin-top: 5rem !important
  }

  .mr-md-23,
  .mx-md-23 {
    margin-right: 5rem !important
  }

  .mb-md-23,
  .my-md-23 {
    margin-bottom: 5rem !important
  }

  .ml-md-23,
  .mx-md-23 {
    margin-left: 5rem !important
  }

  .m-md-24 {
    margin: 5.625rem !important
  }

  .mt-md-24,
  .my-md-24 {
    margin-top: 5.625rem !important
  }

  .mr-md-24,
  .mx-md-24 {
    margin-right: 5.625rem !important
  }

  .mb-md-24,
  .my-md-24 {
    margin-bottom: 5.625rem !important
  }

  .ml-md-24,
  .mx-md-24 {
    margin-left: 5.625rem !important
  }

  .m-md-25 {
    margin: 6.25rem !important
  }

  .mt-md-25,
  .my-md-25 {
    margin-top: 6.25rem !important
  }

  .mr-md-25,
  .mx-md-25 {
    margin-right: 6.25rem !important
  }

  .mb-md-25,
  .my-md-25 {
    margin-bottom: 6.25rem !important
  }

  .ml-md-25,
  .mx-md-25 {
    margin-left: 6.25rem !important
  }

  .p-md-0 {
    padding: 0 !important
  }

  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important
  }

  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important
  }

  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important
  }

  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important
  }

  .p-md-1 {
    padding: .25rem !important
  }

  .pt-md-1,
  .py-md-1 {
    padding-top: .25rem !important
  }

  .pr-md-1,
  .px-md-1 {
    padding-right: .25rem !important
  }

  .pb-md-1,
  .py-md-1 {
    padding-bottom: .25rem !important
  }

  .pl-md-1,
  .px-md-1 {
    padding-left: .25rem !important
  }

  .p-md-2 {
    padding: .375rem !important
  }

  .pt-md-2,
  .py-md-2 {
    padding-top: .375rem !important
  }

  .pr-md-2,
  .px-md-2 {
    padding-right: .375rem !important
  }

  .pb-md-2,
  .py-md-2 {
    padding-bottom: .375rem !important
  }

  .pl-md-2,
  .px-md-2 {
    padding-left: .375rem !important
  }

  .p-md-3 {
    padding: .5rem !important
  }

  .pt-md-3,
  .py-md-3 {
    padding-top: .5rem !important
  }

  .pr-md-3,
  .px-md-3 {
    padding-right: .5rem !important
  }

  .pb-md-3,
  .py-md-3 {
    padding-bottom: .5rem !important
  }

  .pl-md-3,
  .px-md-3 {
    padding-left: .5rem !important
  }

  .p-md-4 {
    padding: .625rem !important
  }

  .pt-md-4,
  .py-md-4 {
    padding-top: .625rem !important
  }

  .pr-md-4,
  .px-md-4 {
    padding-right: .625rem !important
  }

  .pb-md-4,
  .py-md-4 {
    padding-bottom: .625rem !important
  }

  .pl-md-4,
  .px-md-4 {
    padding-left: .625rem !important
  }

  .p-md-5 {
    padding: .75rem !important
  }

  .pt-md-5,
  .py-md-5 {
    padding-top: .75rem !important
  }

  .pr-md-5,
  .px-md-5 {
    padding-right: .75rem !important
  }

  .pb-md-5,
  .py-md-5 {
    padding-bottom: .75rem !important
  }

  .pl-md-5,
  .px-md-5 {
    padding-left: .75rem !important
  }

  .p-md-6 {
    padding: .875rem !important
  }

  .pt-md-6,
  .py-md-6 {
    padding-top: .875rem !important
  }

  .pr-md-6,
  .px-md-6 {
    padding-right: .875rem !important
  }

  .pb-md-6,
  .py-md-6 {
    padding-bottom: .875rem !important
  }

  .pl-md-6,
  .px-md-6 {
    padding-left: .875rem !important
  }

  .p-md-7 {
    padding: 1rem !important
  }

  .pt-md-7,
  .py-md-7 {
    padding-top: 1rem !important
  }

  .pr-md-7,
  .px-md-7 {
    padding-right: 1rem !important
  }

  .pb-md-7,
  .py-md-7 {
    padding-bottom: 1rem !important
  }

  .pl-md-7,
  .px-md-7 {
    padding-left: 1rem !important
  }

  .p-md-8 {
    padding: 1.125rem !important
  }

  .pt-md-8,
  .py-md-8 {
    padding-top: 1.125rem !important
  }

  .pr-md-8,
  .px-md-8 {
    padding-right: 1.125rem !important
  }

  .pb-md-8,
  .py-md-8 {
    padding-bottom: 1.125rem !important
  }

  .pl-md-8,
  .px-md-8 {
    padding-left: 1.125rem !important
  }

  .p-md-9 {
    padding: 1.25rem !important
  }

  .pt-md-9,
  .py-md-9 {
    padding-top: 1.25rem !important
  }

  .pr-md-9,
  .px-md-9 {
    padding-right: 1.25rem !important
  }

  .pb-md-9,
  .py-md-9 {
    padding-bottom: 1.25rem !important
  }

  .pl-md-9,
  .px-md-9 {
    padding-left: 1.25rem !important
  }

  .p-md-10 {
    padding: 1.375rem !important
  }

  .pt-md-10,
  .py-md-10 {
    padding-top: 1.375rem !important
  }

  .pr-md-10,
  .px-md-10 {
    padding-right: 1.375rem !important
  }

  .pb-md-10,
  .py-md-10 {
    padding-bottom: 1.375rem !important
  }

  .pl-md-10,
  .px-md-10 {
    padding-left: 1.375rem !important
  }

  .p-md-11 {
    padding: 1.5rem !important
  }

  .pt-md-11,
  .py-md-11 {
    padding-top: 1.5rem !important
  }

  .pr-md-11,
  .px-md-11 {
    padding-right: 1.5rem !important
  }

  .pb-md-11,
  .py-md-11 {
    padding-bottom: 1.5rem !important
  }

  .pl-md-11,
  .px-md-11 {
    padding-left: 1.5rem !important
  }

  .p-md-12 {
    padding: 1.625rem !important
  }

  .pt-md-12,
  .py-md-12 {
    padding-top: 1.625rem !important
  }

  .pr-md-12,
  .px-md-12 {
    padding-right: 1.625rem !important
  }

  .pb-md-12,
  .py-md-12 {
    padding-bottom: 1.625rem !important
  }

  .pl-md-12,
  .px-md-12 {
    padding-left: 1.625rem !important
  }

  .p-md-13 {
    padding: 1.75rem !important
  }

  .pt-md-13,
  .py-md-13 {
    padding-top: 1.75rem !important
  }

  .pr-md-13,
  .px-md-13 {
    padding-right: 1.75rem !important
  }

  .pb-md-13,
  .py-md-13 {
    padding-bottom: 1.75rem !important
  }

  .pl-md-13,
  .px-md-13 {
    padding-left: 1.75rem !important
  }

  .p-md-14 {
    padding: 1.875rem !important
  }

  .pt-md-14,
  .py-md-14 {
    padding-top: 1.875rem !important
  }

  .pr-md-14,
  .px-md-14 {
    padding-right: 1.875rem !important
  }

  .pb-md-14,
  .py-md-14 {
    padding-bottom: 1.875rem !important
  }

  .pl-md-14,
  .px-md-14 {
    padding-left: 1.875rem !important
  }

  .p-md-15 {
    padding: 2rem !important
  }

  .pt-md-15,
  .py-md-15 {
    padding-top: 2rem !important
  }

  .pr-md-15,
  .px-md-15 {
    padding-right: 2rem !important
  }

  .pb-md-15,
  .py-md-15 {
    padding-bottom: 2rem !important
  }

  .pl-md-15,
  .px-md-15 {
    padding-left: 2rem !important
  }

  .p-md-16 {
    padding: 2.125rem !important
  }

  .pt-md-16,
  .py-md-16 {
    padding-top: 2.125rem !important
  }

  .pr-md-16,
  .px-md-16 {
    padding-right: 2.125rem !important
  }

  .pb-md-16,
  .py-md-16 {
    padding-bottom: 2.125rem !important
  }

  .pl-md-16,
  .px-md-16 {
    padding-left: 2.125rem !important
  }

  .p-md-17 {
    padding: 2.25rem !important
  }

  .pt-md-17,
  .py-md-17 {
    padding-top: 2.25rem !important
  }

  .pr-md-17,
  .px-md-17 {
    padding-right: 2.25rem !important
  }

  .pb-md-17,
  .py-md-17 {
    padding-bottom: 2.25rem !important
  }

  .pl-md-17,
  .px-md-17 {
    padding-left: 2.25rem !important
  }

  .p-md-18 {
    padding: 2.375rem !important
  }

  .pt-md-18,
  .py-md-18 {
    padding-top: 2.375rem !important
  }

  .pr-md-18,
  .px-md-18 {
    padding-right: 2.375rem !important
  }

  .pb-md-18,
  .py-md-18 {
    padding-bottom: 2.375rem !important
  }

  .pl-md-18,
  .px-md-18 {
    padding-left: 2.375rem !important
  }

  .p-md-19 {
    padding: 2.5rem !important
  }

  .pt-md-19,
  .py-md-19 {
    padding-top: 2.5rem !important
  }

  .pr-md-19,
  .px-md-19 {
    padding-right: 2.5rem !important
  }

  .pb-md-19,
  .py-md-19 {
    padding-bottom: 2.5rem !important
  }

  .pl-md-19,
  .px-md-19 {
    padding-left: 2.5rem !important
  }

  .p-md-20 {
    padding: 3.125rem !important
  }

  .pt-md-20,
  .py-md-20 {
    padding-top: 3.125rem !important
  }

  .pr-md-20,
  .px-md-20 {
    padding-right: 3.125rem !important
  }

  .pb-md-20,
  .py-md-20 {
    padding-bottom: 3.125rem !important
  }

  .pl-md-20,
  .px-md-20 {
    padding-left: 3.125rem !important
  }

  .p-md-21 {
    padding: 3.75rem !important
  }

  .pt-md-21,
  .py-md-21 {
    padding-top: 3.75rem !important
  }

  .pr-md-21,
  .px-md-21 {
    padding-right: 3.75rem !important
  }

  .pb-md-21,
  .py-md-21 {
    padding-bottom: 3.75rem !important
  }

  .pl-md-21,
  .px-md-21 {
    padding-left: 3.75rem !important
  }

  .p-md-22 {
    padding: 4.375rem !important
  }

  .pt-md-22,
  .py-md-22 {
    padding-top: 4.375rem !important
  }

  .pr-md-22,
  .px-md-22 {
    padding-right: 4.375rem !important
  }

  .pb-md-22,
  .py-md-22 {
    padding-bottom: 4.375rem !important
  }

  .pl-md-22,
  .px-md-22 {
    padding-left: 4.375rem !important
  }

  .p-md-23 {
    padding: 5rem !important
  }

  .pt-md-23,
  .py-md-23 {
    padding-top: 5rem !important
  }

  .pr-md-23,
  .px-md-23 {
    padding-right: 5rem !important
  }

  .pb-md-23,
  .py-md-23 {
    padding-bottom: 5rem !important
  }

  .pl-md-23,
  .px-md-23 {
    padding-left: 5rem !important
  }

  .p-md-24 {
    padding: 5.625rem !important
  }

  .pt-md-24,
  .py-md-24 {
    padding-top: 5.625rem !important
  }

  .pr-md-24,
  .px-md-24 {
    padding-right: 5.625rem !important
  }

  .pb-md-24,
  .py-md-24 {
    padding-bottom: 5.625rem !important
  }

  .pl-md-24,
  .px-md-24 {
    padding-left: 5.625rem !important
  }

  .p-md-25 {
    padding: 6.25rem !important
  }

  .pt-md-25,
  .py-md-25 {
    padding-top: 6.25rem !important
  }

  .pr-md-25,
  .px-md-25 {
    padding-right: 6.25rem !important
  }

  .pb-md-25,
  .py-md-25 {
    padding-bottom: 6.25rem !important
  }

  .pl-md-25,
  .px-md-25 {
    padding-left: 6.25rem !important
  }

  .m-md-n1 {
    margin: -.25rem !important
  }

  .mt-md-n1,
  .my-md-n1 {
    margin-top: -.25rem !important
  }

  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -.25rem !important
  }

  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -.25rem !important
  }

  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -.25rem !important
  }

  .m-md-n2 {
    margin: -.375rem !important
  }

  .mt-md-n2,
  .my-md-n2 {
    margin-top: -.375rem !important
  }

  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -.375rem !important
  }

  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -.375rem !important
  }

  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -.375rem !important
  }

  .m-md-n3 {
    margin: -.5rem !important
  }

  .mt-md-n3,
  .my-md-n3 {
    margin-top: -.5rem !important
  }

  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -.5rem !important
  }

  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -.5rem !important
  }

  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -.5rem !important
  }

  .m-md-n4 {
    margin: -.625rem !important
  }

  .mt-md-n4,
  .my-md-n4 {
    margin-top: -.625rem !important
  }

  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -.625rem !important
  }

  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -.625rem !important
  }

  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -.625rem !important
  }

  .m-md-n5 {
    margin: -.75rem !important
  }

  .mt-md-n5,
  .my-md-n5 {
    margin-top: -.75rem !important
  }

  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -.75rem !important
  }

  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -.75rem !important
  }

  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -.75rem !important
  }

  .m-md-n6 {
    margin: -.875rem !important
  }

  .mt-md-n6,
  .my-md-n6 {
    margin-top: -.875rem !important
  }

  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -.875rem !important
  }

  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -.875rem !important
  }

  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -.875rem !important
  }

  .m-md-n7 {
    margin: -1rem !important
  }

  .mt-md-n7,
  .my-md-n7 {
    margin-top: -1rem !important
  }

  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -1rem !important
  }

  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -1rem !important
  }

  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -1rem !important
  }

  .m-md-n8 {
    margin: -1.125rem !important
  }

  .mt-md-n8,
  .my-md-n8 {
    margin-top: -1.125rem !important
  }

  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -1.125rem !important
  }

  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -1.125rem !important
  }

  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -1.125rem !important
  }

  .m-md-n9 {
    margin: -1.25rem !important
  }

  .mt-md-n9,
  .my-md-n9 {
    margin-top: -1.25rem !important
  }

  .mr-md-n9,
  .mx-md-n9 {
    margin-right: -1.25rem !important
  }

  .mb-md-n9,
  .my-md-n9 {
    margin-bottom: -1.25rem !important
  }

  .ml-md-n9,
  .mx-md-n9 {
    margin-left: -1.25rem !important
  }

  .m-md-n10 {
    margin: -1.375rem !important
  }

  .mt-md-n10,
  .my-md-n10 {
    margin-top: -1.375rem !important
  }

  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -1.375rem !important
  }

  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -1.375rem !important
  }

  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -1.375rem !important
  }

  .m-md-n11 {
    margin: -1.5rem !important
  }

  .mt-md-n11,
  .my-md-n11 {
    margin-top: -1.5rem !important
  }

  .mr-md-n11,
  .mx-md-n11 {
    margin-right: -1.5rem !important
  }

  .mb-md-n11,
  .my-md-n11 {
    margin-bottom: -1.5rem !important
  }

  .ml-md-n11,
  .mx-md-n11 {
    margin-left: -1.5rem !important
  }

  .m-md-n12 {
    margin: -1.625rem !important
  }

  .mt-md-n12,
  .my-md-n12 {
    margin-top: -1.625rem !important
  }

  .mr-md-n12,
  .mx-md-n12 {
    margin-right: -1.625rem !important
  }

  .mb-md-n12,
  .my-md-n12 {
    margin-bottom: -1.625rem !important
  }

  .ml-md-n12,
  .mx-md-n12 {
    margin-left: -1.625rem !important
  }

  .m-md-n13 {
    margin: -1.75rem !important
  }

  .mt-md-n13,
  .my-md-n13 {
    margin-top: -1.75rem !important
  }

  .mr-md-n13,
  .mx-md-n13 {
    margin-right: -1.75rem !important
  }

  .mb-md-n13,
  .my-md-n13 {
    margin-bottom: -1.75rem !important
  }

  .ml-md-n13,
  .mx-md-n13 {
    margin-left: -1.75rem !important
  }

  .m-md-n14 {
    margin: -1.875rem !important
  }

  .mt-md-n14,
  .my-md-n14 {
    margin-top: -1.875rem !important
  }

  .mr-md-n14,
  .mx-md-n14 {
    margin-right: -1.875rem !important
  }

  .mb-md-n14,
  .my-md-n14 {
    margin-bottom: -1.875rem !important
  }

  .ml-md-n14,
  .mx-md-n14 {
    margin-left: -1.875rem !important
  }

  .m-md-n15 {
    margin: -2rem !important
  }

  .mt-md-n15,
  .my-md-n15 {
    margin-top: -2rem !important
  }

  .mr-md-n15,
  .mx-md-n15 {
    margin-right: -2rem !important
  }

  .mb-md-n15,
  .my-md-n15 {
    margin-bottom: -2rem !important
  }

  .ml-md-n15,
  .mx-md-n15 {
    margin-left: -2rem !important
  }

  .m-md-n16 {
    margin: -2.125rem !important
  }

  .mt-md-n16,
  .my-md-n16 {
    margin-top: -2.125rem !important
  }

  .mr-md-n16,
  .mx-md-n16 {
    margin-right: -2.125rem !important
  }

  .mb-md-n16,
  .my-md-n16 {
    margin-bottom: -2.125rem !important
  }

  .ml-md-n16,
  .mx-md-n16 {
    margin-left: -2.125rem !important
  }

  .m-md-n17 {
    margin: -2.25rem !important
  }

  .mt-md-n17,
  .my-md-n17 {
    margin-top: -2.25rem !important
  }

  .mr-md-n17,
  .mx-md-n17 {
    margin-right: -2.25rem !important
  }

  .mb-md-n17,
  .my-md-n17 {
    margin-bottom: -2.25rem !important
  }

  .ml-md-n17,
  .mx-md-n17 {
    margin-left: -2.25rem !important
  }

  .m-md-n18 {
    margin: -2.375rem !important
  }

  .mt-md-n18,
  .my-md-n18 {
    margin-top: -2.375rem !important
  }

  .mr-md-n18,
  .mx-md-n18 {
    margin-right: -2.375rem !important
  }

  .mb-md-n18,
  .my-md-n18 {
    margin-bottom: -2.375rem !important
  }

  .ml-md-n18,
  .mx-md-n18 {
    margin-left: -2.375rem !important
  }

  .m-md-n19 {
    margin: -2.5rem !important
  }

  .mt-md-n19,
  .my-md-n19 {
    margin-top: -2.5rem !important
  }

  .mr-md-n19,
  .mx-md-n19 {
    margin-right: -2.5rem !important
  }

  .mb-md-n19,
  .my-md-n19 {
    margin-bottom: -2.5rem !important
  }

  .ml-md-n19,
  .mx-md-n19 {
    margin-left: -2.5rem !important
  }

  .m-md-n20 {
    margin: -3.125rem !important
  }

  .mt-md-n20,
  .my-md-n20 {
    margin-top: -3.125rem !important
  }

  .mr-md-n20,
  .mx-md-n20 {
    margin-right: -3.125rem !important
  }

  .mb-md-n20,
  .my-md-n20 {
    margin-bottom: -3.125rem !important
  }

  .ml-md-n20,
  .mx-md-n20 {
    margin-left: -3.125rem !important
  }

  .m-md-n21 {
    margin: -3.75rem !important
  }

  .mt-md-n21,
  .my-md-n21 {
    margin-top: -3.75rem !important
  }

  .mr-md-n21,
  .mx-md-n21 {
    margin-right: -3.75rem !important
  }

  .mb-md-n21,
  .my-md-n21 {
    margin-bottom: -3.75rem !important
  }

  .ml-md-n21,
  .mx-md-n21 {
    margin-left: -3.75rem !important
  }

  .m-md-n22 {
    margin: -4.375rem !important
  }

  .mt-md-n22,
  .my-md-n22 {
    margin-top: -4.375rem !important
  }

  .mr-md-n22,
  .mx-md-n22 {
    margin-right: -4.375rem !important
  }

  .mb-md-n22,
  .my-md-n22 {
    margin-bottom: -4.375rem !important
  }

  .ml-md-n22,
  .mx-md-n22 {
    margin-left: -4.375rem !important
  }

  .m-md-n23 {
    margin: -5rem !important
  }

  .mt-md-n23,
  .my-md-n23 {
    margin-top: -5rem !important
  }

  .mr-md-n23,
  .mx-md-n23 {
    margin-right: -5rem !important
  }

  .mb-md-n23,
  .my-md-n23 {
    margin-bottom: -5rem !important
  }

  .ml-md-n23,
  .mx-md-n23 {
    margin-left: -5rem !important
  }

  .m-md-n24 {
    margin: -5.625rem !important
  }

  .mt-md-n24,
  .my-md-n24 {
    margin-top: -5.625rem !important
  }

  .mr-md-n24,
  .mx-md-n24 {
    margin-right: -5.625rem !important
  }

  .mb-md-n24,
  .my-md-n24 {
    margin-bottom: -5.625rem !important
  }

  .ml-md-n24,
  .mx-md-n24 {
    margin-left: -5.625rem !important
  }

  .m-md-n25 {
    margin: -6.25rem !important
  }

  .mt-md-n25,
  .my-md-n25 {
    margin-top: -6.25rem !important
  }

  .mr-md-n25,
  .mx-md-n25 {
    margin-right: -6.25rem !important
  }

  .mb-md-n25,
  .my-md-n25 {
    margin-bottom: -6.25rem !important
  }

  .ml-md-n25,
  .mx-md-n25 {
    margin-left: -6.25rem !important
  }

  .m-md-auto {
    margin: auto !important
  }

  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important
  }

  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important
  }

  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important
  }

  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important
  }
}

@media (min-width:992px) {
  .m-lg-0 {
    margin: 0 !important
  }

  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important
  }

  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important
  }

  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important
  }

  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important
  }

  .m-lg-1 {
    margin: .25rem !important
  }

  .mt-lg-1,
  .my-lg-1 {
    margin-top: .25rem !important
  }

  .mr-lg-1,
  .mx-lg-1 {
    margin-right: .25rem !important
  }

  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: .25rem !important
  }

  .ml-lg-1,
  .mx-lg-1 {
    margin-left: .25rem !important
  }

  .m-lg-2 {
    margin: .375rem !important
  }

  .mt-lg-2,
  .my-lg-2 {
    margin-top: .375rem !important
  }

  .mr-lg-2,
  .mx-lg-2 {
    margin-right: .375rem !important
  }

  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: .375rem !important
  }

  .ml-lg-2,
  .mx-lg-2 {
    margin-left: .375rem !important
  }

  .m-lg-3 {
    margin: .5rem !important
  }

  .mt-lg-3,
  .my-lg-3 {
    margin-top: .5rem !important
  }

  .mr-lg-3,
  .mx-lg-3 {
    margin-right: .5rem !important
  }

  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: .5rem !important
  }

  .ml-lg-3,
  .mx-lg-3 {
    margin-left: .5rem !important
  }

  .m-lg-4 {
    margin: .625rem !important
  }

  .mt-lg-4,
  .my-lg-4 {
    margin-top: .625rem !important
  }

  .mr-lg-4,
  .mx-lg-4 {
    margin-right: .625rem !important
  }

  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: .625rem !important
  }

  .ml-lg-4,
  .mx-lg-4 {
    margin-left: .625rem !important
  }

  .m-lg-5 {
    margin: .75rem !important
  }

  .mt-lg-5,
  .my-lg-5 {
    margin-top: .75rem !important
  }

  .mr-lg-5,
  .mx-lg-5 {
    margin-right: .75rem !important
  }

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: .75rem !important
  }

  .ml-lg-5,
  .mx-lg-5 {
    margin-left: .75rem !important
  }

  .m-lg-6 {
    margin: .875rem !important
  }

  .mt-lg-6,
  .my-lg-6 {
    margin-top: .875rem !important
  }

  .mr-lg-6,
  .mx-lg-6 {
    margin-right: .875rem !important
  }

  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: .875rem !important
  }

  .ml-lg-6,
  .mx-lg-6 {
    margin-left: .875rem !important
  }

  .m-lg-7 {
    margin: 1rem !important
  }

  .mt-lg-7,
  .my-lg-7 {
    margin-top: 1rem !important
  }

  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 1rem !important
  }

  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 1rem !important
  }

  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 1rem !important
  }

  .m-lg-8 {
    margin: 1.125rem !important
  }

  .mt-lg-8,
  .my-lg-8 {
    margin-top: 1.125rem !important
  }

  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 1.125rem !important
  }

  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 1.125rem !important
  }

  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 1.125rem !important
  }

  .m-lg-9 {
    margin: 1.25rem !important
  }

  .mt-lg-9,
  .my-lg-9 {
    margin-top: 1.25rem !important
  }

  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 1.25rem !important
  }

  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 1.25rem !important
  }

  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 1.25rem !important
  }

  .m-lg-10 {
    margin: 1.375rem !important
  }

  .mt-lg-10,
  .my-lg-10 {
    margin-top: 1.375rem !important
  }

  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 1.375rem !important
  }

  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 1.375rem !important
  }

  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 1.375rem !important
  }

  .m-lg-11 {
    margin: 1.5rem !important
  }

  .mt-lg-11,
  .my-lg-11 {
    margin-top: 1.5rem !important
  }

  .mr-lg-11,
  .mx-lg-11 {
    margin-right: 1.5rem !important
  }

  .mb-lg-11,
  .my-lg-11 {
    margin-bottom: 1.5rem !important
  }

  .ml-lg-11,
  .mx-lg-11 {
    margin-left: 1.5rem !important
  }

  .m-lg-12 {
    margin: 1.625rem !important
  }

  .mt-lg-12,
  .my-lg-12 {
    margin-top: 1.625rem !important
  }

  .mr-lg-12,
  .mx-lg-12 {
    margin-right: 1.625rem !important
  }

  .mb-lg-12,
  .my-lg-12 {
    margin-bottom: 1.625rem !important
  }

  .ml-lg-12,
  .mx-lg-12 {
    margin-left: 1.625rem !important
  }

  .m-lg-13 {
    margin: 1.75rem !important
  }

  .mt-lg-13,
  .my-lg-13 {
    margin-top: 1.75rem !important
  }

  .mr-lg-13,
  .mx-lg-13 {
    margin-right: 1.75rem !important
  }

  .mb-lg-13,
  .my-lg-13 {
    margin-bottom: 1.75rem !important
  }

  .ml-lg-13,
  .mx-lg-13 {
    margin-left: 1.75rem !important
  }

  .m-lg-14 {
    margin: 1.875rem !important
  }

  .mt-lg-14,
  .my-lg-14 {
    margin-top: 1.875rem !important
  }

  .mr-lg-14,
  .mx-lg-14 {
    margin-right: 1.875rem !important
  }

  .mb-lg-14,
  .my-lg-14 {
    margin-bottom: 1.875rem !important
  }

  .ml-lg-14,
  .mx-lg-14 {
    margin-left: 1.875rem !important
  }

  .m-lg-15 {
    margin: 2rem !important
  }

  .mt-lg-15,
  .my-lg-15 {
    margin-top: 2rem !important
  }

  .mr-lg-15,
  .mx-lg-15 {
    margin-right: 2rem !important
  }

  .mb-lg-15,
  .my-lg-15 {
    margin-bottom: 2rem !important
  }

  .ml-lg-15,
  .mx-lg-15 {
    margin-left: 2rem !important
  }

  .m-lg-16 {
    margin: 2.125rem !important
  }

  .mt-lg-16,
  .my-lg-16 {
    margin-top: 2.125rem !important
  }

  .mr-lg-16,
  .mx-lg-16 {
    margin-right: 2.125rem !important
  }

  .mb-lg-16,
  .my-lg-16 {
    margin-bottom: 2.125rem !important
  }

  .ml-lg-16,
  .mx-lg-16 {
    margin-left: 2.125rem !important
  }

  .m-lg-17 {
    margin: 2.25rem !important
  }

  .mt-lg-17,
  .my-lg-17 {
    margin-top: 2.25rem !important
  }

  .mr-lg-17,
  .mx-lg-17 {
    margin-right: 2.25rem !important
  }

  .mb-lg-17,
  .my-lg-17 {
    margin-bottom: 2.25rem !important
  }

  .ml-lg-17,
  .mx-lg-17 {
    margin-left: 2.25rem !important
  }

  .m-lg-18 {
    margin: 2.375rem !important
  }

  .mt-lg-18,
  .my-lg-18 {
    margin-top: 2.375rem !important
  }

  .mr-lg-18,
  .mx-lg-18 {
    margin-right: 2.375rem !important
  }

  .mb-lg-18,
  .my-lg-18 {
    margin-bottom: 2.375rem !important
  }

  .ml-lg-18,
  .mx-lg-18 {
    margin-left: 2.375rem !important
  }

  .m-lg-19 {
    margin: 2.5rem !important
  }

  .mt-lg-19,
  .my-lg-19 {
    margin-top: 2.5rem !important
  }

  .mr-lg-19,
  .mx-lg-19 {
    margin-right: 2.5rem !important
  }

  .mb-lg-19,
  .my-lg-19 {
    margin-bottom: 2.5rem !important
  }

  .ml-lg-19,
  .mx-lg-19 {
    margin-left: 2.5rem !important
  }

  .m-lg-20 {
    margin: 3.125rem !important
  }

  .mt-lg-20,
  .my-lg-20 {
    margin-top: 3.125rem !important
  }

  .mr-lg-20,
  .mx-lg-20 {
    margin-right: 3.125rem !important
  }

  .mb-lg-20,
  .my-lg-20 {
    margin-bottom: 3.125rem !important
  }

  .ml-lg-20,
  .mx-lg-20 {
    margin-left: 3.125rem !important
  }

  .m-lg-21 {
    margin: 3.75rem !important
  }

  .mt-lg-21,
  .my-lg-21 {
    margin-top: 3.75rem !important
  }

  .mr-lg-21,
  .mx-lg-21 {
    margin-right: 3.75rem !important
  }

  .mb-lg-21,
  .my-lg-21 {
    margin-bottom: 3.75rem !important
  }

  .ml-lg-21,
  .mx-lg-21 {
    margin-left: 3.75rem !important
  }

  .m-lg-22 {
    margin: 4.375rem !important
  }

  .mt-lg-22,
  .my-lg-22 {
    margin-top: 4.375rem !important
  }

  .mr-lg-22,
  .mx-lg-22 {
    margin-right: 4.375rem !important
  }

  .mb-lg-22,
  .my-lg-22 {
    margin-bottom: 4.375rem !important
  }

  .ml-lg-22,
  .mx-lg-22 {
    margin-left: 4.375rem !important
  }

  .m-lg-23 {
    margin: 5rem !important
  }

  .mt-lg-23,
  .my-lg-23 {
    margin-top: 5rem !important
  }

  .mr-lg-23,
  .mx-lg-23 {
    margin-right: 5rem !important
  }

  .mb-lg-23,
  .my-lg-23 {
    margin-bottom: 5rem !important
  }

  .ml-lg-23,
  .mx-lg-23 {
    margin-left: 5rem !important
  }

  .m-lg-24 {
    margin: 5.625rem !important
  }

  .mt-lg-24,
  .my-lg-24 {
    margin-top: 5.625rem !important
  }

  .mr-lg-24,
  .mx-lg-24 {
    margin-right: 5.625rem !important
  }

  .mb-lg-24,
  .my-lg-24 {
    margin-bottom: 5.625rem !important
  }

  .ml-lg-24,
  .mx-lg-24 {
    margin-left: 5.625rem !important
  }

  .m-lg-25 {
    margin: 6.25rem !important
  }

  .mt-lg-25,
  .my-lg-25 {
    margin-top: 6.25rem !important
  }

  .mr-lg-25,
  .mx-lg-25 {
    margin-right: 6.25rem !important
  }

  .mb-lg-25,
  .my-lg-25 {
    margin-bottom: 6.25rem !important
  }

  .ml-lg-25,
  .mx-lg-25 {
    margin-left: 6.25rem !important
  }

  .p-lg-0 {
    padding: 0 !important
  }

  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important
  }

  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important
  }

  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important
  }

  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important
  }

  .p-lg-1 {
    padding: .25rem !important
  }

  .pt-lg-1,
  .py-lg-1 {
    padding-top: .25rem !important
  }

  .pr-lg-1,
  .px-lg-1 {
    padding-right: .25rem !important
  }

  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: .25rem !important
  }

  .pl-lg-1,
  .px-lg-1 {
    padding-left: .25rem !important
  }

  .p-lg-2 {
    padding: .375rem !important
  }

  .pt-lg-2,
  .py-lg-2 {
    padding-top: .375rem !important
  }

  .pr-lg-2,
  .px-lg-2 {
    padding-right: .375rem !important
  }

  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: .375rem !important
  }

  .pl-lg-2,
  .px-lg-2 {
    padding-left: .375rem !important
  }

  .p-lg-3 {
    padding: .5rem !important
  }

  .pt-lg-3,
  .py-lg-3 {
    padding-top: .5rem !important
  }

  .pr-lg-3,
  .px-lg-3 {
    padding-right: .5rem !important
  }

  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: .5rem !important
  }

  .pl-lg-3,
  .px-lg-3 {
    padding-left: .5rem !important
  }

  .p-lg-4 {
    padding: .625rem !important
  }

  .pt-lg-4,
  .py-lg-4 {
    padding-top: .625rem !important
  }

  .pr-lg-4,
  .px-lg-4 {
    padding-right: .625rem !important
  }

  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: .625rem !important
  }

  .pl-lg-4,
  .px-lg-4 {
    padding-left: .625rem !important
  }

  .p-lg-5 {
    padding: .75rem !important
  }

  .pt-lg-5,
  .py-lg-5 {
    padding-top: .75rem !important
  }

  .pr-lg-5,
  .px-lg-5 {
    padding-right: .75rem !important
  }

  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: .75rem !important
  }

  .pl-lg-5,
  .px-lg-5 {
    padding-left: .75rem !important
  }

  .p-lg-6 {
    padding: .875rem !important
  }

  .pt-lg-6,
  .py-lg-6 {
    padding-top: .875rem !important
  }

  .pr-lg-6,
  .px-lg-6 {
    padding-right: .875rem !important
  }

  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: .875rem !important
  }

  .pl-lg-6,
  .px-lg-6 {
    padding-left: .875rem !important
  }

  .p-lg-7 {
    padding: 1rem !important
  }

  .pt-lg-7,
  .py-lg-7 {
    padding-top: 1rem !important
  }

  .pr-lg-7,
  .px-lg-7 {
    padding-right: 1rem !important
  }

  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 1rem !important
  }

  .pl-lg-7,
  .px-lg-7 {
    padding-left: 1rem !important
  }

  .p-lg-8 {
    padding: 1.125rem !important
  }

  .pt-lg-8,
  .py-lg-8 {
    padding-top: 1.125rem !important
  }

  .pr-lg-8,
  .px-lg-8 {
    padding-right: 1.125rem !important
  }

  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 1.125rem !important
  }

  .pl-lg-8,
  .px-lg-8 {
    padding-left: 1.125rem !important
  }

  .p-lg-9 {
    padding: 1.25rem !important
  }

  .pt-lg-9,
  .py-lg-9 {
    padding-top: 1.25rem !important
  }

  .pr-lg-9,
  .px-lg-9 {
    padding-right: 1.25rem !important
  }

  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 1.25rem !important
  }

  .pl-lg-9,
  .px-lg-9 {
    padding-left: 1.25rem !important
  }

  .p-lg-10 {
    padding: 1.375rem !important
  }

  .pt-lg-10,
  .py-lg-10 {
    padding-top: 1.375rem !important
  }

  .pr-lg-10,
  .px-lg-10 {
    padding-right: 1.375rem !important
  }

  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 1.375rem !important
  }

  .pl-lg-10,
  .px-lg-10 {
    padding-left: 1.375rem !important
  }

  .p-lg-11 {
    padding: 1.5rem !important
  }

  .pt-lg-11,
  .py-lg-11 {
    padding-top: 1.5rem !important
  }

  .pr-lg-11,
  .px-lg-11 {
    padding-right: 1.5rem !important
  }

  .pb-lg-11,
  .py-lg-11 {
    padding-bottom: 1.5rem !important
  }

  .pl-lg-11,
  .px-lg-11 {
    padding-left: 1.5rem !important
  }

  .p-lg-12 {
    padding: 1.625rem !important
  }

  .pt-lg-12,
  .py-lg-12 {
    padding-top: 1.625rem !important
  }

  .pr-lg-12,
  .px-lg-12 {
    padding-right: 1.625rem !important
  }

  .pb-lg-12,
  .py-lg-12 {
    padding-bottom: 1.625rem !important
  }

  .pl-lg-12,
  .px-lg-12 {
    padding-left: 1.625rem !important
  }

  .p-lg-13 {
    padding: 1.75rem !important
  }

  .pt-lg-13,
  .py-lg-13 {
    padding-top: 1.75rem !important
  }

  .pr-lg-13,
  .px-lg-13 {
    padding-right: 1.75rem !important
  }

  .pb-lg-13,
  .py-lg-13 {
    padding-bottom: 1.75rem !important
  }

  .pl-lg-13,
  .px-lg-13 {
    padding-left: 1.75rem !important
  }

  .p-lg-14 {
    padding: 1.875rem !important
  }

  .pt-lg-14,
  .py-lg-14 {
    padding-top: 1.875rem !important
  }

  .pr-lg-14,
  .px-lg-14 {
    padding-right: 1.875rem !important
  }

  .pb-lg-14,
  .py-lg-14 {
    padding-bottom: 1.875rem !important
  }

  .pl-lg-14,
  .px-lg-14 {
    padding-left: 1.875rem !important
  }

  .p-lg-15 {
    padding: 2rem !important
  }

  .pt-lg-15,
  .py-lg-15 {
    padding-top: 2rem !important
  }

  .pr-lg-15,
  .px-lg-15 {
    padding-right: 2rem !important
  }

  .pb-lg-15,
  .py-lg-15 {
    padding-bottom: 2rem !important
  }

  .pl-lg-15,
  .px-lg-15 {
    padding-left: 2rem !important
  }

  .p-lg-16 {
    padding: 2.125rem !important
  }

  .pt-lg-16,
  .py-lg-16 {
    padding-top: 2.125rem !important
  }

  .pr-lg-16,
  .px-lg-16 {
    padding-right: 2.125rem !important
  }

  .pb-lg-16,
  .py-lg-16 {
    padding-bottom: 2.125rem !important
  }

  .pl-lg-16,
  .px-lg-16 {
    padding-left: 2.125rem !important
  }

  .p-lg-17 {
    padding: 2.25rem !important
  }

  .pt-lg-17,
  .py-lg-17 {
    padding-top: 2.25rem !important
  }

  .pr-lg-17,
  .px-lg-17 {
    padding-right: 2.25rem !important
  }

  .pb-lg-17,
  .py-lg-17 {
    padding-bottom: 2.25rem !important
  }

  .pl-lg-17,
  .px-lg-17 {
    padding-left: 2.25rem !important
  }

  .p-lg-18 {
    padding: 2.375rem !important
  }

  .pt-lg-18,
  .py-lg-18 {
    padding-top: 2.375rem !important
  }

  .pr-lg-18,
  .px-lg-18 {
    padding-right: 2.375rem !important
  }

  .pb-lg-18,
  .py-lg-18 {
    padding-bottom: 2.375rem !important
  }

  .pl-lg-18,
  .px-lg-18 {
    padding-left: 2.375rem !important
  }

  .p-lg-19 {
    padding: 2.5rem !important
  }

  .pt-lg-19,
  .py-lg-19 {
    padding-top: 2.5rem !important
  }

  .pr-lg-19,
  .px-lg-19 {
    padding-right: 2.5rem !important
  }

  .pb-lg-19,
  .py-lg-19 {
    padding-bottom: 2.5rem !important
  }

  .pl-lg-19,
  .px-lg-19 {
    padding-left: 2.5rem !important
  }

  .p-lg-20 {
    padding: 3.125rem !important
  }

  .pt-lg-20,
  .py-lg-20 {
    padding-top: 3.125rem !important
  }

  .pr-lg-20,
  .px-lg-20 {
    padding-right: 3.125rem !important
  }

  .pb-lg-20,
  .py-lg-20 {
    padding-bottom: 3.125rem !important
  }

  .pl-lg-20,
  .px-lg-20 {
    padding-left: 3.125rem !important
  }

  .p-lg-21 {
    padding: 3.75rem !important
  }

  .pt-lg-21,
  .py-lg-21 {
    padding-top: 3.75rem !important
  }

  .pr-lg-21,
  .px-lg-21 {
    padding-right: 3.75rem !important
  }

  .pb-lg-21,
  .py-lg-21 {
    padding-bottom: 3.75rem !important
  }

  .pl-lg-21,
  .px-lg-21 {
    padding-left: 3.75rem !important
  }

  .p-lg-22 {
    padding: 4.375rem !important
  }

  .pt-lg-22,
  .py-lg-22 {
    padding-top: 4.375rem !important
  }

  .pr-lg-22,
  .px-lg-22 {
    padding-right: 4.375rem !important
  }

  .pb-lg-22,
  .py-lg-22 {
    padding-bottom: 4.375rem !important
  }

  .pl-lg-22,
  .px-lg-22 {
    padding-left: 4.375rem !important
  }

  .p-lg-23 {
    padding: 5rem !important
  }

  .pt-lg-23,
  .py-lg-23 {
    padding-top: 5rem !important
  }

  .pr-lg-23,
  .px-lg-23 {
    padding-right: 5rem !important
  }

  .pb-lg-23,
  .py-lg-23 {
    padding-bottom: 5rem !important
  }

  .pl-lg-23,
  .px-lg-23 {
    padding-left: 5rem !important
  }

  .p-lg-24 {
    padding: 5.625rem !important
  }

  .pt-lg-24,
  .py-lg-24 {
    padding-top: 5.625rem !important
  }

  .pr-lg-24,
  .px-lg-24 {
    padding-right: 5.625rem !important
  }

  .pb-lg-24,
  .py-lg-24 {
    padding-bottom: 5.625rem !important
  }

  .pl-lg-24,
  .px-lg-24 {
    padding-left: 5.625rem !important
  }

  .p-lg-25 {
    padding: 6.25rem !important
  }

  .pt-lg-25,
  .py-lg-25 {
    padding-top: 6.25rem !important
  }

  .pr-lg-25,
  .px-lg-25 {
    padding-right: 6.25rem !important
  }

  .pb-lg-25,
  .py-lg-25 {
    padding-bottom: 6.25rem !important
  }

  .pl-lg-25,
  .px-lg-25 {
    padding-left: 6.25rem !important
  }

  .m-lg-n1 {
    margin: -.25rem !important
  }

  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -.25rem !important
  }

  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -.25rem !important
  }

  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -.25rem !important
  }

  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -.25rem !important
  }

  .m-lg-n2 {
    margin: -.375rem !important
  }

  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -.375rem !important
  }

  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -.375rem !important
  }

  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -.375rem !important
  }

  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -.375rem !important
  }

  .m-lg-n3 {
    margin: -.5rem !important
  }

  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -.5rem !important
  }

  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -.5rem !important
  }

  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -.5rem !important
  }

  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -.5rem !important
  }

  .m-lg-n4 {
    margin: -.625rem !important
  }

  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -.625rem !important
  }

  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -.625rem !important
  }

  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -.625rem !important
  }

  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -.625rem !important
  }

  .m-lg-n5 {
    margin: -.75rem !important
  }

  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -.75rem !important
  }

  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -.75rem !important
  }

  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -.75rem !important
  }

  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -.75rem !important
  }

  .m-lg-n6 {
    margin: -.875rem !important
  }

  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -.875rem !important
  }

  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -.875rem !important
  }

  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -.875rem !important
  }

  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -.875rem !important
  }

  .m-lg-n7 {
    margin: -1rem !important
  }

  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -1rem !important
  }

  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -1rem !important
  }

  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -1rem !important
  }

  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -1rem !important
  }

  .m-lg-n8 {
    margin: -1.125rem !important
  }

  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -1.125rem !important
  }

  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -1.125rem !important
  }

  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -1.125rem !important
  }

  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -1.125rem !important
  }

  .m-lg-n9 {
    margin: -1.25rem !important
  }

  .mt-lg-n9,
  .my-lg-n9 {
    margin-top: -1.25rem !important
  }

  .mr-lg-n9,
  .mx-lg-n9 {
    margin-right: -1.25rem !important
  }

  .mb-lg-n9,
  .my-lg-n9 {
    margin-bottom: -1.25rem !important
  }

  .ml-lg-n9,
  .mx-lg-n9 {
    margin-left: -1.25rem !important
  }

  .m-lg-n10 {
    margin: -1.375rem !important
  }

  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -1.375rem !important
  }

  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -1.375rem !important
  }

  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -1.375rem !important
  }

  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -1.375rem !important
  }

  .m-lg-n11 {
    margin: -1.5rem !important
  }

  .mt-lg-n11,
  .my-lg-n11 {
    margin-top: -1.5rem !important
  }

  .mr-lg-n11,
  .mx-lg-n11 {
    margin-right: -1.5rem !important
  }

  .mb-lg-n11,
  .my-lg-n11 {
    margin-bottom: -1.5rem !important
  }

  .ml-lg-n11,
  .mx-lg-n11 {
    margin-left: -1.5rem !important
  }

  .m-lg-n12 {
    margin: -1.625rem !important
  }

  .mt-lg-n12,
  .my-lg-n12 {
    margin-top: -1.625rem !important
  }

  .mr-lg-n12,
  .mx-lg-n12 {
    margin-right: -1.625rem !important
  }

  .mb-lg-n12,
  .my-lg-n12 {
    margin-bottom: -1.625rem !important
  }

  .ml-lg-n12,
  .mx-lg-n12 {
    margin-left: -1.625rem !important
  }

  .m-lg-n13 {
    margin: -1.75rem !important
  }

  .mt-lg-n13,
  .my-lg-n13 {
    margin-top: -1.75rem !important
  }

  .mr-lg-n13,
  .mx-lg-n13 {
    margin-right: -1.75rem !important
  }

  .mb-lg-n13,
  .my-lg-n13 {
    margin-bottom: -1.75rem !important
  }

  .ml-lg-n13,
  .mx-lg-n13 {
    margin-left: -1.75rem !important
  }

  .m-lg-n14 {
    margin: -1.875rem !important
  }

  .mt-lg-n14,
  .my-lg-n14 {
    margin-top: -1.875rem !important
  }

  .mr-lg-n14,
  .mx-lg-n14 {
    margin-right: -1.875rem !important
  }

  .mb-lg-n14,
  .my-lg-n14 {
    margin-bottom: -1.875rem !important
  }

  .ml-lg-n14,
  .mx-lg-n14 {
    margin-left: -1.875rem !important
  }

  .m-lg-n15 {
    margin: -2rem !important
  }

  .mt-lg-n15,
  .my-lg-n15 {
    margin-top: -2rem !important
  }

  .mr-lg-n15,
  .mx-lg-n15 {
    margin-right: -2rem !important
  }

  .mb-lg-n15,
  .my-lg-n15 {
    margin-bottom: -2rem !important
  }

  .ml-lg-n15,
  .mx-lg-n15 {
    margin-left: -2rem !important
  }

  .m-lg-n16 {
    margin: -2.125rem !important
  }

  .mt-lg-n16,
  .my-lg-n16 {
    margin-top: -2.125rem !important
  }

  .mr-lg-n16,
  .mx-lg-n16 {
    margin-right: -2.125rem !important
  }

  .mb-lg-n16,
  .my-lg-n16 {
    margin-bottom: -2.125rem !important
  }

  .ml-lg-n16,
  .mx-lg-n16 {
    margin-left: -2.125rem !important
  }

  .m-lg-n17 {
    margin: -2.25rem !important
  }

  .mt-lg-n17,
  .my-lg-n17 {
    margin-top: -2.25rem !important
  }

  .mr-lg-n17,
  .mx-lg-n17 {
    margin-right: -2.25rem !important
  }

  .mb-lg-n17,
  .my-lg-n17 {
    margin-bottom: -2.25rem !important
  }

  .ml-lg-n17,
  .mx-lg-n17 {
    margin-left: -2.25rem !important
  }

  .m-lg-n18 {
    margin: -2.375rem !important
  }

  .mt-lg-n18,
  .my-lg-n18 {
    margin-top: -2.375rem !important
  }

  .mr-lg-n18,
  .mx-lg-n18 {
    margin-right: -2.375rem !important
  }

  .mb-lg-n18,
  .my-lg-n18 {
    margin-bottom: -2.375rem !important
  }

  .ml-lg-n18,
  .mx-lg-n18 {
    margin-left: -2.375rem !important
  }

  .m-lg-n19 {
    margin: -2.5rem !important
  }

  .mt-lg-n19,
  .my-lg-n19 {
    margin-top: -2.5rem !important
  }

  .mr-lg-n19,
  .mx-lg-n19 {
    margin-right: -2.5rem !important
  }

  .mb-lg-n19,
  .my-lg-n19 {
    margin-bottom: -2.5rem !important
  }

  .ml-lg-n19,
  .mx-lg-n19 {
    margin-left: -2.5rem !important
  }

  .m-lg-n20 {
    margin: -3.125rem !important
  }

  .mt-lg-n20,
  .my-lg-n20 {
    margin-top: -3.125rem !important
  }

  .mr-lg-n20,
  .mx-lg-n20 {
    margin-right: -3.125rem !important
  }

  .mb-lg-n20,
  .my-lg-n20 {
    margin-bottom: -3.125rem !important
  }

  .ml-lg-n20,
  .mx-lg-n20 {
    margin-left: -3.125rem !important
  }

  .m-lg-n21 {
    margin: -3.75rem !important
  }

  .mt-lg-n21,
  .my-lg-n21 {
    margin-top: -3.75rem !important
  }

  .mr-lg-n21,
  .mx-lg-n21 {
    margin-right: -3.75rem !important
  }

  .mb-lg-n21,
  .my-lg-n21 {
    margin-bottom: -3.75rem !important
  }

  .ml-lg-n21,
  .mx-lg-n21 {
    margin-left: -3.75rem !important
  }

  .m-lg-n22 {
    margin: -4.375rem !important
  }

  .mt-lg-n22,
  .my-lg-n22 {
    margin-top: -4.375rem !important
  }

  .mr-lg-n22,
  .mx-lg-n22 {
    margin-right: -4.375rem !important
  }

  .mb-lg-n22,
  .my-lg-n22 {
    margin-bottom: -4.375rem !important
  }

  .ml-lg-n22,
  .mx-lg-n22 {
    margin-left: -4.375rem !important
  }

  .m-lg-n23 {
    margin: -5rem !important
  }

  .mt-lg-n23,
  .my-lg-n23 {
    margin-top: -5rem !important
  }

  .mr-lg-n23,
  .mx-lg-n23 {
    margin-right: -5rem !important
  }

  .mb-lg-n23,
  .my-lg-n23 {
    margin-bottom: -5rem !important
  }

  .ml-lg-n23,
  .mx-lg-n23 {
    margin-left: -5rem !important
  }

  .m-lg-n24 {
    margin: -5.625rem !important
  }

  .mt-lg-n24,
  .my-lg-n24 {
    margin-top: -5.625rem !important
  }

  .mr-lg-n24,
  .mx-lg-n24 {
    margin-right: -5.625rem !important
  }

  .mb-lg-n24,
  .my-lg-n24 {
    margin-bottom: -5.625rem !important
  }

  .ml-lg-n24,
  .mx-lg-n24 {
    margin-left: -5.625rem !important
  }

  .m-lg-n25 {
    margin: -6.25rem !important
  }

  .mt-lg-n25,
  .my-lg-n25 {
    margin-top: -6.25rem !important
  }

  .mr-lg-n25,
  .mx-lg-n25 {
    margin-right: -6.25rem !important
  }

  .mb-lg-n25,
  .my-lg-n25 {
    margin-bottom: -6.25rem !important
  }

  .ml-lg-n25,
  .mx-lg-n25 {
    margin-left: -6.25rem !important
  }

  .m-lg-auto {
    margin: auto !important
  }

  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important
  }

  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important
  }

  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important
  }

  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important
  }
}

@media (min-width:1200px) {
  .m-xl-0 {
    margin: 0 !important
  }

  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important
  }

  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important
  }

  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important
  }

  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important
  }

  .m-xl-1 {
    margin: .25rem !important
  }

  .mt-xl-1,
  .my-xl-1 {
    margin-top: .25rem !important
  }

  .mr-xl-1,
  .mx-xl-1 {
    margin-right: .25rem !important
  }

  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: .25rem !important
  }

  .ml-xl-1,
  .mx-xl-1 {
    margin-left: .25rem !important
  }

  .m-xl-2 {
    margin: .375rem !important
  }

  .mt-xl-2,
  .my-xl-2 {
    margin-top: .375rem !important
  }

  .mr-xl-2,
  .mx-xl-2 {
    margin-right: .375rem !important
  }

  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: .375rem !important
  }

  .ml-xl-2,
  .mx-xl-2 {
    margin-left: .375rem !important
  }

  .m-xl-3 {
    margin: .5rem !important
  }

  .mt-xl-3,
  .my-xl-3 {
    margin-top: .5rem !important
  }

  .mr-xl-3,
  .mx-xl-3 {
    margin-right: .5rem !important
  }

  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: .5rem !important
  }

  .ml-xl-3,
  .mx-xl-3 {
    margin-left: .5rem !important
  }

  .m-xl-4 {
    margin: .625rem !important
  }

  .mt-xl-4,
  .my-xl-4 {
    margin-top: .625rem !important
  }

  .mr-xl-4,
  .mx-xl-4 {
    margin-right: .625rem !important
  }

  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: .625rem !important
  }

  .ml-xl-4,
  .mx-xl-4 {
    margin-left: .625rem !important
  }

  .m-xl-5 {
    margin: .75rem !important
  }

  .mt-xl-5,
  .my-xl-5 {
    margin-top: .75rem !important
  }

  .mr-xl-5,
  .mx-xl-5 {
    margin-right: .75rem !important
  }

  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: .75rem !important
  }

  .ml-xl-5,
  .mx-xl-5 {
    margin-left: .75rem !important
  }

  .m-xl-6 {
    margin: .875rem !important
  }

  .mt-xl-6,
  .my-xl-6 {
    margin-top: .875rem !important
  }

  .mr-xl-6,
  .mx-xl-6 {
    margin-right: .875rem !important
  }

  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: .875rem !important
  }

  .ml-xl-6,
  .mx-xl-6 {
    margin-left: .875rem !important
  }

  .m-xl-7 {
    margin: 1rem !important
  }

  .mt-xl-7,
  .my-xl-7 {
    margin-top: 1rem !important
  }

  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 1rem !important
  }

  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 1rem !important
  }

  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 1rem !important
  }

  .m-xl-8 {
    margin: 1.125rem !important
  }

  .mt-xl-8,
  .my-xl-8 {
    margin-top: 1.125rem !important
  }

  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 1.125rem !important
  }

  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 1.125rem !important
  }

  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 1.125rem !important
  }

  .m-xl-9 {
    margin: 1.25rem !important
  }

  .mt-xl-9,
  .my-xl-9 {
    margin-top: 1.25rem !important
  }

  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 1.25rem !important
  }

  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 1.25rem !important
  }

  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 1.25rem !important
  }

  .m-xl-10 {
    margin: 1.375rem !important
  }

  .mt-xl-10,
  .my-xl-10 {
    margin-top: 1.375rem !important
  }

  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 1.375rem !important
  }

  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 1.375rem !important
  }

  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 1.375rem !important
  }

  .m-xl-11 {
    margin: 1.5rem !important
  }

  .mt-xl-11,
  .my-xl-11 {
    margin-top: 1.5rem !important
  }

  .mr-xl-11,
  .mx-xl-11 {
    margin-right: 1.5rem !important
  }

  .mb-xl-11,
  .my-xl-11 {
    margin-bottom: 1.5rem !important
  }

  .ml-xl-11,
  .mx-xl-11 {
    margin-left: 1.5rem !important
  }

  .m-xl-12 {
    margin: 1.625rem !important
  }

  .mt-xl-12,
  .my-xl-12 {
    margin-top: 1.625rem !important
  }

  .mr-xl-12,
  .mx-xl-12 {
    margin-right: 1.625rem !important
  }

  .mb-xl-12,
  .my-xl-12 {
    margin-bottom: 1.625rem !important
  }

  .ml-xl-12,
  .mx-xl-12 {
    margin-left: 1.625rem !important
  }

  .m-xl-13 {
    margin: 1.75rem !important
  }

  .mt-xl-13,
  .my-xl-13 {
    margin-top: 1.75rem !important
  }

  .mr-xl-13,
  .mx-xl-13 {
    margin-right: 1.75rem !important
  }

  .mb-xl-13,
  .my-xl-13 {
    margin-bottom: 1.75rem !important
  }

  .ml-xl-13,
  .mx-xl-13 {
    margin-left: 1.75rem !important
  }

  .m-xl-14 {
    margin: 1.875rem !important
  }

  .mt-xl-14,
  .my-xl-14 {
    margin-top: 1.875rem !important
  }

  .mr-xl-14,
  .mx-xl-14 {
    margin-right: 1.875rem !important
  }

  .mb-xl-14,
  .my-xl-14 {
    margin-bottom: 1.875rem !important
  }

  .ml-xl-14,
  .mx-xl-14 {
    margin-left: 1.875rem !important
  }

  .m-xl-15 {
    margin: 2rem !important
  }

  .mt-xl-15,
  .my-xl-15 {
    margin-top: 2rem !important
  }

  .mr-xl-15,
  .mx-xl-15 {
    margin-right: 2rem !important
  }

  .mb-xl-15,
  .my-xl-15 {
    margin-bottom: 2rem !important
  }

  .ml-xl-15,
  .mx-xl-15 {
    margin-left: 2rem !important
  }

  .m-xl-16 {
    margin: 2.125rem !important
  }

  .mt-xl-16,
  .my-xl-16 {
    margin-top: 2.125rem !important
  }

  .mr-xl-16,
  .mx-xl-16 {
    margin-right: 2.125rem !important
  }

  .mb-xl-16,
  .my-xl-16 {
    margin-bottom: 2.125rem !important
  }

  .ml-xl-16,
  .mx-xl-16 {
    margin-left: 2.125rem !important
  }

  .m-xl-17 {
    margin: 2.25rem !important
  }

  .mt-xl-17,
  .my-xl-17 {
    margin-top: 2.25rem !important
  }

  .mr-xl-17,
  .mx-xl-17 {
    margin-right: 2.25rem !important
  }

  .mb-xl-17,
  .my-xl-17 {
    margin-bottom: 2.25rem !important
  }

  .ml-xl-17,
  .mx-xl-17 {
    margin-left: 2.25rem !important
  }

  .m-xl-18 {
    margin: 2.375rem !important
  }

  .mt-xl-18,
  .my-xl-18 {
    margin-top: 2.375rem !important
  }

  .mr-xl-18,
  .mx-xl-18 {
    margin-right: 2.375rem !important
  }

  .mb-xl-18,
  .my-xl-18 {
    margin-bottom: 2.375rem !important
  }

  .ml-xl-18,
  .mx-xl-18 {
    margin-left: 2.375rem !important
  }

  .m-xl-19 {
    margin: 2.5rem !important
  }

  .mt-xl-19,
  .my-xl-19 {
    margin-top: 2.5rem !important
  }

  .mr-xl-19,
  .mx-xl-19 {
    margin-right: 2.5rem !important
  }

  .mb-xl-19,
  .my-xl-19 {
    margin-bottom: 2.5rem !important
  }

  .ml-xl-19,
  .mx-xl-19 {
    margin-left: 2.5rem !important
  }

  .m-xl-20 {
    margin: 3.125rem !important
  }

  .mt-xl-20,
  .my-xl-20 {
    margin-top: 3.125rem !important
  }

  .mr-xl-20,
  .mx-xl-20 {
    margin-right: 3.125rem !important
  }

  .mb-xl-20,
  .my-xl-20 {
    margin-bottom: 3.125rem !important
  }

  .ml-xl-20,
  .mx-xl-20 {
    margin-left: 3.125rem !important
  }

  .m-xl-21 {
    margin: 3.75rem !important
  }

  .mt-xl-21,
  .my-xl-21 {
    margin-top: 3.75rem !important
  }

  .mr-xl-21,
  .mx-xl-21 {
    margin-right: 3.75rem !important
  }

  .mb-xl-21,
  .my-xl-21 {
    margin-bottom: 3.75rem !important
  }

  .ml-xl-21,
  .mx-xl-21 {
    margin-left: 3.75rem !important
  }

  .m-xl-22 {
    margin: 4.375rem !important
  }

  .mt-xl-22,
  .my-xl-22 {
    margin-top: 4.375rem !important
  }

  .mr-xl-22,
  .mx-xl-22 {
    margin-right: 4.375rem !important
  }

  .mb-xl-22,
  .my-xl-22 {
    margin-bottom: 4.375rem !important
  }

  .ml-xl-22,
  .mx-xl-22 {
    margin-left: 4.375rem !important
  }

  .m-xl-23 {
    margin: 5rem !important
  }

  .mt-xl-23,
  .my-xl-23 {
    margin-top: 5rem !important
  }

  .mr-xl-23,
  .mx-xl-23 {
    margin-right: 5rem !important
  }

  .mb-xl-23,
  .my-xl-23 {
    margin-bottom: 5rem !important
  }

  .ml-xl-23,
  .mx-xl-23 {
    margin-left: 5rem !important
  }

  .m-xl-24 {
    margin: 5.625rem !important
  }

  .mt-xl-24,
  .my-xl-24 {
    margin-top: 5.625rem !important
  }

  .mr-xl-24,
  .mx-xl-24 {
    margin-right: 5.625rem !important
  }

  .mb-xl-24,
  .my-xl-24 {
    margin-bottom: 5.625rem !important
  }

  .ml-xl-24,
  .mx-xl-24 {
    margin-left: 5.625rem !important
  }

  .m-xl-25 {
    margin: 6.25rem !important
  }

  .mt-xl-25,
  .my-xl-25 {
    margin-top: 6.25rem !important
  }

  .mr-xl-25,
  .mx-xl-25 {
    margin-right: 6.25rem !important
  }

  .mb-xl-25,
  .my-xl-25 {
    margin-bottom: 6.25rem !important
  }

  .ml-xl-25,
  .mx-xl-25 {
    margin-left: 6.25rem !important
  }

  .p-xl-0 {
    padding: 0 !important
  }

  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important
  }

  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important
  }

  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important
  }

  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important
  }

  .p-xl-1 {
    padding: .25rem !important
  }

  .pt-xl-1,
  .py-xl-1 {
    padding-top: .25rem !important
  }

  .pr-xl-1,
  .px-xl-1 {
    padding-right: .25rem !important
  }

  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: .25rem !important
  }

  .pl-xl-1,
  .px-xl-1 {
    padding-left: .25rem !important
  }

  .p-xl-2 {
    padding: .375rem !important
  }

  .pt-xl-2,
  .py-xl-2 {
    padding-top: .375rem !important
  }

  .pr-xl-2,
  .px-xl-2 {
    padding-right: .375rem !important
  }

  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: .375rem !important
  }

  .pl-xl-2,
  .px-xl-2 {
    padding-left: .375rem !important
  }

  .p-xl-3 {
    padding: .5rem !important
  }

  .pt-xl-3,
  .py-xl-3 {
    padding-top: .5rem !important
  }

  .pr-xl-3,
  .px-xl-3 {
    padding-right: .5rem !important
  }

  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: .5rem !important
  }

  .pl-xl-3,
  .px-xl-3 {
    padding-left: .5rem !important
  }

  .p-xl-4 {
    padding: .625rem !important
  }

  .pt-xl-4,
  .py-xl-4 {
    padding-top: .625rem !important
  }

  .pr-xl-4,
  .px-xl-4 {
    padding-right: .625rem !important
  }

  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: .625rem !important
  }

  .pl-xl-4,
  .px-xl-4 {
    padding-left: .625rem !important
  }

  .p-xl-5 {
    padding: .75rem !important
  }

  .pt-xl-5,
  .py-xl-5 {
    padding-top: .75rem !important
  }

  .pr-xl-5,
  .px-xl-5 {
    padding-right: .75rem !important
  }

  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: .75rem !important
  }

  .pl-xl-5,
  .px-xl-5 {
    padding-left: .75rem !important
  }

  .p-xl-6 {
    padding: .875rem !important
  }

  .pt-xl-6,
  .py-xl-6 {
    padding-top: .875rem !important
  }

  .pr-xl-6,
  .px-xl-6 {
    padding-right: .875rem !important
  }

  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: .875rem !important
  }

  .pl-xl-6,
  .px-xl-6 {
    padding-left: .875rem !important
  }

  .p-xl-7 {
    padding: 1rem !important
  }

  .pt-xl-7,
  .py-xl-7 {
    padding-top: 1rem !important
  }

  .pr-xl-7,
  .px-xl-7 {
    padding-right: 1rem !important
  }

  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 1rem !important
  }

  .pl-xl-7,
  .px-xl-7 {
    padding-left: 1rem !important
  }

  .p-xl-8 {
    padding: 1.125rem !important
  }

  .pt-xl-8,
  .py-xl-8 {
    padding-top: 1.125rem !important
  }

  .pr-xl-8,
  .px-xl-8 {
    padding-right: 1.125rem !important
  }

  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 1.125rem !important
  }

  .pl-xl-8,
  .px-xl-8 {
    padding-left: 1.125rem !important
  }

  .p-xl-9 {
    padding: 1.25rem !important
  }

  .pt-xl-9,
  .py-xl-9 {
    padding-top: 1.25rem !important
  }

  .pr-xl-9,
  .px-xl-9 {
    padding-right: 1.25rem !important
  }

  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 1.25rem !important
  }

  .pl-xl-9,
  .px-xl-9 {
    padding-left: 1.25rem !important
  }

  .p-xl-10 {
    padding: 1.375rem !important
  }

  .pt-xl-10,
  .py-xl-10 {
    padding-top: 1.375rem !important
  }

  .pr-xl-10,
  .px-xl-10 {
    padding-right: 1.375rem !important
  }

  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 1.375rem !important
  }

  .pl-xl-10,
  .px-xl-10 {
    padding-left: 1.375rem !important
  }

  .p-xl-11 {
    padding: 1.5rem !important
  }

  .pt-xl-11,
  .py-xl-11 {
    padding-top: 1.5rem !important
  }

  .pr-xl-11,
  .px-xl-11 {
    padding-right: 1.5rem !important
  }

  .pb-xl-11,
  .py-xl-11 {
    padding-bottom: 1.5rem !important
  }

  .pl-xl-11,
  .px-xl-11 {
    padding-left: 1.5rem !important
  }

  .p-xl-12 {
    padding: 1.625rem !important
  }

  .pt-xl-12,
  .py-xl-12 {
    padding-top: 1.625rem !important
  }

  .pr-xl-12,
  .px-xl-12 {
    padding-right: 1.625rem !important
  }

  .pb-xl-12,
  .py-xl-12 {
    padding-bottom: 1.625rem !important
  }

  .pl-xl-12,
  .px-xl-12 {
    padding-left: 1.625rem !important
  }

  .p-xl-13 {
    padding: 1.75rem !important
  }

  .pt-xl-13,
  .py-xl-13 {
    padding-top: 1.75rem !important
  }

  .pr-xl-13,
  .px-xl-13 {
    padding-right: 1.75rem !important
  }

  .pb-xl-13,
  .py-xl-13 {
    padding-bottom: 1.75rem !important
  }

  .pl-xl-13,
  .px-xl-13 {
    padding-left: 1.75rem !important
  }

  .p-xl-14 {
    padding: 1.875rem !important
  }

  .pt-xl-14,
  .py-xl-14 {
    padding-top: 1.875rem !important
  }

  .pr-xl-14,
  .px-xl-14 {
    padding-right: 1.875rem !important
  }

  .pb-xl-14,
  .py-xl-14 {
    padding-bottom: 1.875rem !important
  }

  .pl-xl-14,
  .px-xl-14 {
    padding-left: 1.875rem !important
  }

  .p-xl-15 {
    padding: 2rem !important
  }

  .pt-xl-15,
  .py-xl-15 {
    padding-top: 2rem !important
  }

  .pr-xl-15,
  .px-xl-15 {
    padding-right: 2rem !important
  }

  .pb-xl-15,
  .py-xl-15 {
    padding-bottom: 2rem !important
  }

  .pl-xl-15,
  .px-xl-15 {
    padding-left: 2rem !important
  }

  .p-xl-16 {
    padding: 2.125rem !important
  }

  .pt-xl-16,
  .py-xl-16 {
    padding-top: 2.125rem !important
  }

  .pr-xl-16,
  .px-xl-16 {
    padding-right: 2.125rem !important
  }

  .pb-xl-16,
  .py-xl-16 {
    padding-bottom: 2.125rem !important
  }

  .pl-xl-16,
  .px-xl-16 {
    padding-left: 2.125rem !important
  }

  .p-xl-17 {
    padding: 2.25rem !important
  }

  .pt-xl-17,
  .py-xl-17 {
    padding-top: 2.25rem !important
  }

  .pr-xl-17,
  .px-xl-17 {
    padding-right: 2.25rem !important
  }

  .pb-xl-17,
  .py-xl-17 {
    padding-bottom: 2.25rem !important
  }

  .pl-xl-17,
  .px-xl-17 {
    padding-left: 2.25rem !important
  }

  .p-xl-18 {
    padding: 2.375rem !important
  }

  .pt-xl-18,
  .py-xl-18 {
    padding-top: 2.375rem !important
  }

  .pr-xl-18,
  .px-xl-18 {
    padding-right: 2.375rem !important
  }

  .pb-xl-18,
  .py-xl-18 {
    padding-bottom: 2.375rem !important
  }

  .pl-xl-18,
  .px-xl-18 {
    padding-left: 2.375rem !important
  }

  .p-xl-19 {
    padding: 2.5rem !important
  }

  .pt-xl-19,
  .py-xl-19 {
    padding-top: 2.5rem !important
  }

  .pr-xl-19,
  .px-xl-19 {
    padding-right: 2.5rem !important
  }

  .pb-xl-19,
  .py-xl-19 {
    padding-bottom: 2.5rem !important
  }

  .pl-xl-19,
  .px-xl-19 {
    padding-left: 2.5rem !important
  }

  .p-xl-20 {
    padding: 3.125rem !important
  }

  .pt-xl-20,
  .py-xl-20 {
    padding-top: 3.125rem !important
  }

  .pr-xl-20,
  .px-xl-20 {
    padding-right: 3.125rem !important
  }

  .pb-xl-20,
  .py-xl-20 {
    padding-bottom: 3.125rem !important
  }

  .pl-xl-20,
  .px-xl-20 {
    padding-left: 3.125rem !important
  }

  .p-xl-21 {
    padding: 3.75rem !important
  }

  .pt-xl-21,
  .py-xl-21 {
    padding-top: 3.75rem !important
  }

  .pr-xl-21,
  .px-xl-21 {
    padding-right: 3.75rem !important
  }

  .pb-xl-21,
  .py-xl-21 {
    padding-bottom: 3.75rem !important
  }

  .pl-xl-21,
  .px-xl-21 {
    padding-left: 3.75rem !important
  }

  .p-xl-22 {
    padding: 4.375rem !important
  }

  .pt-xl-22,
  .py-xl-22 {
    padding-top: 4.375rem !important
  }

  .pr-xl-22,
  .px-xl-22 {
    padding-right: 4.375rem !important
  }

  .pb-xl-22,
  .py-xl-22 {
    padding-bottom: 4.375rem !important
  }

  .pl-xl-22,
  .px-xl-22 {
    padding-left: 4.375rem !important
  }

  .p-xl-23 {
    padding: 5rem !important
  }

  .pt-xl-23,
  .py-xl-23 {
    padding-top: 5rem !important
  }

  .pr-xl-23,
  .px-xl-23 {
    padding-right: 5rem !important
  }

  .pb-xl-23,
  .py-xl-23 {
    padding-bottom: 5rem !important
  }

  .pl-xl-23,
  .px-xl-23 {
    padding-left: 5rem !important
  }

  .p-xl-24 {
    padding: 5.625rem !important
  }

  .pt-xl-24,
  .py-xl-24 {
    padding-top: 5.625rem !important
  }

  .pr-xl-24,
  .px-xl-24 {
    padding-right: 5.625rem !important
  }

  .pb-xl-24,
  .py-xl-24 {
    padding-bottom: 5.625rem !important
  }

  .pl-xl-24,
  .px-xl-24 {
    padding-left: 5.625rem !important
  }

  .p-xl-25 {
    padding: 6.25rem !important
  }

  .pt-xl-25,
  .py-xl-25 {
    padding-top: 6.25rem !important
  }

  .pr-xl-25,
  .px-xl-25 {
    padding-right: 6.25rem !important
  }

  .pb-xl-25,
  .py-xl-25 {
    padding-bottom: 6.25rem !important
  }

  .pl-xl-25,
  .px-xl-25 {
    padding-left: 6.25rem !important
  }

  .m-xl-n1 {
    margin: -.25rem !important
  }

  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -.25rem !important
  }

  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -.25rem !important
  }

  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -.25rem !important
  }

  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -.25rem !important
  }

  .m-xl-n2 {
    margin: -.375rem !important
  }

  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -.375rem !important
  }

  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -.375rem !important
  }

  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -.375rem !important
  }

  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -.375rem !important
  }

  .m-xl-n3 {
    margin: -.5rem !important
  }

  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -.5rem !important
  }

  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -.5rem !important
  }

  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -.5rem !important
  }

  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -.5rem !important
  }

  .m-xl-n4 {
    margin: -.625rem !important
  }

  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -.625rem !important
  }

  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -.625rem !important
  }

  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -.625rem !important
  }

  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -.625rem !important
  }

  .m-xl-n5 {
    margin: -.75rem !important
  }

  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -.75rem !important
  }

  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -.75rem !important
  }

  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -.75rem !important
  }

  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -.75rem !important
  }

  .m-xl-n6 {
    margin: -.875rem !important
  }

  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -.875rem !important
  }

  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -.875rem !important
  }

  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -.875rem !important
  }

  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -.875rem !important
  }

  .m-xl-n7 {
    margin: -1rem !important
  }

  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -1rem !important
  }

  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -1rem !important
  }

  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -1rem !important
  }

  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -1rem !important
  }

  .m-xl-n8 {
    margin: -1.125rem !important
  }

  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -1.125rem !important
  }

  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -1.125rem !important
  }

  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -1.125rem !important
  }

  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -1.125rem !important
  }

  .m-xl-n9 {
    margin: -1.25rem !important
  }

  .mt-xl-n9,
  .my-xl-n9 {
    margin-top: -1.25rem !important
  }

  .mr-xl-n9,
  .mx-xl-n9 {
    margin-right: -1.25rem !important
  }

  .mb-xl-n9,
  .my-xl-n9 {
    margin-bottom: -1.25rem !important
  }

  .ml-xl-n9,
  .mx-xl-n9 {
    margin-left: -1.25rem !important
  }

  .m-xl-n10 {
    margin: -1.375rem !important
  }

  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -1.375rem !important
  }

  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -1.375rem !important
  }

  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -1.375rem !important
  }

  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -1.375rem !important
  }

  .m-xl-n11 {
    margin: -1.5rem !important
  }

  .mt-xl-n11,
  .my-xl-n11 {
    margin-top: -1.5rem !important
  }

  .mr-xl-n11,
  .mx-xl-n11 {
    margin-right: -1.5rem !important
  }

  .mb-xl-n11,
  .my-xl-n11 {
    margin-bottom: -1.5rem !important
  }

  .ml-xl-n11,
  .mx-xl-n11 {
    margin-left: -1.5rem !important
  }

  .m-xl-n12 {
    margin: -1.625rem !important
  }

  .mt-xl-n12,
  .my-xl-n12 {
    margin-top: -1.625rem !important
  }

  .mr-xl-n12,
  .mx-xl-n12 {
    margin-right: -1.625rem !important
  }

  .mb-xl-n12,
  .my-xl-n12 {
    margin-bottom: -1.625rem !important
  }

  .ml-xl-n12,
  .mx-xl-n12 {
    margin-left: -1.625rem !important
  }

  .m-xl-n13 {
    margin: -1.75rem !important
  }

  .mt-xl-n13,
  .my-xl-n13 {
    margin-top: -1.75rem !important
  }

  .mr-xl-n13,
  .mx-xl-n13 {
    margin-right: -1.75rem !important
  }

  .mb-xl-n13,
  .my-xl-n13 {
    margin-bottom: -1.75rem !important
  }

  .ml-xl-n13,
  .mx-xl-n13 {
    margin-left: -1.75rem !important
  }

  .m-xl-n14 {
    margin: -1.875rem !important
  }

  .mt-xl-n14,
  .my-xl-n14 {
    margin-top: -1.875rem !important
  }

  .mr-xl-n14,
  .mx-xl-n14 {
    margin-right: -1.875rem !important
  }

  .mb-xl-n14,
  .my-xl-n14 {
    margin-bottom: -1.875rem !important
  }

  .ml-xl-n14,
  .mx-xl-n14 {
    margin-left: -1.875rem !important
  }

  .m-xl-n15 {
    margin: -2rem !important
  }

  .mt-xl-n15,
  .my-xl-n15 {
    margin-top: -2rem !important
  }

  .mr-xl-n15,
  .mx-xl-n15 {
    margin-right: -2rem !important
  }

  .mb-xl-n15,
  .my-xl-n15 {
    margin-bottom: -2rem !important
  }

  .ml-xl-n15,
  .mx-xl-n15 {
    margin-left: -2rem !important
  }

  .m-xl-n16 {
    margin: -2.125rem !important
  }

  .mt-xl-n16,
  .my-xl-n16 {
    margin-top: -2.125rem !important
  }

  .mr-xl-n16,
  .mx-xl-n16 {
    margin-right: -2.125rem !important
  }

  .mb-xl-n16,
  .my-xl-n16 {
    margin-bottom: -2.125rem !important
  }

  .ml-xl-n16,
  .mx-xl-n16 {
    margin-left: -2.125rem !important
  }

  .m-xl-n17 {
    margin: -2.25rem !important
  }

  .mt-xl-n17,
  .my-xl-n17 {
    margin-top: -2.25rem !important
  }

  .mr-xl-n17,
  .mx-xl-n17 {
    margin-right: -2.25rem !important
  }

  .mb-xl-n17,
  .my-xl-n17 {
    margin-bottom: -2.25rem !important
  }

  .ml-xl-n17,
  .mx-xl-n17 {
    margin-left: -2.25rem !important
  }

  .m-xl-n18 {
    margin: -2.375rem !important
  }

  .mt-xl-n18,
  .my-xl-n18 {
    margin-top: -2.375rem !important
  }

  .mr-xl-n18,
  .mx-xl-n18 {
    margin-right: -2.375rem !important
  }

  .mb-xl-n18,
  .my-xl-n18 {
    margin-bottom: -2.375rem !important
  }

  .ml-xl-n18,
  .mx-xl-n18 {
    margin-left: -2.375rem !important
  }

  .m-xl-n19 {
    margin: -2.5rem !important
  }

  .mt-xl-n19,
  .my-xl-n19 {
    margin-top: -2.5rem !important
  }

  .mr-xl-n19,
  .mx-xl-n19 {
    margin-right: -2.5rem !important
  }

  .mb-xl-n19,
  .my-xl-n19 {
    margin-bottom: -2.5rem !important
  }

  .ml-xl-n19,
  .mx-xl-n19 {
    margin-left: -2.5rem !important
  }

  .m-xl-n20 {
    margin: -3.125rem !important
  }

  .mt-xl-n20,
  .my-xl-n20 {
    margin-top: -3.125rem !important
  }

  .mr-xl-n20,
  .mx-xl-n20 {
    margin-right: -3.125rem !important
  }

  .mb-xl-n20,
  .my-xl-n20 {
    margin-bottom: -3.125rem !important
  }

  .ml-xl-n20,
  .mx-xl-n20 {
    margin-left: -3.125rem !important
  }

  .m-xl-n21 {
    margin: -3.75rem !important
  }

  .mt-xl-n21,
  .my-xl-n21 {
    margin-top: -3.75rem !important
  }

  .mr-xl-n21,
  .mx-xl-n21 {
    margin-right: -3.75rem !important
  }

  .mb-xl-n21,
  .my-xl-n21 {
    margin-bottom: -3.75rem !important
  }

  .ml-xl-n21,
  .mx-xl-n21 {
    margin-left: -3.75rem !important
  }

  .m-xl-n22 {
    margin: -4.375rem !important
  }

  .mt-xl-n22,
  .my-xl-n22 {
    margin-top: -4.375rem !important
  }

  .mr-xl-n22,
  .mx-xl-n22 {
    margin-right: -4.375rem !important
  }

  .mb-xl-n22,
  .my-xl-n22 {
    margin-bottom: -4.375rem !important
  }

  .ml-xl-n22,
  .mx-xl-n22 {
    margin-left: -4.375rem !important
  }

  .m-xl-n23 {
    margin: -5rem !important
  }

  .mt-xl-n23,
  .my-xl-n23 {
    margin-top: -5rem !important
  }

  .mr-xl-n23,
  .mx-xl-n23 {
    margin-right: -5rem !important
  }

  .mb-xl-n23,
  .my-xl-n23 {
    margin-bottom: -5rem !important
  }

  .ml-xl-n23,
  .mx-xl-n23 {
    margin-left: -5rem !important
  }

  .m-xl-n24 {
    margin: -5.625rem !important
  }

  .mt-xl-n24,
  .my-xl-n24 {
    margin-top: -5.625rem !important
  }

  .mr-xl-n24,
  .mx-xl-n24 {
    margin-right: -5.625rem !important
  }

  .mb-xl-n24,
  .my-xl-n24 {
    margin-bottom: -5.625rem !important
  }

  .ml-xl-n24,
  .mx-xl-n24 {
    margin-left: -5.625rem !important
  }

  .m-xl-n25 {
    margin: -6.25rem !important
  }

  .mt-xl-n25,
  .my-xl-n25 {
    margin-top: -6.25rem !important
  }

  .mr-xl-n25,
  .mx-xl-n25 {
    margin-right: -6.25rem !important
  }

  .mb-xl-n25,
  .my-xl-n25 {
    margin-bottom: -6.25rem !important
  }

  .ml-xl-n25,
  .mx-xl-n25 {
    margin-left: -6.25rem !important
  }

  .m-xl-auto {
    margin: auto !important
  }

  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important
  }

  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important
  }

  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important
  }

  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important
  }
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0)
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important
}

.text-justify {
  text-align: justify !important
}

.text-wrap {
  white-space: normal !important
}

.text-nowrap {
  white-space: nowrap !important
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.text-left {
  text-align: left !important
}

.text-right {
  text-align: right !important
}

.text-center {
  text-align: center !important
}

@media (min-width:576px) {
  .text-sm-left {
    text-align: left !important
  }

  .text-sm-right {
    text-align: right !important
  }

  .text-sm-center {
    text-align: center !important
  }
}

@media (min-width:768px) {
  .text-md-left {
    text-align: left !important
  }

  .text-md-right {
    text-align: right !important
  }

  .text-md-center {
    text-align: center !important
  }
}

@media (min-width:992px) {
  .text-lg-left {
    text-align: left !important
  }

  .text-lg-right {
    text-align: right !important
  }

  .text-lg-center {
    text-align: center !important
  }
}

@media (min-width:1200px) {
  .text-xl-left {
    text-align: left !important
  }

  .text-xl-right {
    text-align: right !important
  }

  .text-xl-center {
    text-align: center !important
  }
}

.text-lowercase {
  text-transform: lowercase !important
}

.text-uppercase {
  text-transform: uppercase !important
}

.text-capitalize {
  text-transform: capitalize !important
}

.font-weight-light {
  font-weight: 300 !important
}

.font-weight-lighter {
  font-weight: lighter !important
}

.font-weight-normal {
  font-weight: 400 !important
}

.font-weight-bold {
  font-weight: 700 !important
}

.font-weight-bolder {
  font-weight: bolder !important
}

.font-italic {
  font-style: italic !important
}

.text-white {
  color: #fff !important
}

.text-primary {
  color: #3db36b !important
}

.text-secondary {
  color: #f9f9f9 !important
}

.text-success {
  color: #9DC41E !important
}

.text-info {
  color: #4184f3 !important
}

.text-warning {
  color: #fdb813 !important
}

.text-danger {
  color: #dc3545 !important
}

.text-light {
  color: #efefef !important
}

.text-dark {
  color: #343a40 !important
}

.text-body {
  color: #212529 !important
}

.text-muted {
  color: #9b9b9b !important
}

.text-black-50 {
  color: rgba(0, 0, 0, .5) !important
}

.text-white-50 {
  color: rgba(255, 255, 255, .5) !important
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0
}

.text-decoration-none {
  text-decoration: none !important
}

.text-break {
  word-wrap: break-word !important
}

.text-reset {
  color: inherit !important
}

.visible {
  visibility: visible !important
}

.invisible {
  visibility: hidden !important
}

@media print {

  *,
  ::after,
  ::before {
    text-shadow: none !important;
    box-shadow: none !important
  }

  a:not(.btn) {
    text-decoration: underline
  }

  abbr[title]::after {
    content: " (" attr(title) ")"
  }

  pre {
    white-space: pre-wrap !important
  }

  blockquote,
  pre {
    border: 1px solid #adb5bd;
    page-break-inside: avoid
  }

  thead {
    display: table-header-group
  }

  img,
  tr {
    page-break-inside: avoid
  }

  h2,
  h3,
  p {
    orphans: 3;
    widows: 3
  }

  h2,
  h3 {
    page-break-after: avoid
  }

  @page {
    size: a3
  }

  body {
    min-width: 992px !important
  }

  .container {
    min-width: 992px !important
  }

  .navbar {
    display: none
  }

  .badge {
    border: 1px solid #000
  }

  .table {
    border-collapse: collapse !important
  }

  .table td,
  .table th {
    background-color: #fff !important
  }

  .table-bordered td,
  .table-bordered th {
    border: 1px solid #dee2e6 !important
  }

  .table-dark {
    color: inherit
  }

  .table-dark tbody+tbody,
  .table-dark td,
  .table-dark th,
  .table-dark thead th {
    border-color: #dee2e6
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6
  }
}

.row[class*=vertical-divider-] {
  overflow-x: hidden;
  position: relative
}

.row[class*=vertical-divider-]>[class*=col]:nth-child(n+2):after {
  content: '';
  background-color: var(--vertical-divider-color);
  position: absolute;
  top: 0;
  bottom: 0
}

@media (max-width:720px) {
  .row[class*=vertical-divider-]>[class*=col]:nth-child(n+2):after {
    width: 100%;
    height: .0625rem;
    left: 0;
    top: -.25rem
  }
}

@media (min-width:768px) {
  .row[class*=vertical-divider-]>[class*=col]:nth-child(n+2):after {
    width: .0625rem;
    height: auto;
    left: -.0625rem
  }
}

.row.vertical-divider-blue {
  --vertical-divider-color: #007bff
}

.row.vertical-divider-indigo {
  --vertical-divider-color: #6610f2
}

.row.vertical-divider-purple {
  --vertical-divider-color: #6f42c1
}

.row.vertical-divider-pink {
  --vertical-divider-color: #e83e8c
}

.row.vertical-divider-red {
  --vertical-divider-color: #dc3545
}

.row.vertical-divider-orange {
  --vertical-divider-color: #fd7e14
}

.row.vertical-divider-yellow {
  --vertical-divider-color: #FDB813
}

.row.vertical-divider-green {
  --vertical-divider-color: #9DC41E
}

.row.vertical-divider-teal {
  --vertical-divider-color: #20c997
}

.row.vertical-divider-cyan {
  --vertical-divider-color: #17a2b8
}

.row.vertical-divider-white {
  --vertical-divider-color: #fff
}

.row.vertical-divider-gray {
  --vertical-divider-color: #6c757d
}

.row.vertical-divider-gray-dark {
  --vertical-divider-color: #343a40
}

.row.vertical-divider-black {
  --vertical-divider-color: #000
}

.row.vertical-divider-primary {
  --vertical-divider-color: #3DB36B
}

.row.vertical-divider-secondary {
  --vertical-divider-color: #f9f9f9
}

.row.vertical-divider-success {
  --vertical-divider-color: #9DC41E
}

.row.vertical-divider-info {
  --vertical-divider-color: #4184F3
}

.row.vertical-divider-warning {
  --vertical-divider-color: #FDB813
}

.row.vertical-divider-danger {
  --vertical-divider-color: #dc3545
}

.row.vertical-divider-light {
  --vertical-divider-color: #EFEFEF
}

.row.vertical-divider-dark {
  --vertical-divider-color: #343a40
}

.btn {
  letter-spacing: .125rem;
  text-transform: uppercase
}

.btn-square {
  padding: 0;
  line-height: 3rem;
  height: 3.125rem;
  width: 3.125rem
}

.btn-group-lg>.btn-square.btn,
.btn-lg.btn-square {
  padding: 0;
  line-height: 3.625rem;
  height: 3.75rem;
  width: 3.75rem
}

.btn-group-sm>.btn-square.btn,
.btn-sm.btn-square {
  padding: 0;
  line-height: 2.375rem;
  height: 2.5rem;
  width: 2.5rem
}

.btn-white-primary {
  background: #fff;
  color: #3db36b;
  border-color: #fff
}

.btn-white-primary.disabled,
.btn-white-primary:disabled {
  background: #fff;
  opacity: 1;
  color: rgba(61, 179, 107, .2)
}

.btn-white-primary:not(:disabled):not(.disabled):active,
.btn-white-primary:not(:disabled):not(.disabled):focus,
.btn-white-primary:not(:disabled):not(.disabled):hover {
  background: 0 0;
  border-color: #fff;
  color: #fff
}

.btn-light {
  background: 0 0;
  border-color: #efefef;
  color: #000
}

.btn-light.disabled,
.btn-light:disabled {
  background: 0 0;
  opacity: 1;
  color: rgba(0, 0, 0, .2)
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled):focus,
.btn-light:not(:disabled):not(.disabled):hover {
  background: 0 0;
  border-color: #c9c9c9
}

.btn-ghost {
  border-color: #fff;
  color: #fff
}

.btn-ghost.disabled,
.btn-ghost:disabled {
  opacity: 1;
  color: rgba(255, 255, 255, .2);
  border-color: rgba(255, 255, 255, .25)
}

.btn-ghost:not(:disabled):not(.disabled):active,
.btn-ghost:not(:disabled):not(.disabled):focus,
.btn-ghost:not(:disabled):not(.disabled):hover {
  color: rgba(255, 255, 255, .65);
  border-color: rgba(255, 255, 255, .65)
}

.btn-link {
  position: relative;
  font-size: .8125rem;
  font-weight: 700;
  line-height: 1;
  letter-spacing: .125rem;
  padding: 0 0 .5rem
}

.btn-link,
.btn-link:active,
.btn-link:focus,
.btn-link:hover {
  background: 0 0;
  border: 0;
  text-decoration: none
}

.btn-link:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: currentColor
}

.bg-blue {
  background-color: #007bff !important
}

.bg-blue-10 {
  background-color: rgba(0, 123, 255, .1) !important
}

.bg-blue-20 {
  background-color: rgba(0, 123, 255, .2) !important
}

.bg-blue-30 {
  background-color: rgba(0, 123, 255, .3) !important
}

.bg-blue-40 {
  background-color: rgba(0, 123, 255, .4) !important
}

.bg-blue-50 {
  background-color: rgba(0, 123, 255, .5) !important
}

.bg-blue-60 {
  background-color: rgba(0, 123, 255, .6) !important
}

.bg-blue-70 {
  background-color: rgba(0, 123, 255, .7) !important
}

.bg-blue-80 {
  background-color: rgba(0, 123, 255, .8) !important
}

.bg-blue-90 {
  background-color: rgba(0, 123, 255, .9) !important
}

.bg-indigo {
  background-color: #6610f2 !important
}

.bg-indigo-10 {
  background-color: rgba(102, 16, 242, .1) !important
}

.bg-indigo-20 {
  background-color: rgba(102, 16, 242, .2) !important
}

.bg-indigo-30 {
  background-color: rgba(102, 16, 242, .3) !important
}

.bg-indigo-40 {
  background-color: rgba(102, 16, 242, .4) !important
}

.bg-indigo-50 {
  background-color: rgba(102, 16, 242, .5) !important
}

.bg-indigo-60 {
  background-color: rgba(102, 16, 242, .6) !important
}

.bg-indigo-70 {
  background-color: rgba(102, 16, 242, .7) !important
}

.bg-indigo-80 {
  background-color: rgba(102, 16, 242, .8) !important
}

.bg-indigo-90 {
  background-color: rgba(102, 16, 242, .9) !important
}

.bg-purple {
  background-color: #6f42c1 !important
}

.bg-purple-10 {
  background-color: rgba(111, 66, 193, .1) !important
}

.bg-purple-20 {
  background-color: rgba(111, 66, 193, .2) !important
}

.bg-purple-30 {
  background-color: rgba(111, 66, 193, .3) !important
}

.bg-purple-40 {
  background-color: rgba(111, 66, 193, .4) !important
}

.bg-purple-50 {
  background-color: rgba(111, 66, 193, .5) !important
}

.bg-purple-60 {
  background-color: rgba(111, 66, 193, .6) !important
}

.bg-purple-70 {
  background-color: rgba(111, 66, 193, .7) !important
}

.bg-purple-80 {
  background-color: rgba(111, 66, 193, .8) !important
}

.bg-purple-90 {
  background-color: rgba(111, 66, 193, .9) !important
}

.bg-pink {
  background-color: #e83e8c !important
}

.bg-pink-10 {
  background-color: rgba(232, 62, 140, .1) !important
}

.bg-pink-20 {
  background-color: rgba(232, 62, 140, .2) !important
}

.bg-pink-30 {
  background-color: rgba(232, 62, 140, .3) !important
}

.bg-pink-40 {
  background-color: rgba(232, 62, 140, .4) !important
}

.bg-pink-50 {
  background-color: rgba(232, 62, 140, .5) !important
}

.bg-pink-60 {
  background-color: rgba(232, 62, 140, .6) !important
}

.bg-pink-70 {
  background-color: rgba(232, 62, 140, .7) !important
}

.bg-pink-80 {
  background-color: rgba(232, 62, 140, .8) !important
}

.bg-pink-90 {
  background-color: rgba(232, 62, 140, .9) !important
}

.bg-red {
  background-color: #dc3545 !important
}

.bg-red-10 {
  background-color: rgba(220, 53, 69, .1) !important
}

.bg-red-20 {
  background-color: rgba(220, 53, 69, .2) !important
}

.bg-red-30 {
  background-color: rgba(220, 53, 69, .3) !important
}

.bg-red-40 {
  background-color: rgba(220, 53, 69, .4) !important
}

.bg-red-50 {
  background-color: rgba(220, 53, 69, .5) !important
}

.bg-red-60 {
  background-color: rgba(220, 53, 69, .6) !important
}

.bg-red-70 {
  background-color: rgba(220, 53, 69, .7) !important
}

.bg-red-80 {
  background-color: rgba(220, 53, 69, .8) !important
}

.bg-red-90 {
  background-color: rgba(220, 53, 69, .9) !important
}

.bg-orange {
  background-color: #fd7e14 !important
}

.bg-orange-10 {
  background-color: rgba(253, 126, 20, .1) !important
}

.bg-orange-20 {
  background-color: rgba(253, 126, 20, .2) !important
}

.bg-orange-30 {
  background-color: rgba(253, 126, 20, .3) !important
}

.bg-orange-40 {
  background-color: rgba(253, 126, 20, .4) !important
}

.bg-orange-50 {
  background-color: rgba(253, 126, 20, .5) !important
}

.bg-orange-60 {
  background-color: rgba(253, 126, 20, .6) !important
}

.bg-orange-70 {
  background-color: rgba(253, 126, 20, .7) !important
}

.bg-orange-80 {
  background-color: rgba(253, 126, 20, .8) !important
}

.bg-orange-90 {
  background-color: rgba(253, 126, 20, .9) !important
}

.bg-yellow {
  background-color: #fdb813 !important
}

.bg-yellow-10 {
  background-color: rgba(253, 184, 19, .1) !important
}

.bg-yellow-20 {
  background-color: rgba(253, 184, 19, .2) !important
}

.bg-yellow-30 {
  background-color: rgba(253, 184, 19, .3) !important
}

.bg-yellow-40 {
  background-color: rgba(253, 184, 19, .4) !important
}

.bg-yellow-50 {
  background-color: rgba(253, 184, 19, .5) !important
}

.bg-yellow-60 {
  background-color: rgba(253, 184, 19, .6) !important
}

.bg-yellow-70 {
  background-color: rgba(253, 184, 19, .7) !important
}

.bg-yellow-80 {
  background-color: rgba(253, 184, 19, .8) !important
}

.bg-yellow-90 {
  background-color: rgba(253, 184, 19, .9) !important
}

.bg-green {
  background-color: #9DC41E !important
}

.bg-green-10 {
  background-color: rgba(40, 167, 69, .1) !important
}

.bg-green-20 {
  background-color: rgba(40, 167, 69, .2) !important
}

.bg-green-30 {
  background-color: rgba(40, 167, 69, .3) !important
}

.bg-green-40 {
  background-color: rgba(40, 167, 69, .4) !important
}

.bg-green-50 {
  background-color: rgba(40, 167, 69, .5) !important
}

.bg-green-60 {
  background-color: rgba(40, 167, 69, .6) !important
}

.bg-green-70 {
  background-color: rgba(40, 167, 69, .7) !important
}

.bg-green-80 {
  background-color: rgba(40, 167, 69, .8) !important
}

.bg-green-90 {
  background-color: rgba(40, 167, 69, .9) !important
}

.bg-teal {
  background-color: #20c997 !important
}

.bg-teal-10 {
  background-color: rgba(32, 201, 151, .1) !important
}

.bg-teal-20 {
  background-color: rgba(32, 201, 151, .2) !important
}

.bg-teal-30 {
  background-color: rgba(32, 201, 151, .3) !important
}

.bg-teal-40 {
  background-color: rgba(32, 201, 151, .4) !important
}

.bg-teal-50 {
  background-color: rgba(32, 201, 151, .5) !important
}

.bg-teal-60 {
  background-color: rgba(32, 201, 151, .6) !important
}

.bg-teal-70 {
  background-color: rgba(32, 201, 151, .7) !important
}

.bg-teal-80 {
  background-color: rgba(32, 201, 151, .8) !important
}

.bg-teal-90 {
  background-color: rgba(32, 201, 151, .9) !important
}

.bg-cyan {
  background-color: #17a2b8 !important
}

.bg-cyan-10 {
  background-color: rgba(23, 162, 184, .1) !important
}

.bg-cyan-20 {
  background-color: rgba(23, 162, 184, .2) !important
}

.bg-cyan-30 {
  background-color: rgba(23, 162, 184, .3) !important
}

.bg-cyan-40 {
  background-color: rgba(23, 162, 184, .4) !important
}

.bg-cyan-50 {
  background-color: rgba(23, 162, 184, .5) !important
}

.bg-cyan-60 {
  background-color: rgba(23, 162, 184, .6) !important
}

.bg-cyan-70 {
  background-color: rgba(23, 162, 184, .7) !important
}

.bg-cyan-80 {
  background-color: rgba(23, 162, 184, .8) !important
}

.bg-cyan-90 {
  background-color: rgba(23, 162, 184, .9) !important
}

.bg-white {
  background-color: #fff !important
}

.bg-white-10 {
  background-color: rgba(255, 255, 255, .1) !important
}

.bg-white-20 {
  background-color: rgba(255, 255, 255, .2) !important
}

.bg-white-30 {
  background-color: rgba(255, 255, 255, .3) !important
}

.bg-white-40 {
  background-color: rgba(255, 255, 255, .4) !important
}

.bg-white-50 {
  background-color: rgba(255, 255, 255, .5) !important
}

.bg-white-60 {
  background-color: rgba(255, 255, 255, .6) !important
}

.bg-white-70 {
  background-color: rgba(255, 255, 255, .7) !important
}

.bg-white-80 {
  background-color: rgba(255, 255, 255, .8) !important
}

.bg-white-90 {
  background-color: rgba(255, 255, 255, .9) !important
}

.bg-gray {
  background-color: #6c757d !important
}

.bg-gray-10 {
  background-color: rgba(108, 117, 125, .1) !important
}

.bg-gray-20 {
  background-color: rgba(108, 117, 125, .2) !important
}

.bg-gray-30 {
  background-color: rgba(108, 117, 125, .3) !important
}

.bg-gray-40 {
  background-color: rgba(108, 117, 125, .4) !important
}

.bg-gray-50 {
  background-color: rgba(108, 117, 125, .5) !important
}

.bg-gray-60 {
  background-color: rgba(108, 117, 125, .6) !important
}

.bg-gray-70 {
  background-color: rgba(108, 117, 125, .7) !important
}

.bg-gray-80 {
  background-color: rgba(108, 117, 125, .8) !important
}

.bg-gray-90 {
  background-color: rgba(108, 117, 125, .9) !important
}

.bg-gray-dark {
  background-color: #343a40 !important
}

.bg-gray-dark-10 {
  background-color: rgba(52, 58, 64, .1) !important
}

.bg-gray-dark-20 {
  background-color: rgba(52, 58, 64, .2) !important
}

.bg-gray-dark-30 {
  background-color: rgba(52, 58, 64, .3) !important
}

.bg-gray-dark-40 {
  background-color: rgba(52, 58, 64, .4) !important
}

.bg-gray-dark-50 {
  background-color: rgba(52, 58, 64, .5) !important
}

.bg-gray-dark-60 {
  background-color: rgba(52, 58, 64, .6) !important
}

.bg-gray-dark-70 {
  background-color: rgba(52, 58, 64, .7) !important
}

.bg-gray-dark-80 {
  background-color: rgba(52, 58, 64, .8) !important
}

.bg-gray-dark-90 {
  background-color: rgba(52, 58, 64, .9) !important
}

.bg-black {
  background-color: #000 !important
}

.bg-black-10 {
  background-color: rgba(0, 0, 0, .1) !important
}

.bg-black-20 {
  background-color: rgba(0, 0, 0, .2) !important
}

.bg-black-30 {
  background-color: rgba(0, 0, 0, .3) !important
}

.bg-black-40 {
  background-color: rgba(0, 0, 0, .4) !important
}

.bg-black-50 {
  background-color: rgba(0, 0, 0, .5) !important
}

.bg-black-60 {
  background-color: rgba(0, 0, 0, .6) !important
}

.bg-black-70 {
  background-color: rgba(0, 0, 0, .7) !important
}

.bg-black-80 {
  background-color: rgba(0, 0, 0, .8) !important
}

.bg-black-90 {
  background-color: rgba(0, 0, 0, .9) !important
}

.text-blue {
  color: #007bff !important
}

.text-blue-10 {
  color: rgba(0, 123, 255, .1) !important
}

.text-blue-20 {
  color: rgba(0, 123, 255, .2) !important
}

.text-blue-30 {
  color: rgba(0, 123, 255, .3) !important
}

.text-blue-40 {
  color: rgba(0, 123, 255, .4) !important
}

.text-blue-50 {
  color: rgba(0, 123, 255, .5) !important
}

.text-blue-60 {
  color: rgba(0, 123, 255, .6) !important
}

.text-blue-70 {
  color: rgba(0, 123, 255, .7) !important
}

.text-blue-80 {
  color: rgba(0, 123, 255, .8) !important
}

.text-blue-90 {
  color: rgba(0, 123, 255, .9) !important
}

.text-indigo {
  color: #6610f2 !important
}

.text-indigo-10 {
  color: rgba(102, 16, 242, .1) !important
}

.text-indigo-20 {
  color: rgba(102, 16, 242, .2) !important
}

.text-indigo-30 {
  color: rgba(102, 16, 242, .3) !important
}

.text-indigo-40 {
  color: rgba(102, 16, 242, .4) !important
}

.text-indigo-50 {
  color: rgba(102, 16, 242, .5) !important
}

.text-indigo-60 {
  color: rgba(102, 16, 242, .6) !important
}

.text-indigo-70 {
  color: rgba(102, 16, 242, .7) !important
}

.text-indigo-80 {
  color: rgba(102, 16, 242, .8) !important
}

.text-indigo-90 {
  color: rgba(102, 16, 242, .9) !important
}

.text-purple {
  color: #6f42c1 !important
}

.text-purple-10 {
  color: rgba(111, 66, 193, .1) !important
}

.text-purple-20 {
  color: rgba(111, 66, 193, .2) !important
}

.text-purple-30 {
  color: rgba(111, 66, 193, .3) !important
}

.text-purple-40 {
  color: rgba(111, 66, 193, .4) !important
}

.text-purple-50 {
  color: rgba(111, 66, 193, .5) !important
}

.text-purple-60 {
  color: rgba(111, 66, 193, .6) !important
}

.text-purple-70 {
  color: rgba(111, 66, 193, .7) !important
}

.text-purple-80 {
  color: rgba(111, 66, 193, .8) !important
}

.text-purple-90 {
  color: rgba(111, 66, 193, .9) !important
}

.text-pink {
  color: #e83e8c !important
}

.text-pink-10 {
  color: rgba(232, 62, 140, .1) !important
}

.text-pink-20 {
  color: rgba(232, 62, 140, .2) !important
}

.text-pink-30 {
  color: rgba(232, 62, 140, .3) !important
}

.text-pink-40 {
  color: rgba(232, 62, 140, .4) !important
}

.text-pink-50 {
  color: rgba(232, 62, 140, .5) !important
}

.text-pink-60 {
  color: rgba(232, 62, 140, .6) !important
}

.text-pink-70 {
  color: rgba(232, 62, 140, .7) !important
}

.text-pink-80 {
  color: rgba(232, 62, 140, .8) !important
}

.text-pink-90 {
  color: rgba(232, 62, 140, .9) !important
}

.text-red {
  color: #dc3545 !important
}

.text-red-10 {
  color: rgba(220, 53, 69, .1) !important
}

.text-red-20 {
  color: rgba(220, 53, 69, .2) !important
}

.text-red-30 {
  color: rgba(220, 53, 69, .3) !important
}

.text-red-40 {
  color: rgba(220, 53, 69, .4) !important
}

.text-red-50 {
  color: rgba(220, 53, 69, .5) !important
}

.text-red-60 {
  color: rgba(220, 53, 69, .6) !important
}

.text-red-70 {
  color: rgba(220, 53, 69, .7) !important
}

.text-red-80 {
  color: rgba(220, 53, 69, .8) !important
}

.text-red-90 {
  color: rgba(220, 53, 69, .9) !important
}

.text-orange {
  color: #fd7e14 !important
}

.text-orange-10 {
  color: rgba(253, 126, 20, .1) !important
}

.text-orange-20 {
  color: rgba(253, 126, 20, .2) !important
}

.text-orange-30 {
  color: rgba(253, 126, 20, .3) !important
}

.text-orange-40 {
  color: rgba(253, 126, 20, .4) !important
}

.text-orange-50 {
  color: rgba(253, 126, 20, .5) !important
}

.text-orange-60 {
  color: rgba(253, 126, 20, .6) !important
}

.text-orange-70 {
  color: rgba(253, 126, 20, .7) !important
}

.text-orange-80 {
  color: rgba(253, 126, 20, .8) !important
}

.text-orange-90 {
  color: rgba(253, 126, 20, .9) !important
}

.text-yellow {
  color: #fdb813 !important
}

.text-yellow-10 {
  color: rgba(253, 184, 19, .1) !important
}

.text-yellow-20 {
  color: rgba(253, 184, 19, .2) !important
}

.text-yellow-30 {
  color: rgba(253, 184, 19, .3) !important
}

.text-yellow-40 {
  color: rgba(253, 184, 19, .4) !important
}

.text-yellow-50 {
  color: rgba(253, 184, 19, .5) !important
}

.text-yellow-60 {
  color: rgba(253, 184, 19, .6) !important
}

.text-yellow-70 {
  color: rgba(253, 184, 19, .7) !important
}

.text-yellow-80 {
  color: rgba(253, 184, 19, .8) !important
}

.text-yellow-90 {
  color: rgba(253, 184, 19, .9) !important
}

.text-green {
  color: #9DC41E !important
}

.text-green-10 {
  color: rgba(40, 167, 69, .1) !important
}

.text-green-20 {
  color: rgba(40, 167, 69, .2) !important
}

.text-green-30 {
  color: rgba(40, 167, 69, .3) !important
}

.text-green-40 {
  color: rgba(40, 167, 69, .4) !important
}

.text-green-50 {
  color: rgba(40, 167, 69, .5) !important
}

.text-green-60 {
  color: rgba(40, 167, 69, .6) !important
}

.text-green-70 {
  color: rgba(40, 167, 69, .7) !important
}

.text-green-80 {
  color: rgba(40, 167, 69, .8) !important
}

.text-green-90 {
  color: rgba(40, 167, 69, .9) !important
}

.text-teal {
  color: #20c997 !important
}

.text-teal-10 {
  color: rgba(32, 201, 151, .1) !important
}

.text-teal-20 {
  color: rgba(32, 201, 151, .2) !important
}

.text-teal-30 {
  color: rgba(32, 201, 151, .3) !important
}

.text-teal-40 {
  color: rgba(32, 201, 151, .4) !important
}

.text-teal-50 {
  color: rgba(32, 201, 151, .5) !important
}

.text-teal-60 {
  color: rgba(32, 201, 151, .6) !important
}

.text-teal-70 {
  color: rgba(32, 201, 151, .7) !important
}

.text-teal-80 {
  color: rgba(32, 201, 151, .8) !important
}

.text-teal-90 {
  color: rgba(32, 201, 151, .9) !important
}

.text-cyan {
  color: #17a2b8 !important
}

.text-cyan-10 {
  color: rgba(23, 162, 184, .1) !important
}

.text-cyan-20 {
  color: rgba(23, 162, 184, .2) !important
}

.text-cyan-30 {
  color: rgba(23, 162, 184, .3) !important
}

.text-cyan-40 {
  color: rgba(23, 162, 184, .4) !important
}

.text-cyan-50 {
  color: rgba(23, 162, 184, .5) !important
}

.text-cyan-60 {
  color: rgba(23, 162, 184, .6) !important
}

.text-cyan-70 {
  color: rgba(23, 162, 184, .7) !important
}

.text-cyan-80 {
  color: rgba(23, 162, 184, .8) !important
}

.text-cyan-90 {
  color: rgba(23, 162, 184, .9) !important
}

.text-white {
  color: #fff !important
}

.text-white-10 {
  color: rgba(255, 255, 255, .1) !important
}

.text-white-20 {
  color: rgba(255, 255, 255, .2) !important
}

.text-white-30 {
  color: rgba(255, 255, 255, .3) !important
}

.text-white-40 {
  color: rgba(255, 255, 255, .4) !important
}

.text-white-50 {
  color: rgba(255, 255, 255, .5) !important
}

.text-white-60 {
  color: rgba(255, 255, 255, .6) !important
}

.text-white-70 {
  color: rgba(255, 255, 255, .7) !important
}

.text-white-80 {
  color: rgba(255, 255, 255, .8) !important
}

.text-white-90 {
  color: rgba(255, 255, 255, .9) !important
}

.text-gray {
  color: #6c757d !important
}

.text-gray-10 {
  color: rgba(108, 117, 125, .1) !important
}

.text-gray-20 {
  color: rgba(108, 117, 125, .2) !important
}

.text-gray-30 {
  color: rgba(108, 117, 125, .3) !important
}

.text-gray-40 {
  color: rgba(108, 117, 125, .4) !important
}

.text-gray-50 {
  color: rgba(108, 117, 125, .5) !important
}

.text-gray-60 {
  color: rgba(108, 117, 125, .6) !important
}

.text-gray-70 {
  color: rgba(108, 117, 125, .7) !important
}

.text-gray-80 {
  color: rgba(108, 117, 125, .8) !important
}

.text-gray-90 {
  color: rgba(108, 117, 125, .9) !important
}

.text-gray-dark {
  color: #343a40 !important
}

.text-gray-dark-10 {
  color: rgba(52, 58, 64, .1) !important
}

.text-gray-dark-20 {
  color: rgba(52, 58, 64, .2) !important
}

.text-gray-dark-30 {
  color: rgba(52, 58, 64, .3) !important
}

.text-gray-dark-40 {
  color: rgba(52, 58, 64, .4) !important
}

.text-gray-dark-50 {
  color: rgba(52, 58, 64, .5) !important
}

.text-gray-dark-60 {
  color: rgba(52, 58, 64, .6) !important
}

.text-gray-dark-70 {
  color: rgba(52, 58, 64, .7) !important
}

.text-gray-dark-80 {
  color: rgba(52, 58, 64, .8) !important
}

.text-gray-dark-90 {
  color: rgba(52, 58, 64, .9) !important
}

.text-black {
  color: #000 !important
}

.text-black-10 {
  color: rgba(0, 0, 0, .1) !important
}

.text-black-20 {
  color: rgba(0, 0, 0, .2) !important
}

.text-black-30 {
  color: rgba(0, 0, 0, .3) !important
}

.text-black-40 {
  color: rgba(0, 0, 0, .4) !important
}

.text-black-50 {
  color: rgba(0, 0, 0, .5) !important
}

.text-black-60 {
  color: rgba(0, 0, 0, .6) !important
}

.text-black-70 {
  color: rgba(0, 0, 0, .7) !important
}

.text-black-80 {
  color: rgba(0, 0, 0, .8) !important
}

.text-black-90 {
  color: rgba(0, 0, 0, .9) !important
}

.bg-gradient-black-bottom {
  background-image: linear-gradient(0deg, #000 0, rgba(0, 0, 0, 0) 100%)
}

.bg-gradient-dark-bottom {
  background-image: linear-gradient(0deg, #000 0, rgba(0, 0, 0, 0) 100%)
}

.bg-gradient-white-bottom {
  background-image: linear-gradient(0deg, #fff 0, rgba(255, 255, 255, 0) 100%)
}

.bg-gradient-primary-bottom {
  background-image: linear-gradient(0deg, #3db36b 0, rgba(61, 179, 107, 0) 100%)
}

.bg-gradient-black-left {
  background-image: linear-gradient(90deg, #000 0, rgba(0, 0, 0, 0) 100%)
}

.bg-gradient-dark-left {
  background-image: linear-gradient(90deg, #000 0, rgba(0, 0, 0, 0) 100%)
}

.bg-gradient-white-left {
  background-image: linear-gradient(90deg, #fff 0, rgba(255, 255, 255, 0) 100%)
}

.bg-gradient-primary-left {
  background-image: linear-gradient(90deg, #3db36b 0, rgba(61, 179, 107, 0) 100%)
}

.bg-gradient-black-right {
  background-image: linear-gradient(-90deg, #000 0, rgba(0, 0, 0, 0) 100%)
}

.bg-gradient-dark-right {
  background-image: linear-gradient(-90deg, #000 0, rgba(0, 0, 0, 0) 100%)
}

.bg-gradient-white-right {
  background-image: linear-gradient(-90deg, #fff 0, rgba(255, 255, 255, 0) 100%)
}

.bg-gradient-primary-right {
  background-image: linear-gradient(-90deg, #3db36b 0, rgba(61, 179, 107, 0) 100%)
}

.bg-gradient-black-top {
  background-image: linear-gradient(180deg, #000 0, rgba(0, 0, 0, 0) 100%)
}

.bg-gradient-dark-top {
  background-image: linear-gradient(180deg, #000 0, rgba(0, 0, 0, 0) 100%)
}

.bg-gradient-white-top {
  background-image: linear-gradient(180deg, #fff 0, rgba(255, 255, 255, 0) 100%)
}

.bg-gradient-primary-top {
  background-image: linear-gradient(180deg, #3db36b 0, rgba(61, 179, 107, 0) 100%)
}

.form-check-label {
  cursor: pointer
}

.form-label {
  font-family: Arimo, sans-serif;
  letter-spacing: .125rem;
  text-transform: uppercase;
  font-size: .75rem;
  color: #9b9b9b;
  margin-bottom: .25rem;
  line-height: 1.5;
  vertical-align: middle
}

p {
  color: #4a4a4a
}

b,
strong {
  font-weight: 700
}

.fs-1 {
  font-size: .75rem !important
}

.lh-1 {
  line-height: .75rem !important
}

.fs-2 {
  font-size: .8125rem !important
}

.lh-2 {
  line-height: .8125rem !important
}

.fs-3 {
  font-size: .875rem !important
}

.lh-3 {
  line-height: .875rem !important
}

.fs-4 {
  font-size: 1rem !important
}

.lh-4 {
  line-height: 1rem !important
}

.fs-5 {
  font-size: 1.125rem !important
}

.lh-5 {
  line-height: 1.125rem !important
}

.fs-6 {
  font-size: 1.5rem !important
}

.lh-6 {
  line-height: 1.5rem !important
}

.fs-7 {
  font-size: 1.875rem !important
}

.lh-7 {
  line-height: 1.875rem !important
}

.fs-8 {
  font-size: 2.25rem !important
}

.lh-8 {
  line-height: 2.25rem !important
}

.fs-9 {
  font-size: 2.5rem !important
}

.lh-9 {
  line-height: 2.5rem !important
}

.fs-10 {
  font-size: 3.125rem !important
}

.lh-10 {
  line-height: 3.125rem !important
}

.fs-11 {
  font-size: 4.375rem !important
}

.lh-11 {
  line-height: 4.375rem !important
}

.fs-12 {
  font-size: 5rem !important
}

.lh-12 {
  line-height: 5rem !important
}

.fs-13 {
  font-size: 6.25rem !important
}

.lh-13 {
  line-height: 6.25rem !important
}

@media (min-width:576px) {
  .fs-sm-1 {
    font-size: .75rem !important
  }

  .lh-sm-1 {
    line-height: .75rem !important
  }

  .fs-sm-2 {
    font-size: .8125rem !important
  }

  .lh-sm-2 {
    line-height: .8125rem !important
  }

  .fs-sm-3 {
    font-size: .875rem !important
  }

  .lh-sm-3 {
    line-height: .875rem !important
  }

  .fs-sm-4 {
    font-size: 1rem !important
  }

  .lh-sm-4 {
    line-height: 1rem !important
  }

  .fs-sm-5 {
    font-size: 1.125rem !important
  }

  .lh-sm-5 {
    line-height: 1.125rem !important
  }

  .fs-sm-6 {
    font-size: 1.5rem !important
  }

  .lh-sm-6 {
    line-height: 1.5rem !important
  }

  .fs-sm-7 {
    font-size: 1.875rem !important
  }

  .lh-sm-7 {
    line-height: 1.875rem !important
  }

  .fs-sm-8 {
    font-size: 2.25rem !important
  }

  .lh-sm-8 {
    line-height: 2.25rem !important
  }

  .fs-sm-9 {
    font-size: 2.5rem !important
  }

  .lh-sm-9 {
    line-height: 2.5rem !important
  }

  .fs-sm-10 {
    font-size: 3.125rem !important
  }

  .lh-sm-10 {
    line-height: 3.125rem !important
  }

  .fs-sm-11 {
    font-size: 4.375rem !important
  }

  .lh-sm-11 {
    line-height: 4.375rem !important
  }

  .fs-sm-12 {
    font-size: 5rem !important
  }

  .lh-sm-12 {
    line-height: 5rem !important
  }

  .fs-sm-13 {
    font-size: 6.25rem !important
  }

  .lh-sm-13 {
    line-height: 6.25rem !important
  }
}

@media (min-width:768px) {
  .fs-md-1 {
    font-size: .75rem !important
  }

  .lh-md-1 {
    line-height: .75rem !important
  }

  .fs-md-2 {
    font-size: .8125rem !important
  }

  .lh-md-2 {
    line-height: .8125rem !important
  }

  .fs-md-3 {
    font-size: .875rem !important
  }

  .lh-md-3 {
    line-height: .875rem !important
  }

  .fs-md-4 {
    font-size: 1rem !important
  }

  .lh-md-4 {
    line-height: 1rem !important
  }

  .fs-md-5 {
    font-size: 1.125rem !important
  }

  .lh-md-5 {
    line-height: 1.125rem !important
  }

  .fs-md-6 {
    font-size: 1.5rem !important
  }

  .lh-md-6 {
    line-height: 1.5rem !important
  }

  .fs-md-7 {
    font-size: 1.875rem !important
  }

  .lh-md-7 {
    line-height: 1.875rem !important
  }

  .fs-md-8 {
    font-size: 2.25rem !important
  }

  .lh-md-8 {
    line-height: 2.25rem !important
  }

  .fs-md-9 {
    font-size: 2.5rem !important
  }

  .lh-md-9 {
    line-height: 2.5rem !important
  }

  .fs-md-10 {
    font-size: 3.125rem !important
  }

  .lh-md-10 {
    line-height: 3.125rem !important
  }

  .fs-md-11 {
    font-size: 4.375rem !important
  }

  .lh-md-11 {
    line-height: 4.375rem !important
  }

  .fs-md-12 {
    font-size: 5rem !important
  }

  .lh-md-12 {
    line-height: 5rem !important
  }

  .fs-md-13 {
    font-size: 6.25rem !important
  }

  .lh-md-13 {
    line-height: 6.25rem !important
  }
}

@media (min-width:992px) {
  .fs-lg-1 {
    font-size: .75rem !important
  }

  .lh-lg-1 {
    line-height: .75rem !important
  }

  .fs-lg-2 {
    font-size: .8125rem !important
  }

  .lh-lg-2 {
    line-height: .8125rem !important
  }

  .fs-lg-3 {
    font-size: .875rem !important
  }

  .lh-lg-3 {
    line-height: .875rem !important
  }

  .fs-lg-4 {
    font-size: 1rem !important
  }

  .lh-lg-4 {
    line-height: 1rem !important
  }

  .fs-lg-5 {
    font-size: 1.125rem !important
  }

  .lh-lg-5 {
    line-height: 1.125rem !important
  }

  .fs-lg-6 {
    font-size: 1.5rem !important
  }

  .lh-lg-6 {
    line-height: 1.5rem !important
  }

  .fs-lg-7 {
    font-size: 1.875rem !important
  }

  .lh-lg-7 {
    line-height: 1.875rem !important
  }

  .fs-lg-8 {
    font-size: 2.25rem !important
  }

  .lh-lg-8 {
    line-height: 2.25rem !important
  }

  .fs-lg-9 {
    font-size: 2.5rem !important
  }

  .lh-lg-9 {
    line-height: 2.5rem !important
  }

  .fs-lg-10 {
    font-size: 3.125rem !important
  }

  .lh-lg-10 {
    line-height: 3.125rem !important
  }

  .fs-lg-11 {
    font-size: 4.375rem !important
  }

  .lh-lg-11 {
    line-height: 4.375rem !important
  }

  .fs-lg-12 {
    font-size: 5rem !important
  }

  .lh-lg-12 {
    line-height: 5rem !important
  }

  .fs-lg-13 {
    font-size: 6.25rem !important
  }

  .lh-lg-13 {
    line-height: 6.25rem !important
  }
}

@media (min-width:1200px) {
  .fs-xl-1 {
    font-size: .75rem !important
  }

  .lh-xl-1 {
    line-height: .75rem !important
  }

  .fs-xl-2 {
    font-size: .8125rem !important
  }

  .lh-xl-2 {
    line-height: .8125rem !important
  }

  .fs-xl-3 {
    font-size: .875rem !important
  }

  .lh-xl-3 {
    line-height: .875rem !important
  }

  .fs-xl-4 {
    font-size: 1rem !important
  }

  .lh-xl-4 {
    line-height: 1rem !important
  }

  .fs-xl-5 {
    font-size: 1.125rem !important
  }

  .lh-xl-5 {
    line-height: 1.125rem !important
  }

  .fs-xl-6 {
    font-size: 1.5rem !important
  }

  .lh-xl-6 {
    line-height: 1.5rem !important
  }

  .fs-xl-7 {
    font-size: 1.875rem !important
  }

  .lh-xl-7 {
    line-height: 1.875rem !important
  }

  .fs-xl-8 {
    font-size: 2.25rem !important
  }

  .lh-xl-8 {
    line-height: 2.25rem !important
  }

  .fs-xl-9 {
    font-size: 2.5rem !important
  }

  .lh-xl-9 {
    line-height: 2.5rem !important
  }

  .fs-xl-10 {
    font-size: 3.125rem !important
  }

  .lh-xl-10 {
    line-height: 3.125rem !important
  }

  .fs-xl-11 {
    font-size: 4.375rem !important
  }

  .lh-xl-11 {
    line-height: 4.375rem !important
  }

  .fs-xl-12 {
    font-size: 5rem !important
  }

  .lh-xl-12 {
    line-height: 5rem !important
  }

  .fs-xl-13 {
    font-size: 6.25rem !important
  }

  .lh-xl-13 {
    line-height: 6.25rem !important
  }
}

.letter-spacing-caption {
  letter-spacing: .125rem
}

.letter-spacing-normal {
  letter-spacing: normal
}

.font-weight-medium {
  font-weight: 500
}

.font-weight-semibold {
  font-weight: 600
}

.font-family-base {
  font-family: Roboto, sans-serif
}

.font-family-secondary {
  font-family: Arimo, sans-serif
}

.text-black {
  color: #000 !important
}

.text-white-75 {
  color: rgba(255, 255, 255, .75)
}

.h1,
.h2,
.h3,
.h4,
.h5,
h1,
h2,
h3,
h4,
h5 {
  margin-bottom: 1rem
}

.h1:last-child,
.h2:last-child,
.h3:last-child,
.h4:last-child,
.h5:last-child,
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child {
  margin-bottom: 0
}

.alert,
blockquote,
ol,
p,
ul {
  margin-bottom: 1rem
}

.alert:last-child,
blockquote:last-child,
ol:last-child,
p:last-child,
ul:last-child {
  margin-bottom: 0
}

body {
  min-height: 100vh;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

img {
  max-width: 100%
}

a:hover {
  text-decoration: none
}

.absolute-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1030
}

.position-absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1030
}

.position-top-0 {
  top: 0 !important
}

.position-left-0 {
  left: 0 !important
}

.position-right-0 {
  right: 0 !important
}

.position-bottom-0 {
  bottom: 0 !important
}

.position-top-1 {
  top: .25rem !important
}

.position-left-1 {
  left: .25rem !important
}

.position-right-1 {
  right: .25rem !important
}

.position-bottom-1 {
  bottom: .25rem !important
}

.position-top-2 {
  top: .375rem !important
}

.position-left-2 {
  left: .375rem !important
}

.position-right-2 {
  right: .375rem !important
}

.position-bottom-2 {
  bottom: .375rem !important
}

.position-top-3 {
  top: .5rem !important
}

.position-left-3 {
  left: .5rem !important
}

.position-right-3 {
  right: .5rem !important
}

.position-bottom-3 {
  bottom: .5rem !important
}

.position-top-4 {
  top: .625rem !important
}

.position-left-4 {
  left: .625rem !important
}

.position-right-4 {
  right: .625rem !important
}

.position-bottom-4 {
  bottom: .625rem !important
}

.position-top-5 {
  top: .75rem !important
}

.position-left-5 {
  left: .75rem !important
}

.position-right-5 {
  right: .75rem !important
}

.position-bottom-5 {
  bottom: .75rem !important
}

.position-top-6 {
  top: .875rem !important
}

.position-left-6 {
  left: .875rem !important
}

.position-right-6 {
  right: .875rem !important
}

.position-bottom-6 {
  bottom: .875rem !important
}

.position-top-7 {
  top: 1rem !important
}

.position-left-7 {
  left: 1rem !important
}

.position-right-7 {
  right: 1rem !important
}

.position-bottom-7 {
  bottom: 1rem !important
}

.position-top-8 {
  top: 1.125rem !important
}

.position-left-8 {
  left: 1.125rem !important
}

.position-right-8 {
  right: 1.125rem !important
}

.position-bottom-8 {
  bottom: 1.125rem !important
}

.position-top-9 {
  top: 1.25rem !important
}

.position-left-9 {
  left: 1.25rem !important
}

.position-right-9 {
  right: 1.25rem !important
}

.position-bottom-9 {
  bottom: 1.25rem !important
}

.position-top-10 {
  top: 1.375rem !important
}

.position-left-10 {
  left: 1.375rem !important
}

.position-right-10 {
  right: 1.375rem !important
}

.position-bottom-10 {
  bottom: 1.375rem !important
}

.position-top-11 {
  top: 1.5rem !important
}

.position-left-11 {
  left: 1.5rem !important
}

.position-right-11 {
  right: 1.5rem !important
}

.position-bottom-11 {
  bottom: 1.5rem !important
}

.position-top-12 {
  top: 1.625rem !important
}

.position-left-12 {
  left: 1.625rem !important
}

.position-right-12 {
  right: 1.625rem !important
}

.position-bottom-12 {
  bottom: 1.625rem !important
}

.position-top-13 {
  top: 1.75rem !important
}

.position-left-13 {
  left: 1.75rem !important
}

.position-right-13 {
  right: 1.75rem !important
}

.position-bottom-13 {
  bottom: 1.75rem !important
}

.position-top-14 {
  top: 1.875rem !important
}

.position-left-14 {
  left: 1.875rem !important
}

.position-right-14 {
  right: 1.875rem !important
}

.position-bottom-14 {
  bottom: 1.875rem !important
}

.position-top-15 {
  top: 2rem !important
}

.position-left-15 {
  left: 2rem !important
}

.position-right-15 {
  right: 2rem !important
}

.position-bottom-15 {
  bottom: 2rem !important
}

.position-top-16 {
  top: 2.125rem !important
}

.position-left-16 {
  left: 2.125rem !important
}

.position-right-16 {
  right: 2.125rem !important
}

.position-bottom-16 {
  bottom: 2.125rem !important
}

.position-top-17 {
  top: 2.25rem !important
}

.position-left-17 {
  left: 2.25rem !important
}

.position-right-17 {
  right: 2.25rem !important
}

.position-bottom-17 {
  bottom: 2.25rem !important
}

.position-top-18 {
  top: 2.375rem !important
}

.position-left-18 {
  left: 2.375rem !important
}

.position-right-18 {
  right: 2.375rem !important
}

.position-bottom-18 {
  bottom: 2.375rem !important
}

.position-top-19 {
  top: 2.5rem !important
}

.position-left-19 {
  left: 2.5rem !important
}

.position-right-19 {
  right: 2.5rem !important
}

.position-bottom-19 {
  bottom: 2.5rem !important
}

.position-top-20 {
  top: 3.125rem !important
}

.position-left-20 {
  left: 3.125rem !important
}

.position-right-20 {
  right: 3.125rem !important
}

.position-bottom-20 {
  bottom: 3.125rem !important
}

.position-top-21 {
  top: 3.75rem !important
}

.position-left-21 {
  left: 3.75rem !important
}

.position-right-21 {
  right: 3.75rem !important
}

.position-bottom-21 {
  bottom: 3.75rem !important
}

.position-top-22 {
  top: 4.375rem !important
}

.position-left-22 {
  left: 4.375rem !important
}

.position-right-22 {
  right: 4.375rem !important
}

.position-bottom-22 {
  bottom: 4.375rem !important
}

.position-top-23 {
  top: 5rem !important
}

.position-left-23 {
  left: 5rem !important
}

.position-right-23 {
  right: 5rem !important
}

.position-bottom-23 {
  bottom: 5rem !important
}

.position-top-24 {
  top: 5.625rem !important
}

.position-left-24 {
  left: 5.625rem !important
}

.position-right-24 {
  right: 5.625rem !important
}

.position-bottom-24 {
  bottom: 5.625rem !important
}

.position-top-25 {
  top: 6.25rem !important
}

.position-left-25 {
  left: 6.25rem !important
}

.position-right-25 {
  right: 6.25rem !important
}

.position-bottom-25 {
  bottom: 6.25rem !important
}

@media (min-width:576px) {
  .position-top-sm-0 {
    top: 0 !important
  }

  .position-left-sm-0 {
    left: 0 !important
  }

  .position-right-sm-0 {
    right: 0 !important
  }

  .position-bottom-sm-0 {
    bottom: 0 !important
  }

  .position-top-sm-1 {
    top: .25rem !important
  }

  .position-left-sm-1 {
    left: .25rem !important
  }

  .position-right-sm-1 {
    right: .25rem !important
  }

  .position-bottom-sm-1 {
    bottom: .25rem !important
  }

  .position-top-sm-2 {
    top: .375rem !important
  }

  .position-left-sm-2 {
    left: .375rem !important
  }

  .position-right-sm-2 {
    right: .375rem !important
  }

  .position-bottom-sm-2 {
    bottom: .375rem !important
  }

  .position-top-sm-3 {
    top: .5rem !important
  }

  .position-left-sm-3 {
    left: .5rem !important
  }

  .position-right-sm-3 {
    right: .5rem !important
  }

  .position-bottom-sm-3 {
    bottom: .5rem !important
  }

  .position-top-sm-4 {
    top: .625rem !important
  }

  .position-left-sm-4 {
    left: .625rem !important
  }

  .position-right-sm-4 {
    right: .625rem !important
  }

  .position-bottom-sm-4 {
    bottom: .625rem !important
  }

  .position-top-sm-5 {
    top: .75rem !important
  }

  .position-left-sm-5 {
    left: .75rem !important
  }

  .position-right-sm-5 {
    right: .75rem !important
  }

  .position-bottom-sm-5 {
    bottom: .75rem !important
  }

  .position-top-sm-6 {
    top: .875rem !important
  }

  .position-left-sm-6 {
    left: .875rem !important
  }

  .position-right-sm-6 {
    right: .875rem !important
  }

  .position-bottom-sm-6 {
    bottom: .875rem !important
  }

  .position-top-sm-7 {
    top: 1rem !important
  }

  .position-left-sm-7 {
    left: 1rem !important
  }

  .position-right-sm-7 {
    right: 1rem !important
  }

  .position-bottom-sm-7 {
    bottom: 1rem !important
  }

  .position-top-sm-8 {
    top: 1.125rem !important
  }

  .position-left-sm-8 {
    left: 1.125rem !important
  }

  .position-right-sm-8 {
    right: 1.125rem !important
  }

  .position-bottom-sm-8 {
    bottom: 1.125rem !important
  }

  .position-top-sm-9 {
    top: 1.25rem !important
  }

  .position-left-sm-9 {
    left: 1.25rem !important
  }

  .position-right-sm-9 {
    right: 1.25rem !important
  }

  .position-bottom-sm-9 {
    bottom: 1.25rem !important
  }

  .position-top-sm-10 {
    top: 1.375rem !important
  }

  .position-left-sm-10 {
    left: 1.375rem !important
  }

  .position-right-sm-10 {
    right: 1.375rem !important
  }

  .position-bottom-sm-10 {
    bottom: 1.375rem !important
  }

  .position-top-sm-11 {
    top: 1.5rem !important
  }

  .position-left-sm-11 {
    left: 1.5rem !important
  }

  .position-right-sm-11 {
    right: 1.5rem !important
  }

  .position-bottom-sm-11 {
    bottom: 1.5rem !important
  }

  .position-top-sm-12 {
    top: 1.625rem !important
  }

  .position-left-sm-12 {
    left: 1.625rem !important
  }

  .position-right-sm-12 {
    right: 1.625rem !important
  }

  .position-bottom-sm-12 {
    bottom: 1.625rem !important
  }

  .position-top-sm-13 {
    top: 1.75rem !important
  }

  .position-left-sm-13 {
    left: 1.75rem !important
  }

  .position-right-sm-13 {
    right: 1.75rem !important
  }

  .position-bottom-sm-13 {
    bottom: 1.75rem !important
  }

  .position-top-sm-14 {
    top: 1.875rem !important
  }

  .position-left-sm-14 {
    left: 1.875rem !important
  }

  .position-right-sm-14 {
    right: 1.875rem !important
  }

  .position-bottom-sm-14 {
    bottom: 1.875rem !important
  }

  .position-top-sm-15 {
    top: 2rem !important
  }

  .position-left-sm-15 {
    left: 2rem !important
  }

  .position-right-sm-15 {
    right: 2rem !important
  }

  .position-bottom-sm-15 {
    bottom: 2rem !important
  }

  .position-top-sm-16 {
    top: 2.125rem !important
  }

  .position-left-sm-16 {
    left: 2.125rem !important
  }

  .position-right-sm-16 {
    right: 2.125rem !important
  }

  .position-bottom-sm-16 {
    bottom: 2.125rem !important
  }

  .position-top-sm-17 {
    top: 2.25rem !important
  }

  .position-left-sm-17 {
    left: 2.25rem !important
  }

  .position-right-sm-17 {
    right: 2.25rem !important
  }

  .position-bottom-sm-17 {
    bottom: 2.25rem !important
  }

  .position-top-sm-18 {
    top: 2.375rem !important
  }

  .position-left-sm-18 {
    left: 2.375rem !important
  }

  .position-right-sm-18 {
    right: 2.375rem !important
  }

  .position-bottom-sm-18 {
    bottom: 2.375rem !important
  }

  .position-top-sm-19 {
    top: 2.5rem !important
  }

  .position-left-sm-19 {
    left: 2.5rem !important
  }

  .position-right-sm-19 {
    right: 2.5rem !important
  }

  .position-bottom-sm-19 {
    bottom: 2.5rem !important
  }

  .position-top-sm-20 {
    top: 3.125rem !important
  }

  .position-left-sm-20 {
    left: 3.125rem !important
  }

  .position-right-sm-20 {
    right: 3.125rem !important
  }

  .position-bottom-sm-20 {
    bottom: 3.125rem !important
  }

  .position-top-sm-21 {
    top: 3.75rem !important
  }

  .position-left-sm-21 {
    left: 3.75rem !important
  }

  .position-right-sm-21 {
    right: 3.75rem !important
  }

  .position-bottom-sm-21 {
    bottom: 3.75rem !important
  }

  .position-top-sm-22 {
    top: 4.375rem !important
  }

  .position-left-sm-22 {
    left: 4.375rem !important
  }

  .position-right-sm-22 {
    right: 4.375rem !important
  }

  .position-bottom-sm-22 {
    bottom: 4.375rem !important
  }

  .position-top-sm-23 {
    top: 5rem !important
  }

  .position-left-sm-23 {
    left: 5rem !important
  }

  .position-right-sm-23 {
    right: 5rem !important
  }

  .position-bottom-sm-23 {
    bottom: 5rem !important
  }

  .position-top-sm-24 {
    top: 5.625rem !important
  }

  .position-left-sm-24 {
    left: 5.625rem !important
  }

  .position-right-sm-24 {
    right: 5.625rem !important
  }

  .position-bottom-sm-24 {
    bottom: 5.625rem !important
  }

  .position-top-sm-25 {
    top: 6.25rem !important
  }

  .position-left-sm-25 {
    left: 6.25rem !important
  }

  .position-right-sm-25 {
    right: 6.25rem !important
  }

  .position-bottom-sm-25 {
    bottom: 6.25rem !important
  }
}

@media (min-width:768px) {
  .position-top-md-0 {
    top: 0 !important
  }

  .position-left-md-0 {
    left: 0 !important
  }

  .position-right-md-0 {
    right: 0 !important
  }

  .position-bottom-md-0 {
    bottom: 0 !important
  }

  .position-top-md-1 {
    top: .25rem !important
  }

  .position-left-md-1 {
    left: .25rem !important
  }

  .position-right-md-1 {
    right: .25rem !important
  }

  .position-bottom-md-1 {
    bottom: .25rem !important
  }

  .position-top-md-2 {
    top: .375rem !important
  }

  .position-left-md-2 {
    left: .375rem !important
  }

  .position-right-md-2 {
    right: .375rem !important
  }

  .position-bottom-md-2 {
    bottom: .375rem !important
  }

  .position-top-md-3 {
    top: .5rem !important
  }

  .position-left-md-3 {
    left: .5rem !important
  }

  .position-right-md-3 {
    right: .5rem !important
  }

  .position-bottom-md-3 {
    bottom: .5rem !important
  }

  .position-top-md-4 {
    top: .625rem !important
  }

  .position-left-md-4 {
    left: .625rem !important
  }

  .position-right-md-4 {
    right: .625rem !important
  }

  .position-bottom-md-4 {
    bottom: .625rem !important
  }

  .position-top-md-5 {
    top: .75rem !important
  }

  .position-left-md-5 {
    left: .75rem !important
  }

  .position-right-md-5 {
    right: .75rem !important
  }

  .position-bottom-md-5 {
    bottom: .75rem !important
  }

  .position-top-md-6 {
    top: .875rem !important
  }

  .position-left-md-6 {
    left: .875rem !important
  }

  .position-right-md-6 {
    right: .875rem !important
  }

  .position-bottom-md-6 {
    bottom: .875rem !important
  }

  .position-top-md-7 {
    top: 1rem !important
  }

  .position-left-md-7 {
    left: 1rem !important
  }

  .position-right-md-7 {
    right: 1rem !important
  }

  .position-bottom-md-7 {
    bottom: 1rem !important
  }

  .position-top-md-8 {
    top: 1.125rem !important
  }

  .position-left-md-8 {
    left: 1.125rem !important
  }

  .position-right-md-8 {
    right: 1.125rem !important
  }

  .position-bottom-md-8 {
    bottom: 1.125rem !important
  }

  .position-top-md-9 {
    top: 1.25rem !important
  }

  .position-left-md-9 {
    left: 1.25rem !important
  }

  .position-right-md-9 {
    right: 1.25rem !important
  }

  .position-bottom-md-9 {
    bottom: 1.25rem !important
  }

  .position-top-md-10 {
    top: 1.375rem !important
  }

  .position-left-md-10 {
    left: 1.375rem !important
  }

  .position-right-md-10 {
    right: 1.375rem !important
  }

  .position-bottom-md-10 {
    bottom: 1.375rem !important
  }

  .position-top-md-11 {
    top: 1.5rem !important
  }

  .position-left-md-11 {
    left: 1.5rem !important
  }

  .position-right-md-11 {
    right: 1.5rem !important
  }

  .position-bottom-md-11 {
    bottom: 1.5rem !important
  }

  .position-top-md-12 {
    top: 1.625rem !important
  }

  .position-left-md-12 {
    left: 1.625rem !important
  }

  .position-right-md-12 {
    right: 1.625rem !important
  }

  .position-bottom-md-12 {
    bottom: 1.625rem !important
  }

  .position-top-md-13 {
    top: 1.75rem !important
  }

  .position-left-md-13 {
    left: 1.75rem !important
  }

  .position-right-md-13 {
    right: 1.75rem !important
  }

  .position-bottom-md-13 {
    bottom: 1.75rem !important
  }

  .position-top-md-14 {
    top: 1.875rem !important
  }

  .position-left-md-14 {
    left: 1.875rem !important
  }

  .position-right-md-14 {
    right: 1.875rem !important
  }

  .position-bottom-md-14 {
    bottom: 1.875rem !important
  }

  .position-top-md-15 {
    top: 2rem !important
  }

  .position-left-md-15 {
    left: 2rem !important
  }

  .position-right-md-15 {
    right: 2rem !important
  }

  .position-bottom-md-15 {
    bottom: 2rem !important
  }

  .position-top-md-16 {
    top: 2.125rem !important
  }

  .position-left-md-16 {
    left: 2.125rem !important
  }

  .position-right-md-16 {
    right: 2.125rem !important
  }

  .position-bottom-md-16 {
    bottom: 2.125rem !important
  }

  .position-top-md-17 {
    top: 2.25rem !important
  }

  .position-left-md-17 {
    left: 2.25rem !important
  }

  .position-right-md-17 {
    right: 2.25rem !important
  }

  .position-bottom-md-17 {
    bottom: 2.25rem !important
  }

  .position-top-md-18 {
    top: 2.375rem !important
  }

  .position-left-md-18 {
    left: 2.375rem !important
  }

  .position-right-md-18 {
    right: 2.375rem !important
  }

  .position-bottom-md-18 {
    bottom: 2.375rem !important
  }

  .position-top-md-19 {
    top: 2.5rem !important
  }

  .position-left-md-19 {
    left: 2.5rem !important
  }

  .position-right-md-19 {
    right: 2.5rem !important
  }

  .position-bottom-md-19 {
    bottom: 2.5rem !important
  }

  .position-top-md-20 {
    top: 3.125rem !important
  }

  .position-left-md-20 {
    left: 3.125rem !important
  }

  .position-right-md-20 {
    right: 3.125rem !important
  }

  .position-bottom-md-20 {
    bottom: 3.125rem !important
  }

  .position-top-md-21 {
    top: 3.75rem !important
  }

  .position-left-md-21 {
    left: 3.75rem !important
  }

  .position-right-md-21 {
    right: 3.75rem !important
  }

  .position-bottom-md-21 {
    bottom: 3.75rem !important
  }

  .position-top-md-22 {
    top: 4.375rem !important
  }

  .position-left-md-22 {
    left: 4.375rem !important
  }

  .position-right-md-22 {
    right: 4.375rem !important
  }

  .position-bottom-md-22 {
    bottom: 4.375rem !important
  }

  .position-top-md-23 {
    top: 5rem !important
  }

  .position-left-md-23 {
    left: 5rem !important
  }

  .position-right-md-23 {
    right: 5rem !important
  }

  .position-bottom-md-23 {
    bottom: 5rem !important
  }

  .position-top-md-24 {
    top: 5.625rem !important
  }

  .position-left-md-24 {
    left: 5.625rem !important
  }

  .position-right-md-24 {
    right: 5.625rem !important
  }

  .position-bottom-md-24 {
    bottom: 5.625rem !important
  }

  .position-top-md-25 {
    top: 6.25rem !important
  }

  .position-left-md-25 {
    left: 6.25rem !important
  }

  .position-right-md-25 {
    right: 6.25rem !important
  }

  .position-bottom-md-25 {
    bottom: 6.25rem !important
  }
}

@media (min-width:992px) {
  .position-top-lg-0 {
    top: 0 !important
  }

  .position-left-lg-0 {
    left: 0 !important
  }

  .position-right-lg-0 {
    right: 0 !important
  }

  .position-bottom-lg-0 {
    bottom: 0 !important
  }

  .position-top-lg-1 {
    top: .25rem !important
  }

  .position-left-lg-1 {
    left: .25rem !important
  }

  .position-right-lg-1 {
    right: .25rem !important
  }

  .position-bottom-lg-1 {
    bottom: .25rem !important
  }

  .position-top-lg-2 {
    top: .375rem !important
  }

  .position-left-lg-2 {
    left: .375rem !important
  }

  .position-right-lg-2 {
    right: .375rem !important
  }

  .position-bottom-lg-2 {
    bottom: .375rem !important
  }

  .position-top-lg-3 {
    top: .5rem !important
  }

  .position-left-lg-3 {
    left: .5rem !important
  }

  .position-right-lg-3 {
    right: .5rem !important
  }

  .position-bottom-lg-3 {
    bottom: .5rem !important
  }

  .position-top-lg-4 {
    top: .625rem !important
  }

  .position-left-lg-4 {
    left: .625rem !important
  }

  .position-right-lg-4 {
    right: .625rem !important
  }

  .position-bottom-lg-4 {
    bottom: .625rem !important
  }

  .position-top-lg-5 {
    top: .75rem !important
  }

  .position-left-lg-5 {
    left: .75rem !important
  }

  .position-right-lg-5 {
    right: .75rem !important
  }

  .position-bottom-lg-5 {
    bottom: .75rem !important
  }

  .position-top-lg-6 {
    top: .875rem !important
  }

  .position-left-lg-6 {
    left: .875rem !important
  }

  .position-right-lg-6 {
    right: .875rem !important
  }

  .position-bottom-lg-6 {
    bottom: .875rem !important
  }

  .position-top-lg-7 {
    top: 1rem !important
  }

  .position-left-lg-7 {
    left: 1rem !important
  }

  .position-right-lg-7 {
    right: 1rem !important
  }

  .position-bottom-lg-7 {
    bottom: 1rem !important
  }

  .position-top-lg-8 {
    top: 1.125rem !important
  }

  .position-left-lg-8 {
    left: 1.125rem !important
  }

  .position-right-lg-8 {
    right: 1.125rem !important
  }

  .position-bottom-lg-8 {
    bottom: 1.125rem !important
  }

  .position-top-lg-9 {
    top: 1.25rem !important
  }

  .position-left-lg-9 {
    left: 1.25rem !important
  }

  .position-right-lg-9 {
    right: 1.25rem !important
  }

  .position-bottom-lg-9 {
    bottom: 1.25rem !important
  }

  .position-top-lg-10 {
    top: 1.375rem !important
  }

  .position-left-lg-10 {
    left: 1.375rem !important
  }

  .position-right-lg-10 {
    right: 1.375rem !important
  }

  .position-bottom-lg-10 {
    bottom: 1.375rem !important
  }

  .position-top-lg-11 {
    top: 1.5rem !important
  }

  .position-left-lg-11 {
    left: 1.5rem !important
  }

  .position-right-lg-11 {
    right: 1.5rem !important
  }

  .position-bottom-lg-11 {
    bottom: 1.5rem !important
  }

  .position-top-lg-12 {
    top: 1.625rem !important
  }

  .position-left-lg-12 {
    left: 1.625rem !important
  }

  .position-right-lg-12 {
    right: 1.625rem !important
  }

  .position-bottom-lg-12 {
    bottom: 1.625rem !important
  }

  .position-top-lg-13 {
    top: 1.75rem !important
  }

  .position-left-lg-13 {
    left: 1.75rem !important
  }

  .position-right-lg-13 {
    right: 1.75rem !important
  }

  .position-bottom-lg-13 {
    bottom: 1.75rem !important
  }

  .position-top-lg-14 {
    top: 1.875rem !important
  }

  .position-left-lg-14 {
    left: 1.875rem !important
  }

  .position-right-lg-14 {
    right: 1.875rem !important
  }

  .position-bottom-lg-14 {
    bottom: 1.875rem !important
  }

  .position-top-lg-15 {
    top: 2rem !important
  }

  .position-left-lg-15 {
    left: 2rem !important
  }

  .position-right-lg-15 {
    right: 2rem !important
  }

  .position-bottom-lg-15 {
    bottom: 2rem !important
  }

  .position-top-lg-16 {
    top: 2.125rem !important
  }

  .position-left-lg-16 {
    left: 2.125rem !important
  }

  .position-right-lg-16 {
    right: 2.125rem !important
  }

  .position-bottom-lg-16 {
    bottom: 2.125rem !important
  }

  .position-top-lg-17 {
    top: 2.25rem !important
  }

  .position-left-lg-17 {
    left: 2.25rem !important
  }

  .position-right-lg-17 {
    right: 2.25rem !important
  }

  .position-bottom-lg-17 {
    bottom: 2.25rem !important
  }

  .position-top-lg-18 {
    top: 2.375rem !important
  }

  .position-left-lg-18 {
    left: 2.375rem !important
  }

  .position-right-lg-18 {
    right: 2.375rem !important
  }

  .position-bottom-lg-18 {
    bottom: 2.375rem !important
  }

  .position-top-lg-19 {
    top: 2.5rem !important
  }

  .position-left-lg-19 {
    left: 2.5rem !important
  }

  .position-right-lg-19 {
    right: 2.5rem !important
  }

  .position-bottom-lg-19 {
    bottom: 2.5rem !important
  }

  .position-top-lg-20 {
    top: 3.125rem !important
  }

  .position-left-lg-20 {
    left: 3.125rem !important
  }

  .position-right-lg-20 {
    right: 3.125rem !important
  }

  .position-bottom-lg-20 {
    bottom: 3.125rem !important
  }

  .position-top-lg-21 {
    top: 3.75rem !important
  }

  .position-left-lg-21 {
    left: 3.75rem !important
  }

  .position-right-lg-21 {
    right: 3.75rem !important
  }

  .position-bottom-lg-21 {
    bottom: 3.75rem !important
  }

  .position-top-lg-22 {
    top: 4.375rem !important
  }

  .position-left-lg-22 {
    left: 4.375rem !important
  }

  .position-right-lg-22 {
    right: 4.375rem !important
  }

  .position-bottom-lg-22 {
    bottom: 4.375rem !important
  }

  .position-top-lg-23 {
    top: 5rem !important
  }

  .position-left-lg-23 {
    left: 5rem !important
  }

  .position-right-lg-23 {
    right: 5rem !important
  }

  .position-bottom-lg-23 {
    bottom: 5rem !important
  }

  .position-top-lg-24 {
    top: 5.625rem !important
  }

  .position-left-lg-24 {
    left: 5.625rem !important
  }

  .position-right-lg-24 {
    right: 5.625rem !important
  }

  .position-bottom-lg-24 {
    bottom: 5.625rem !important
  }

  .position-top-lg-25 {
    top: 6.25rem !important
  }

  .position-left-lg-25 {
    left: 6.25rem !important
  }

  .position-right-lg-25 {
    right: 6.25rem !important
  }

  .position-bottom-lg-25 {
    bottom: 6.25rem !important
  }
}

@media (min-width:1200px) {
  .position-top-xl-0 {
    top: 0 !important
  }

  .position-left-xl-0 {
    left: 0 !important
  }

  .position-right-xl-0 {
    right: 0 !important
  }

  .position-bottom-xl-0 {
    bottom: 0 !important
  }

  .position-top-xl-1 {
    top: .25rem !important
  }

  .position-left-xl-1 {
    left: .25rem !important
  }

  .position-right-xl-1 {
    right: .25rem !important
  }

  .position-bottom-xl-1 {
    bottom: .25rem !important
  }

  .position-top-xl-2 {
    top: .375rem !important
  }

  .position-left-xl-2 {
    left: .375rem !important
  }

  .position-right-xl-2 {
    right: .375rem !important
  }

  .position-bottom-xl-2 {
    bottom: .375rem !important
  }

  .position-top-xl-3 {
    top: .5rem !important
  }

  .position-left-xl-3 {
    left: .5rem !important
  }

  .position-right-xl-3 {
    right: .5rem !important
  }

  .position-bottom-xl-3 {
    bottom: .5rem !important
  }

  .position-top-xl-4 {
    top: .625rem !important
  }

  .position-left-xl-4 {
    left: .625rem !important
  }

  .position-right-xl-4 {
    right: .625rem !important
  }

  .position-bottom-xl-4 {
    bottom: .625rem !important
  }

  .position-top-xl-5 {
    top: .75rem !important
  }

  .position-left-xl-5 {
    left: .75rem !important
  }

  .position-right-xl-5 {
    right: .75rem !important
  }

  .position-bottom-xl-5 {
    bottom: .75rem !important
  }

  .position-top-xl-6 {
    top: .875rem !important
  }

  .position-left-xl-6 {
    left: .875rem !important
  }

  .position-right-xl-6 {
    right: .875rem !important
  }

  .position-bottom-xl-6 {
    bottom: .875rem !important
  }

  .position-top-xl-7 {
    top: 1rem !important
  }

  .position-left-xl-7 {
    left: 1rem !important
  }

  .position-right-xl-7 {
    right: 1rem !important
  }

  .position-bottom-xl-7 {
    bottom: 1rem !important
  }

  .position-top-xl-8 {
    top: 1.125rem !important
  }

  .position-left-xl-8 {
    left: 1.125rem !important
  }

  .position-right-xl-8 {
    right: 1.125rem !important
  }

  .position-bottom-xl-8 {
    bottom: 1.125rem !important
  }

  .position-top-xl-9 {
    top: 1.25rem !important
  }

  .position-left-xl-9 {
    left: 1.25rem !important
  }

  .position-right-xl-9 {
    right: 1.25rem !important
  }

  .position-bottom-xl-9 {
    bottom: 1.25rem !important
  }

  .position-top-xl-10 {
    top: 1.375rem !important
  }

  .position-left-xl-10 {
    left: 1.375rem !important
  }

  .position-right-xl-10 {
    right: 1.375rem !important
  }

  .position-bottom-xl-10 {
    bottom: 1.375rem !important
  }

  .position-top-xl-11 {
    top: 1.5rem !important
  }

  .position-left-xl-11 {
    left: 1.5rem !important
  }

  .position-right-xl-11 {
    right: 1.5rem !important
  }

  .position-bottom-xl-11 {
    bottom: 1.5rem !important
  }

  .position-top-xl-12 {
    top: 1.625rem !important
  }

  .position-left-xl-12 {
    left: 1.625rem !important
  }

  .position-right-xl-12 {
    right: 1.625rem !important
  }

  .position-bottom-xl-12 {
    bottom: 1.625rem !important
  }

  .position-top-xl-13 {
    top: 1.75rem !important
  }

  .position-left-xl-13 {
    left: 1.75rem !important
  }

  .position-right-xl-13 {
    right: 1.75rem !important
  }

  .position-bottom-xl-13 {
    bottom: 1.75rem !important
  }

  .position-top-xl-14 {
    top: 1.875rem !important
  }

  .position-left-xl-14 {
    left: 1.875rem !important
  }

  .position-right-xl-14 {
    right: 1.875rem !important
  }

  .position-bottom-xl-14 {
    bottom: 1.875rem !important
  }

  .position-top-xl-15 {
    top: 2rem !important
  }

  .position-left-xl-15 {
    left: 2rem !important
  }

  .position-right-xl-15 {
    right: 2rem !important
  }

  .position-bottom-xl-15 {
    bottom: 2rem !important
  }

  .position-top-xl-16 {
    top: 2.125rem !important
  }

  .position-left-xl-16 {
    left: 2.125rem !important
  }

  .position-right-xl-16 {
    right: 2.125rem !important
  }

  .position-bottom-xl-16 {
    bottom: 2.125rem !important
  }

  .position-top-xl-17 {
    top: 2.25rem !important
  }

  .position-left-xl-17 {
    left: 2.25rem !important
  }

  .position-right-xl-17 {
    right: 2.25rem !important
  }

  .position-bottom-xl-17 {
    bottom: 2.25rem !important
  }

  .position-top-xl-18 {
    top: 2.375rem !important
  }

  .position-left-xl-18 {
    left: 2.375rem !important
  }

  .position-right-xl-18 {
    right: 2.375rem !important
  }

  .position-bottom-xl-18 {
    bottom: 2.375rem !important
  }

  .position-top-xl-19 {
    top: 2.5rem !important
  }

  .position-left-xl-19 {
    left: 2.5rem !important
  }

  .position-right-xl-19 {
    right: 2.5rem !important
  }

  .position-bottom-xl-19 {
    bottom: 2.5rem !important
  }

  .position-top-xl-20 {
    top: 3.125rem !important
  }

  .position-left-xl-20 {
    left: 3.125rem !important
  }

  .position-right-xl-20 {
    right: 3.125rem !important
  }

  .position-bottom-xl-20 {
    bottom: 3.125rem !important
  }

  .position-top-xl-21 {
    top: 3.75rem !important
  }

  .position-left-xl-21 {
    left: 3.75rem !important
  }

  .position-right-xl-21 {
    right: 3.75rem !important
  }

  .position-bottom-xl-21 {
    bottom: 3.75rem !important
  }

  .position-top-xl-22 {
    top: 4.375rem !important
  }

  .position-left-xl-22 {
    left: 4.375rem !important
  }

  .position-right-xl-22 {
    right: 4.375rem !important
  }

  .position-bottom-xl-22 {
    bottom: 4.375rem !important
  }

  .position-top-xl-23 {
    top: 5rem !important
  }

  .position-left-xl-23 {
    left: 5rem !important
  }

  .position-right-xl-23 {
    right: 5rem !important
  }

  .position-bottom-xl-23 {
    bottom: 5rem !important
  }

  .position-top-xl-24 {
    top: 5.625rem !important
  }

  .position-left-xl-24 {
    left: 5.625rem !important
  }

  .position-right-xl-24 {
    right: 5.625rem !important
  }

  .position-bottom-xl-24 {
    bottom: 5.625rem !important
  }

  .position-top-xl-25 {
    top: 6.25rem !important
  }

  .position-left-xl-25 {
    left: 6.25rem !important
  }

  .position-right-xl-25 {
    right: 6.25rem !important
  }

  .position-bottom-xl-25 {
    bottom: 6.25rem !important
  }
}

.footer {
  overflow: hidden
}

a.footer-icon {
  color: #adb5bd;
  line-height: 1;
  text-decoration: none;
  transition: all .2s ease-in-out
}

a.footer-icon i,
a.footer-icon>.material-icons {
  font-size: 1.5rem
}

a.footer-icon:hover {
  color: #838f9b
}

a.footer-brand {
  font-family: Roboto, sans-serif;
  font-size: 1.875rem;
  font-weight: 700;
  color: #212529;
  text-decoration: none;
  line-height: 1;
  vertical-align: middle;
  display: inline-block
}

.footer-menu {
  list-style: none;
  margin: 0 -1.5625rem;
  padding: 0;
  font-size: 0;
  line-height: 1.5
}

.footer-menu li {
  display: inline
}

.footer-menu a {
  display: inline-block;
  font-family: Arimo, sans-serif;
  text-transform: uppercase;
  font-size: .75rem;
  letter-spacing: .125rem;
  font-weight: 700;
  margin: 0 1.5625rem;
  color: #4a4a4a;
  transition: all .2s ease-in-out
}

.footer-menu a:hover {
  color: #0a0a0a
}

.object-fit-cover {
  -o-object-fit: cover;
  font-family: "object-fit:cover";
  object-fit: cover
}

.border-bold {
  border-width: .125rem
}

.cursor-pointer {
  cursor: pointer
}

.img-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  font-family: "object-fit:cover";
  object-fit: cover
}

@supports ((-webkit-clip-path:polygon(0 0, 100% 0, 100% 75%, 0 100%)) or (clip-path:polygon(0 0, 100% 0, 100% 75%, 0 100%))) or ((-webkit-filter:drop-shadow(0 0 0.625rem rgba(0, 0, 0, 0.1))) or (filter:drop-shadow(0 0 0.625rem rgba(0, 0, 0, 0.1)))) {
  .popover {
    background: 0 0;
    box-shadow: none;
    -webkit-filter: drop-shadow(0 0 .625rem rgba(0, 0, 0, .1));
    filter: drop-shadow(0 0 .625rem rgba(0, 0, 0, .1))
  }

  .popover>.arrow {
    display: none
  }

  .popover>.popover-header {
    display: none
  }

  .popover>.popover-body {
    background: #fff
  }

  .bs-popover-auto[x-placement^=top],
  .bs-popover-top {
    margin-bottom: .625rem
  }

  .bs-popover-auto[x-placement^=top]>.popover-body,
  .bs-popover-top>.popover-body {
    -webkit-clip-path: polygon(100% 0, 100% calc(100% - .625rem), 50% 100%, 0 calc(100% - .625rem), 0 0);
    clip-path: polygon(100% 0, 100% calc(100% - .625rem), 50% 100%, 0 calc(100% - .625rem), 0 0);
    padding-bottom: 1.125rem
  }

  .bs-popover-auto[x-placement^=bottom],
  .bs-popover-bottom {
    margin-top: .625rem
  }

  .bs-popover-auto[x-placement^=bottom]>.popover-body,
  .bs-popover-bottom>.popover-body {
    -webkit-clip-path: polygon(50% 0, 100% .625rem, 100% 100%, 0 100%, 0 .625rem);
    clip-path: polygon(50% 0, 100% .625rem, 100% 100%, 0 100%, 0 .625rem);
    padding-top: 1.125rem
  }

  .bs-popover-auto[x-placement^=left],
  .bs-popover-left {
    margin-right: .625rem
  }

  .bs-popover-auto[x-placement^=left]>.popover-body,
  .bs-popover-left>.popover-body {
    -webkit-clip-path: polygon(0 0, calc(100% - .625rem) 0, 100% 50%, calc(100% - .625rem) 100%, 0 100%);
    clip-path: polygon(0 0, calc(100% - .625rem) 0, 100% 50%, calc(100% - .625rem) 100%, 0 100%);
    padding-right: 1.375rem
  }

  .bs-popover-auto[x-placement^=right],
  .bs-popover-right {
    margin-left: .625rem
  }

  .bs-popover-auto[x-placement^=right]>.popover-body,
  .bs-popover-right>.popover-body {
    -webkit-clip-path: polygon(.625rem 0, 100% 0, 100% 100%, .625rem 100%, 0 50%);
    clip-path: polygon(.625rem 0, 100% 0, 100% 100%, .625rem 100%, 0 50%);
    padding-left: 1.375rem
  }
}

.card {
  z-index: 0
}

.card-ghost {
  border-color: rgba(255, 255, 255, .25);
  background: 0 0;
  color: #fff
}

.card-ghost p {
  color: inherit
}

.card-square:before {
  content: '';
  display: inline-block;
  padding-top: 100%
}

.card-square .card-body {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0
}

.card-icon {
  color: #adb5bd;
  line-height: 1
}

.card-icon .material-icons,
.card-icon i {
  font-size: 2.25rem
}

.card-accent-left:before,
.card-accent-right:before {
  content: '';
  position: absolute;
  top: -1px;
  bottom: -1px;
  width: .1875rem;
  background: #3db36b
}

.card-accent-left:before {
  left: -1px
}

.card-accent-right:before {
  right: -1px
}

.card-text {
  color: inherit
}

.card-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1
}

.card .card-body-hover {
  opacity: 0;
  -webkit-transform: scale(.75);
  transform: scale(.75);
  transition: all .2s ease-in-out
}

.card .card-bg-hover {
  opacity: 0;
  transition: all .2s ease-in-out
}

.card:hover .card-body-hover {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1
}

.card:hover .card-bg-hover {
  opacity: 1
}

.card-link-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1030
}

.card-vertical-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column;
  flex-flow: column
}

.card-vertical-group>.card+.card {
  margin-top: 0;
  border-top: 0
}

.card.demo-card {
  border: 0
}

.card.demo-card .card-body {
  padding: 0
}

.demo-card-placeholder {
  position: relative
}

.demo-card-placeholder:before {
  content: '';
  display: block;
  padding-top: 152.7777778%
}

.demo-card-placeholder .demo-card-placeholder-text {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center
}

.card.demo-card .card-body img,
.demo-card-placeholder {
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, .1);
  transition: all .2s ease-in-out
}

.card.demo-card:hover .card-body img,
.demo-card-placeholder:hover {
  box-shadow: 0 0 45px 0 rgba(0, 0, 0, .15)
}

.custom-checkbox-wrap,
.custom-radio-wrap {
  position: relative;
  overflow: hidden;
  display: inline-block
}

.form-check>.custom-checkbox-wrap,
.form-check>.custom-radio-wrap {
  position: absolute;
  margin-top: .3rem;
  margin-left: -1.25rem
}

.form-check-inline>.custom-checkbox-wrap,
.form-check-inline>.custom-radio-wrap {
  position: static;
  margin-top: 0;
  margin-right: .3125rem;
  margin-left: 0
}

.custom-checkbox-wrap>.custom-checkbox,
.custom-radio-wrap>.custom-radio {
  position: absolute;
  left: -9999px
}

.custom-checkbox-presenter,
.custom-radio-presenter {
  position: relative;
  width: 1em;
  height: 1em;
  cursor: pointer
}

.custom-checkbox-presenter:after,
.custom-checkbox-presenter:before {
  position: absolute;
  text-align: center;
  content: '';
  display: inline-block;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all .2s ease-in-out
}

.custom-checkbox-presenter:before {
  border-radius: 2px;
  background-color: #dee2e6
}

.custom-checkbox-presenter:after {
  text-align: center;
  line-height: 1;
  content: '✓';
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  color: #fff;
  font-family: Arial, sans-serif
}

.custom-checkbox-presenter:hover:before {
  background-color: #ced4da
}

.custom-checkbox-wrap.checked>.custom-checkbox-presenter:before {
  background-color: #3db36b
}

.custom-checkbox-wrap.checked>.custom-checkbox-presenter:after {
  -webkit-transform: scale(.8);
  transform: scale(.8);
  opacity: 1
}

.custom-radio-presenter {
  border-radius: 50%;
  border: 2px solid #9b9b9b;
  transition: all .2s ease-in-out
}

.custom-radio-presenter:before {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all .2s ease-in-out;
  background-color: #9b9b9b;
  border-radius: 50%;
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0
}

.custom-radio-presenter:hover:before {
  -webkit-transform: scale(.75);
  transform: scale(.75);
  opacity: 1
}

.custom-radio-wrap.checked>.custom-radio-presenter {
  border-color: #3db36b
}

.custom-radio-wrap.checked>.custom-radio-presenter:before {
  background-color: #3db36b;
  -webkit-transform: scale(.7);
  transform: scale(.7);
  opacity: 1
}

.form-label {
  font-family: Arimo, sans-serif;
  letter-spacing: .125rem;
  text-transform: uppercase;
  font-size: .75rem;
  color: #9b9b9b
}

.form-check-label {
  cursor: pointer
}

.form-selectable-label {
  cursor: pointer;
  position: relative;
  border: .0625rem solid #d3d3d3;
  z-index: 1;
  transition: all .2s ease-in-out
}

.form-selectable-label:before {
  content: '';
  position: absolute;
  top: -.0625rem;
  left: -.0625rem;
  right: -.0625rem;
  bottom: -.0625rem;
  z-index: -1;
  border: .125rem solid transparent;
  transition: all .2s ease-in-out
}

.form-selectable-label.selected {
  z-index: 2
}

.form-selectable-label.selected:before {
  border-color: #3db36b
}

.form-selectable-label label {
  cursor: pointer
}

.form-selectable-label.collapse-top {
  margin-top: -.0625rem;
  border-top: 0
}

.form-selectable-label.collapse-bottom {
  margin-bottom: -.0625rem;
  border-bottom: 0
}

.form-collapsible-section-body:before {
  content: '';
  display: block;
  height: 1.875rem
}

.form-collapsible-section-head {
  background: #f9f9f9;
  font-size: 1.3125rem;
  line-height: 1.5rem;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  color: #000;
  overflow: hidden
}

.form-collapsible-section-number,
.form-collapsible-section-title,
.form-collapsible-section-toggle {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 1.125rem
}

.form-collapsible-section-number {
  background: #ececec;
  -ms-flex-pack: center;
  justify-content: center;
  min-width: 3.75rem
}

.form-collapsible-section-toggle {
  transition: all .2s ease-in-out;
  margin-left: auto
}

.form-collapsible-section[aria-expanded=true] .form-collapsible-section-toggle {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg)
}

.radio-buttons-group {
  max-width: 100%;
  overflow-x: auto;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  border: .0625rem solid #d3d3d3
}

.radio-buttons-group .btn {
  position: relative;
  border: 0;
  border-radius: true
}

.radio-buttons-group .btn,
.radio-buttons-group .btn:active[class],
.radio-buttons-group .btn:focus[class] {
  color: #9b9b9b
}

.radio-buttons-group .btn:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: .125rem;
  background: #000;
  transition: opacity .15s linear;
  opacity: 0
}

.radio-buttons-group .btn.selected,
.radio-buttons-group .btn.selected:active[class],
.radio-buttons-group .btn.selected:focus[class] {
  color: #000
}

.radio-buttons-group .btn.selected:before {
  opacity: 1
}

.material-icons {
  vertical-align: middle
}

.material-icons.material-icons-size-auto {
  font-size: 1em
}

.jarallax {
  position: relative;
  z-index: 0
}

.jarallax>.jarallax-img {
  position: absolute;
  -o-object-fit: cover;
  font-family: "object-fit:cover";
  object-fit: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1
}

.jarallax .jarallax-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1
}

.section {
  position: relative;
  overflow: hidden;
  padding: 2.5rem 0;
  background: #fff
}

@media (min-width:576px) {
  .section {
    padding: 5rem 0
  }
}

@media (min-width:992px) {
  .section {
    padding: 6.25rem 0
  }
}

.section-background-color,
.section-background-image {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10
}

.section-background-image {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  font-family: "object-fit:cover";
  object-fit: cover
}

.section-background-color {
  z-index: 15
}

.section-content {
  position: relative;
  z-index: 20
}

.header-spacing-helper {
  display: none
}

.header-fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1030;
  transition: all .2s ease-in-out
}

body.page-scrolled .header-fixed {
  box-shadow: 0 0 .625rem rgba(0, 0, 0, .1)
}

.header-fixed+.header-spacing-helper {
  display: block
}

@media (min-width:576px) {
  .header-fixed-sm {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1030;
    transition: all .2s ease-in-out
  }

  body.page-scrolled .header-fixed-sm {
    box-shadow: 0 0 .625rem rgba(0, 0, 0, .1)
  }

  .header-fixed-sm+.header-spacing-helper {
    display: block
  }
}

@media (min-width:768px) {
  .header-fixed-md {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1030;
    transition: all .2s ease-in-out
  }

  body.page-scrolled .header-fixed-md {
    box-shadow: 0 0 .625rem rgba(0, 0, 0, .1)
  }

  .header-fixed-md+.header-spacing-helper {
    display: block
  }
}

@media (min-width:992px) {
  .header-fixed-lg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1030;
    transition: all .2s ease-in-out
  }

  body.page-scrolled .header-fixed-lg {
    box-shadow: 0 0 .625rem rgba(0, 0, 0, .1)
  }

  .header-fixed-lg+.header-spacing-helper {
    display: block
  }
}

@media (min-width:1200px) {
  .header-fixed-xl {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1030;
    transition: all .2s ease-in-out
  }

  body.page-scrolled .header-fixed-xl {
    box-shadow: 0 0 .625rem rgba(0, 0, 0, .1)
  }

  .header-fixed-xl+.header-spacing-helper {
    display: block
  }
}

.header-absolute {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1030
}

.tour-tabs-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column
}

.tour-tabs-nav-link {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 1.875rem;
  opacity: .25;
  transition: all .2s ease-in-out;
  background: 0 0
}

.tour-tabs-nav-link,
.tour-tabs-nav-link:active,
.tour-tabs-nav-link:focus,
.tour-tabs-nav-link:hover {
  color: #212529;
  text-decoration: none
}

.tour-tabs-nav-link.active {
  opacity: 1;
  background: #f9f9f9
}

.tour-tabs-content {
  background: #f9f9f9;
  padding: 1.875rem;
  -ms-flex: 1;
  flex: 1 1
}

@media (min-width:992px) {
  .tour-tabs-content {
    padding: 3.125rem
  }
}

.brand-icon {
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 1em;
  height: 1em;
  vertical-align: middle;
  font-size: 1.5rem;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  transition: all .2s ease-in-out
}

.brand-icon>i {
  font-size: 62.5%
}

.brand-icon-circle {
  border-radius: 50%;
  color: #fff
}

.brand-icon-tiktok {
  background: #263961
}

.brand-icon-tiktok,
.brand-icon-tiktok:active,
.brand-icon-tiktok:focus,
.brand-icon-tiktok:hover {
  color: #fff
}

.brand-icon-tiktok:active,
.brand-icon-tiktok:focus,
.brand-icon-tiktok:hover {
  background: #0077b5
}

.brand-icon-facebook {
  background: #3b5998
}

.brand-icon-facebook,
.brand-icon-facebook:active,
.brand-icon-facebook:focus,
.brand-icon-facebook:hover {
  color: #fff
}

.brand-icon-facebook:active,
.brand-icon-facebook:focus,
.brand-icon-facebook:hover {
  background: #263961
}

.brand-icon-twitter {
  background: #1da1f2
}

.brand-icon-twitter,
.brand-icon-twitter:active,
.brand-icon-twitter:focus,
.brand-icon-twitter:hover {
  color: #fff
}

.brand-icon-twitter:active,
.brand-icon-twitter:focus,
.brand-icon-twitter:hover {
  background: #0b76b8
}

.brand-icon-linkedin {
  background: #0077b5
}

.brand-icon-linkedin,
.brand-icon-linkedin:active,
.brand-icon-linkedin:focus,
.brand-icon-linkedin:hover {
  color: #fff
}

.brand-icon-linkedin:active,
.brand-icon-linkedin:focus,
.brand-icon-linkedin:hover {
  background: #004569
}

.brand-icon-youtube {
  background: red
}

.brand-icon-youtube,
.brand-icon-youtube:active,
.brand-icon-youtube:focus,
.brand-icon-youtube:hover {
  color: #fff
}

.brand-icon-youtube:active,
.brand-icon-youtube:focus,
.brand-icon-youtube:hover {
  background: #b30000
}

.brand-icon-behance {
  background: #1769ff
}

.brand-icon-behance,
.brand-icon-behance:active,
.brand-icon-behance:focus,
.brand-icon-behance:hover {
  color: #fff
}

.brand-icon-behance:active,
.brand-icon-behance:focus,
.brand-icon-behance:hover {
  background: #0047ca
}

.brand-icon-instagram {
  background: #f56040
}

.brand-icon-instagram,
.brand-icon-instagram:active,
.brand-icon-instagram:focus,
.brand-icon-instagram:hover {
  color: #fff
}

.brand-icon-instagram:active,
.brand-icon-instagram:focus,
.brand-icon-instagram:hover {
  background: #dd310c
}

.brand-icon-pinterest {
  background: #bd081c
}

.brand-icon-pinterest,
.brand-icon-pinterest:active,
.brand-icon-pinterest:focus,
.brand-icon-pinterest:hover {
  color: #fff
}

.brand-icon-pinterest:active,
.brand-icon-pinterest:focus,
.brand-icon-pinterest:hover {
  background: #740511
}

.brand-icon-tumblr {
  background: #35465c
}

.brand-icon-tumblr,
.brand-icon-tumblr:active,
.brand-icon-tumblr:focus,
.brand-icon-tumblr:hover {
  color: #fff
}

.brand-icon-tumblr:active,
.brand-icon-tumblr:focus,
.brand-icon-tumblr:hover {
  background: #19212b
}

.brand-icon-vimeo {
  background: #1ab7ea
}

.brand-icon-vimeo,
.brand-icon-vimeo:active,
.brand-icon-vimeo:focus,
.brand-icon-vimeo:hover {
  color: #fff
}

.brand-icon-vimeo:active,
.brand-icon-vimeo:focus,
.brand-icon-vimeo:hover {
  background: #0f83a8
}

.brand-icons-list {
  display: -ms-flexbox;
  display: flex;
  margin: -.625rem
}

.brand-icons-list .brand-icon {
  margin: .625rem
}

.card-slider {
  overflow: hidden
}

.card-slider:after {
  content: '';
  display: none
}

@media (min-width:576px) {
  .card-slider:after {
    content: 'flickity'
  }
}

.card-slider-gap-0 {
  margin-left: 0;
  margin-right: 0
}

.card-slider-gap-0 .card-slider-item {
  padding-left: 0;
  padding-right: 0
}

.card-slider-gap-1 {
  margin-left: -.25rem;
  margin-right: -.25rem
}

.card-slider-gap-1 .card-slider-item {
  padding-left: .25rem;
  padding-right: .25rem
}

.card-slider-gap-2 {
  margin-left: -.375rem;
  margin-right: -.375rem
}

.card-slider-gap-2 .card-slider-item {
  padding-left: .375rem;
  padding-right: .375rem
}

.card-slider-gap-3 {
  margin-left: -.5rem;
  margin-right: -.5rem
}

.card-slider-gap-3 .card-slider-item {
  padding-left: .5rem;
  padding-right: .5rem
}

.card-slider-gap-4 {
  margin-left: -.625rem;
  margin-right: -.625rem
}

.card-slider-gap-4 .card-slider-item {
  padding-left: .625rem;
  padding-right: .625rem
}

.card-slider-gap-5 {
  margin-left: -.75rem;
  margin-right: -.75rem
}

.card-slider-gap-5 .card-slider-item {
  padding-left: .75rem;
  padding-right: .75rem
}

.card-slider-gap-6 {
  margin-left: -.875rem;
  margin-right: -.875rem
}

.card-slider-gap-6 .card-slider-item {
  padding-left: .875rem;
  padding-right: .875rem
}

.card-slider-gap-7 {
  margin-left: -1rem;
  margin-right: -1rem
}

.card-slider-gap-7 .card-slider-item {
  padding-left: 1rem;
  padding-right: 1rem
}

.card-slider-gap-8 {
  margin-left: -1.125rem;
  margin-right: -1.125rem
}

.card-slider-gap-8 .card-slider-item {
  padding-left: 1.125rem;
  padding-right: 1.125rem
}

.card-slider-gap-9 {
  margin-left: -1.25rem;
  margin-right: -1.25rem
}

.card-slider-gap-9 .card-slider-item {
  padding-left: 1.25rem;
  padding-right: 1.25rem
}

.card-slider-gap-10 {
  margin-left: -1.375rem;
  margin-right: -1.375rem
}

.card-slider-gap-10 .card-slider-item {
  padding-left: 1.375rem;
  padding-right: 1.375rem
}

.card-slider-gap-11 {
  margin-left: -1.5rem;
  margin-right: -1.5rem
}

.card-slider-gap-11 .card-slider-item {
  padding-left: 1.5rem;
  padding-right: 1.5rem
}

.card-slider-gap-12 {
  margin-left: -1.625rem;
  margin-right: -1.625rem
}

.card-slider-gap-12 .card-slider-item {
  padding-left: 1.625rem;
  padding-right: 1.625rem
}

.card-slider-gap-13 {
  margin-left: -1.75rem;
  margin-right: -1.75rem
}

.card-slider-gap-13 .card-slider-item {
  padding-left: 1.75rem;
  padding-right: 1.75rem
}

.card-slider-gap-14 {
  margin-left: -1.875rem;
  margin-right: -1.875rem
}

.card-slider-gap-14 .card-slider-item {
  padding-left: 1.875rem;
  padding-right: 1.875rem
}

.card-slider-gap-15 {
  margin-left: -2rem;
  margin-right: -2rem
}

.card-slider-gap-15 .card-slider-item {
  padding-left: 2rem;
  padding-right: 2rem
}

.card-slider-gap-16 {
  margin-left: -2.125rem;
  margin-right: -2.125rem
}

.card-slider-gap-16 .card-slider-item {
  padding-left: 2.125rem;
  padding-right: 2.125rem
}

.card-slider-gap-17 {
  margin-left: -2.25rem;
  margin-right: -2.25rem
}

.card-slider-gap-17 .card-slider-item {
  padding-left: 2.25rem;
  padding-right: 2.25rem
}

.card-slider-gap-18 {
  margin-left: -2.375rem;
  margin-right: -2.375rem
}

.card-slider-gap-18 .card-slider-item {
  padding-left: 2.375rem;
  padding-right: 2.375rem
}

.card-slider-gap-19 {
  margin-left: -2.5rem;
  margin-right: -2.5rem
}

.card-slider-gap-19 .card-slider-item {
  padding-left: 2.5rem;
  padding-right: 2.5rem
}

.card-slider-gap-20 {
  margin-left: -3.125rem;
  margin-right: -3.125rem
}

.card-slider-gap-20 .card-slider-item {
  padding-left: 3.125rem;
  padding-right: 3.125rem
}

.card-slider-gap-21 {
  margin-left: -3.75rem;
  margin-right: -3.75rem
}

.card-slider-gap-21 .card-slider-item {
  padding-left: 3.75rem;
  padding-right: 3.75rem
}

.card-slider-gap-22 {
  margin-left: -4.375rem;
  margin-right: -4.375rem
}

.card-slider-gap-22 .card-slider-item {
  padding-left: 4.375rem;
  padding-right: 4.375rem
}

.card-slider-gap-23 {
  margin-left: -5rem;
  margin-right: -5rem
}

.card-slider-gap-23 .card-slider-item {
  padding-left: 5rem;
  padding-right: 5rem
}

.card-slider-gap-24 {
  margin-left: -5.625rem;
  margin-right: -5.625rem
}

.card-slider-gap-24 .card-slider-item {
  padding-left: 5.625rem;
  padding-right: 5.625rem
}

.card-slider-gap-25 {
  margin-left: -6.25rem;
  margin-right: -6.25rem
}

.card-slider-gap-25 .card-slider-item {
  padding-left: 6.25rem;
  padding-right: 6.25rem
}

.card-slider-items-1 .card-slider-item {
  box-sizing: border-box;
  width: 100%
}

.card-slider-items-2 .card-slider-item {
  box-sizing: border-box;
  width: 50%
}

.card-slider-items-3 .card-slider-item {
  box-sizing: border-box;
  width: 33.33333%
}

.card-slider-items-4 .card-slider-item {
  box-sizing: border-box;
  width: 25%
}

.card-slider-items-5 .card-slider-item {
  box-sizing: border-box;
  width: 20%
}

.card-slider-items-6 .card-slider-item {
  box-sizing: border-box;
  width: 16.66667%
}

.card-slider-items-7 .card-slider-item {
  box-sizing: border-box;
  width: 14.28571%
}

.card-slider-items-8 .card-slider-item {
  box-sizing: border-box;
  width: 12.5%
}

.card-slider-items-9 .card-slider-item {
  box-sizing: border-box;
  width: 11.11111%
}

.card-slider-items-10 .card-slider-item {
  box-sizing: border-box;
  width: 10%
}

.card-slider-items-11 .card-slider-item {
  box-sizing: border-box;
  width: 9.09091%
}

.card-slider-items-12 .card-slider-item {
  box-sizing: border-box;
  width: 8.33333%
}

.card-slider-item {
  box-sizing: content-box
}

.card-slider:not(.flickity-enabled) {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

@media (min-width:576px) {
  .card-slider:not(.flickity-enabled) {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap
  }
}

.card-slider:not(.flickity-enabled) .card-slider-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
  flex-shrink: 0
}

.card-slider-dots {
  font-size: 0;
  line-height: 1;
  list-style: none;
  margin: -.3125rem;
  padding: 0
}

.card-slider-dots>li {
  display: inline
}

.card-slider-dots>li>a {
  display: inline-block;
  font-size: .625rem;
  text-indent: -9999px;
  width: 1em;
  height: 1em;
  background-color: rgba(255, 255, 255, .25);
  border-radius: 50%;
  transition: all .2s ease-in-out;
  margin: .3125rem
}

.card-slider-dots>li.card-slider-dot-active a,
.card-slider-dots>li:hover a {
  background-color: #fff
}

.card-slider-services .card-slider-item {
  width: 100%;
  padding-right: 15px;
  margin-bottom: 30px
}

@media (min-width:576px) {
  .card-slider-services .card-slider-item {
    width: 330px;
    margin-bottom: 0;
    padding-right: 30px
  }
}

@media (min-width:768px) {
  .card-slider-services .card-slider-item {
    width: 330px
  }
}

@media (min-width:992px) {
  .card-slider-services .card-slider-item {
    width: 370px
  }
}

@media (min-width:1200px) {
  .card-slider-services .card-slider-item {
    width: 445px
  }
}

.card-slider-testimonials {
  margin: 0 -15px
}

.card-slider-testimonials .card-slider-item {
  box-sizing: border-box;
  width: 100%;
  padding: 0 15px;
  margin-bottom: 30px
}

@media (min-width:768px) {
  .card-slider-testimonials .card-slider-item {
    width: 50%;
    margin-bottom: 0
  }
}

.card-slider-brands {
  margin: 0 0 -15px
}

.card-slider-brands .card-slider-item {
  margin-right: 1.875rem;
  margin-bottom: 15px
}

@media (min-width:768px) {
  .card-slider-brands .card-slider-item {
    margin-right: 3.125rem;
    margin-bottom: 0
  }
}

@media (min-width:992px) {
  .card-slider-brands .card-slider-item {
    margin-right: 5rem
  }
}

.card-slider-brands:after {
  display: none;
  content: 'flickity'
}

.dropdown .dropdown-menu .dropdown-item {
  font-family: Roboto, sans-serif;
  letter-spacing: 0
}

.navbar .dropdown-menu.show {
  -webkit-animation: dropdown .2s ease forwards;
  animation: dropdown .2s ease forwards
}

.dropdown.dropdown-flat .dropdown-menu {
  box-shadow: none
}

.dropdown.dropdown-flat .dropdown-menu.show {
  -webkit-animation: dropdown-reverse .2s ease forwards;
  animation: dropdown-reverse .2s ease forwards
}

.navbar .dropdown.dropdown-flat .dropdown-menu {
  margin-left: -1rem;
  margin-right: -1rem;
  padding-top: .5rem;
  padding-bottom: .5rem
}

.dropdown-flat.dropdown-flat-primary .dropdown-menu {
  background-color: #3db36b
}

.dropdown-flat.dropdown-flat-primary .dropdown-menu .dropdown-divider {
  border-color: #5cc786
}

.dropdown-flat.dropdown-flat-primary .dropdown-menu .dropdown-item {
  color: #fff
}

.dropdown-flat.dropdown-flat-primary .dropdown-menu .dropdown-item:active,
.dropdown-flat.dropdown-flat-primary .dropdown-menu .dropdown-item:focus,
.dropdown-flat.dropdown-flat-primary .dropdown-menu .dropdown-item:hover {
  color: #fff;
  background: #5cc786
}

.dropdown-flat.dropdown-flat-secondary .dropdown-menu {
  background-color: #f9f9f9
}

.dropdown-flat.dropdown-flat-secondary .dropdown-menu .dropdown-divider {
  border-color: #fff
}

.dropdown-flat.dropdown-flat-secondary .dropdown-menu .dropdown-item {
  color: #212529
}

.dropdown-flat.dropdown-flat-secondary .dropdown-menu .dropdown-item:active,
.dropdown-flat.dropdown-flat-secondary .dropdown-menu .dropdown-item:focus,
.dropdown-flat.dropdown-flat-secondary .dropdown-menu .dropdown-item:hover {
  color: #212529;
  background: #fff
}

.dropdown-flat.dropdown-flat-success .dropdown-menu {
  background-color: #9DC41E
}

.dropdown-flat.dropdown-flat-success .dropdown-menu .dropdown-divider {
  border-color: #34ce57
}

.dropdown-flat.dropdown-flat-success .dropdown-menu .dropdown-item {
  color: #fff
}

.dropdown-flat.dropdown-flat-success .dropdown-menu .dropdown-item:active,
.dropdown-flat.dropdown-flat-success .dropdown-menu .dropdown-item:focus,
.dropdown-flat.dropdown-flat-success .dropdown-menu .dropdown-item:hover {
  color: #fff;
  background: #34ce57
}

.dropdown-flat.dropdown-flat-info .dropdown-menu {
  background-color: #4184f3
}

.dropdown-flat.dropdown-flat-info .dropdown-menu .dropdown-divider {
  border-color: #71a3f6
}

.dropdown-flat.dropdown-flat-info .dropdown-menu .dropdown-item {
  color: #fff
}

.dropdown-flat.dropdown-flat-info .dropdown-menu .dropdown-item:active,
.dropdown-flat.dropdown-flat-info .dropdown-menu .dropdown-item:focus,
.dropdown-flat.dropdown-flat-info .dropdown-menu .dropdown-item:hover {
  color: #fff;
  background: #71a3f6
}

.dropdown-flat.dropdown-flat-warning .dropdown-menu {
  background-color: #fdb813
}

.dropdown-flat.dropdown-flat-warning .dropdown-menu .dropdown-divider {
  border-color: #fdc746
}

.dropdown-flat.dropdown-flat-warning .dropdown-menu .dropdown-item {
  color: #212529
}

.dropdown-flat.dropdown-flat-warning .dropdown-menu .dropdown-item:active,
.dropdown-flat.dropdown-flat-warning .dropdown-menu .dropdown-item:focus,
.dropdown-flat.dropdown-flat-warning .dropdown-menu .dropdown-item:hover {
  color: #212529;
  background: #fdc746
}

.dropdown-flat.dropdown-flat-danger .dropdown-menu {
  background-color: #dc3545
}

.dropdown-flat.dropdown-flat-danger .dropdown-menu .dropdown-divider {
  border-color: #e4606d
}

.dropdown-flat.dropdown-flat-danger .dropdown-menu .dropdown-item {
  color: #fff
}

.dropdown-flat.dropdown-flat-danger .dropdown-menu .dropdown-item:active,
.dropdown-flat.dropdown-flat-danger .dropdown-menu .dropdown-item:focus,
.dropdown-flat.dropdown-flat-danger .dropdown-menu .dropdown-item:hover {
  color: #fff;
  background: #e4606d
}

.dropdown-flat.dropdown-flat-light .dropdown-menu {
  background-color: #efefef
}

.dropdown-flat.dropdown-flat-light .dropdown-menu .dropdown-divider {
  border-color: #fff
}

.dropdown-flat.dropdown-flat-light .dropdown-menu .dropdown-item {
  color: #212529
}

.dropdown-flat.dropdown-flat-light .dropdown-menu .dropdown-item:active,
.dropdown-flat.dropdown-flat-light .dropdown-menu .dropdown-item:focus,
.dropdown-flat.dropdown-flat-light .dropdown-menu .dropdown-item:hover {
  color: #212529;
  background: #fff
}

.dropdown-flat.dropdown-flat-dark .dropdown-menu {
  background-color: #343a40
}

.dropdown-flat.dropdown-flat-dark .dropdown-menu .dropdown-divider {
  border-color: #4b545c
}

.dropdown-flat.dropdown-flat-dark .dropdown-menu .dropdown-item {
  color: #fff
}

.dropdown-flat.dropdown-flat-dark .dropdown-menu .dropdown-item:active,
.dropdown-flat.dropdown-flat-dark .dropdown-menu .dropdown-item:focus,
.dropdown-flat.dropdown-flat-dark .dropdown-menu .dropdown-item:hover {
  color: #fff;
  background: #4b545c
}

@-webkit-keyframes dropdown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(1rem);
    transform: translateY(1rem)
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
}

@keyframes dropdown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(1rem);
    transform: translateY(1rem)
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
}

@-webkit-keyframes dropdown-reverse {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-1rem);
    transform: translateY(-1rem)
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
}

@keyframes dropdown-reverse {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-1rem);
    transform: translateY(-1rem)
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
}

.tab-content>.tab-pane:not(.active) {
  display: none !important
}

.navbar {
  font-family: Arimo, sans-serif;
  letter-spacing: .125rem;
  font-weight: 700;
  font-size: .75rem;
  line-height: 1.5
}

.navbar .navbar-brand {
  font-family: Roboto, sans-serif;
  letter-spacing: 0
}

.navbar .navbar-nav {
  margin-top: 1.125rem
}

.navbar .nav-link {
  transition: all .2s ease-in-out;
  outline: 0
}

@media (prefers-reduced-motion:reduce) {
  .navbar .nav-link {
    transition: none
  }
}

.navbar .navbar-spacer {
  width: 2.5rem;
  font-size: 0
}

.navbar .dropdown .dropdown-item {
  color: inherit;
  font-family: Arimo, sans-serif;
  letter-spacing: .125rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: .75rem
}

.navbar-dark .nav-item.dropdown .dropdown-toggle::after {
  transition: all .2s ease-in-out;
  color: rgba(255, 255, 255, .5)
}

@media (prefers-reduced-motion:reduce) {
  .navbar-dark .nav-item.dropdown .dropdown-toggle::after {
    transition: none
  }
}

.navbar-dark .nav-item.dropdown.show .dropdown-toggle::after {
  color: #fff;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg)
}

.navbar-expand {
  padding-top: 0;
  padding-bottom: 0
}

.navbar-expand .navbar-nav .nav-link,
.navbar-expand .navbar-text {
  padding: 2rem 0
}

.navbar-expand .navbar-nav {
  margin: 0 -1.25rem
}

.navbar-expand .navbar-nav .nav-link {
  position: relative;
  padding-left: 1.25rem;
  padding-right: 1.25rem
}

.navbar-expand .navbar-nav .nav-link:before {
  transition: all .2s ease-in-out;
  position: absolute;
  content: '';
  height: .1875rem;
  left: 1.25rem;
  right: 1.25rem;
  bottom: 0;
  background: 0 0
}

@media (prefers-reduced-motion:reduce) {
  .navbar-expand .navbar-nav .nav-link:before {
    transition: none
  }
}

.navbar-expand .navbar-nav .nav-item.active .nav-link:before,
.navbar-expand .navbar-nav .nav-link.active:before,
.navbar-expand .navbar-nav .nav-link:hover:before {
  background-color: #3db36b
}

@media (min-width:576px) {
  .navbar-expand-sm {
    padding-top: 0;
    padding-bottom: 0
  }

  .navbar-expand-sm .navbar-nav .nav-link,
  .navbar-expand-sm .navbar-text {
    padding: 2.25rem 0
  }

  .navbar-expand-sm .navbar-nav {
    margin: 0 -1.25rem
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    position: relative;
    padding-left: 1.25rem;
    padding-right: 1.25rem
  }

  .navbar-expand-sm .navbar-nav .nav-link:before {
    transition: all .2s ease-in-out;
    position: absolute;
    content: '';
    height: .1875rem;
    left: 1.25rem;
    right: 1.25rem;
    bottom: 0;
    background: 0 0
  }
}

@media (min-width:576px) and (prefers-reduced-motion:reduce) {
  .navbar-expand-sm .navbar-nav .nav-link:before {
    transition: none
  }
}

@media (min-width:576px) {

  .navbar-expand-sm .navbar-nav .nav-item.active .nav-link:before,
  .navbar-expand-sm .navbar-nav .nav-link.active:before,
  .navbar-expand-sm .navbar-nav .nav-link:hover:before {
    background-color: #3db36b
  }
}

@media (min-width:768px) {
  .navbar-expand-md {
    padding-top: 0;
    padding-bottom: 0
  }

  .navbar-expand-md .navbar-nav .nav-link,
  .navbar-expand-md .navbar-text {
    padding: 2rem 0
  }

  .navbar-expand-md .navbar-nav {
    margin: 0 -1.25rem
  }

  .navbar-expand-md .navbar-nav .nav-link {
    position: relative;
    padding-left: 1.25rem;
    padding-right: 1.25rem
  }

  .navbar-expand-md .navbar-nav .nav-link:before {
    transition: all .2s ease-in-out;
    position: absolute;
    content: '';
    height: .1875rem;
    left: 1.25rem;
    right: 1.25rem;
    bottom: 0;
    background: 0 0
  }
}

@media (min-width:768px) and (prefers-reduced-motion:reduce) {
  .navbar-expand-md .navbar-nav .nav-link:before {
    transition: none
  }
}

@media (min-width:768px) {

  .navbar-expand-md .navbar-nav .nav-item.active .nav-link:before,
  .navbar-expand-md .navbar-nav .nav-link.active:before,
  .navbar-expand-md .navbar-nav .nav-link:hover:before {
    background-color: #3db36b
  }
}

@media (min-width:992px) {
  .navbar-expand-lg {
    padding-top: 0;
    padding-bottom: 0
  }

  .navbar-expand-lg .navbar-nav .nav-link,
  .navbar-expand-lg .navbar-text {
    padding: 2em 0
  }

  .navbar-expand-lg .navbar-nav {
    margin: 0 -1.25rem
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    position: relative;
    padding-left: 1.25rem;
    padding-right: 1.25rem
  }

  .navbar-expand-lg .navbar-nav .nav-link:before {
    transition: all .2s ease-in-out;
    position: absolute;
    content: '';
    height: .1875rem;
    left: 1.25rem;
    right: 1.25rem;
    bottom: 0;
    background: 0 0
  }
}

@media (min-width:992px) and (prefers-reduced-motion:reduce) {
  .navbar-expand-lg .navbar-nav .nav-link:before {
    transition: none
  }
}

@media (min-width:992px) {

  .navbar-expand-lg .navbar-nav .nav-item.active .nav-link:before,
  .navbar-expand-lg .navbar-nav .nav-link.active:before,
  .navbar-expand-lg .navbar-nav .nav-link:hover:before {
    background-color: #3db36b
  }
}

@media (min-width:1200px) {
  .navbar-expand-xl {
    padding-top: 0;
    padding-bottom: 0
  }

  .navbar-expand-xl .navbar-nav .nav-link,
  .navbar-expand-xl .navbar-text {
    padding: 2rem 0
  }

  .navbar-expand-xl .navbar-nav {
    margin: 0 -1.25rem
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    position: relative;
    padding-left: 1.25rem;
    padding-right: 1.25rem
  }

  .navbar-expand-xl .navbar-nav .nav-link:before {
    transition: all .2s ease-in-out;
    position: absolute;
    content: '';
    height: .1875rem;
    left: 1.25rem;
    right: 1.25rem;
    bottom: 0;
    background: 0 0
  }
}

@media (min-width:1200px) and (prefers-reduced-motion:reduce) {
  .navbar-expand-xl .navbar-nav .nav-link:before {
    transition: none
  }
}

@media (min-width:1200px) {

  .navbar-expand-xl .navbar-nav .nav-item.active .nav-link:before,
  .navbar-expand-xl .navbar-nav .nav-link.active:before,
  .navbar-expand-xl .navbar-nav .nav-link:hover:before {
    background-color: #3db36b
  }
}

.navbar-short.navbar-expand {
  padding-top: .5rem;
  padding-bottom: .5rem
}

.navbar-short.navbar-expand,
.navbar-short.navbar-expand .navbar-collapse {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

.navbar-short.navbar-expand .navbar-nav {
  margin: 0 -.625rem;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

.navbar-short.navbar-expand .navbar-nav .nav-item {
  padding: 0 .625rem
}

.navbar-short.navbar-expand .navbar-nav .nav-item:before {
  display: none
}

.navbar-short.navbar-expand .navbar-nav .dropdown-menu {
  margin-left: .625rem;
  margin-right: .625rem
}

.navbar-short.navbar-expand .navbar-nav .dropdown.dropdown-flat .dropdown-menu {
  margin-left: -.375rem;
  margin-right: -.375rem
}

.navbar-short.navbar-expand .navbar-nav .nav-link,
.navbar-short.navbar-expand .navbar-text {
  padding: .5rem 0
}

@media (min-width:576px) {
  .navbar-short.navbar-expand-sm {
    padding-top: .5rem;
    padding-bottom: .5rem
  }

  .navbar-short.navbar-expand-sm,
  .navbar-short.navbar-expand-sm .navbar-collapse {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
  }

  .navbar-short.navbar-expand-sm .navbar-nav {
    margin: 0 -.625rem;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
  }

  .navbar-short.navbar-expand-sm .navbar-nav .nav-item {
    padding: 0 .625rem
  }

  .navbar-short.navbar-expand-sm .navbar-nav .nav-item:before {
    display: none
  }

  .navbar-short.navbar-expand-sm .navbar-nav .dropdown-menu {
    margin-left: .625rem;
    margin-right: .625rem
  }

  .navbar-short.navbar-expand-sm .navbar-nav .dropdown.dropdown-flat .dropdown-menu {
    margin-left: -.375rem;
    margin-right: -.375rem
  }

  .navbar-short.navbar-expand-sm .navbar-nav .nav-link,
  .navbar-short.navbar-expand-sm .navbar-text {
    padding: .5rem 0
  }
}

@media (min-width:768px) {
  .navbar-short.navbar-expand-md {
    padding-top: .5rem;
    padding-bottom: .5rem
  }

  .navbar-short.navbar-expand-md,
  .navbar-short.navbar-expand-md .navbar-collapse {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
  }

  .navbar-short.navbar-expand-md .navbar-nav {
    margin: 0 -.625rem;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
  }

  .navbar-short.navbar-expand-md .navbar-nav .nav-item {
    padding: 0 .625rem
  }

  .navbar-short.navbar-expand-md .navbar-nav .nav-item:before {
    display: none
  }

  .navbar-short.navbar-expand-md .navbar-nav .dropdown-menu {
    margin-left: .625rem;
    margin-right: .625rem
  }

  .navbar-short.navbar-expand-md .navbar-nav .dropdown.dropdown-flat .dropdown-menu {
    margin-left: -.375rem;
    margin-right: -.375rem
  }

  .navbar-short.navbar-expand-md .navbar-nav .nav-link,
  .navbar-short.navbar-expand-md .navbar-text {
    padding: .5rem 0
  }
}

@media (min-width:992px) {
  .navbar-short.navbar-expand-lg {
    padding-top: .5rem;
    padding-bottom: .5rem
  }

  .navbar-short.navbar-expand-lg,
  .navbar-short.navbar-expand-lg .navbar-collapse {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
  }

  .navbar-short.navbar-expand-lg .navbar-nav {
    margin: 0 -.625rem;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
  }

  .navbar-short.navbar-expand-lg .navbar-nav .nav-item {
    padding: 0 .625rem
  }

  .navbar-short.navbar-expand-lg .navbar-nav .nav-item:before {
    display: none
  }

  .navbar-short.navbar-expand-lg .navbar-nav .dropdown-menu {
    margin-left: .625rem;
    margin-right: .625rem
  }

  .navbar-short.navbar-expand-lg .navbar-nav .dropdown.dropdown-flat .dropdown-menu {
    margin-left: -.375rem;
    margin-right: -.375rem
  }

  .navbar-short.navbar-expand-lg .navbar-nav .nav-link,
  .navbar-short.navbar-expand-lg .navbar-text {
    padding: .5rem 0
  }
}

@media (min-width:1200px) {
  .navbar-short.navbar-expand-xl {
    padding-top: .5rem;
    padding-bottom: .5rem
  }

  .navbar-short.navbar-expand-xl,
  .navbar-short.navbar-expand-xl .navbar-collapse {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
  }

  .navbar-short.navbar-expand-xl .navbar-nav {
    margin: 0 -.625rem;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
  }

  .navbar-short.navbar-expand-xl .navbar-nav .nav-item {
    padding: 0 .625rem
  }

  .navbar-short.navbar-expand-xl .navbar-nav .nav-item:before {
    display: none
  }

  .navbar-short.navbar-expand-xl .navbar-nav .dropdown-menu {
    margin-left: .625rem;
    margin-right: .625rem
  }

  .navbar-short.navbar-expand-xl .navbar-nav .dropdown.dropdown-flat .dropdown-menu {
    margin-left: -.375rem;
    margin-right: -.375rem
  }

  .navbar-short.navbar-expand-xl .navbar-nav .nav-link,
  .navbar-short.navbar-expand-xl .navbar-text {
    padding: .5rem 0
  }
}

.navbar-short .nav-link:before {
  display: none
}

.navbar-short .navbar-spacer {
  width: 1.25rem
}

.hero {
  position: relative;
  overflow: hidden
}

.hero-fullscreen {
  max-height: 70vh
}

.hero-bg-white {
  background: #fff
}

.hero-bg-secondary {
  background: #f9f9f9
}

.hero-background,
.hero-background-overlay,
.hero-foreground {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10
}

.hero-foreground {
  transition: all .75s ease-in-out;
  transition-delay: .25s
}

.hero:not(.hero-bg-ready) .hero-foreground {
  opacity: 0;
  -webkit-transform: translate3d(0, -1rem, 0);
  transform: translate3d(0, -1rem, 0)
}

.hero-background {
  z-index: 5;
  visibility: hidden
}

.hero.hero-bg-ready .hero-background {
  -webkit-animation: hero-bg-reveal 1s;
  animation: hero-bg-reveal 1s;
  visibility: visible
}

.hero-background.hero-background-stick-right {
  right: 0;
  left: auto
}

.hero-background.hero-background-stick-left {
  left: 0;
  right: auto
}

@media (min-width:992px) {
  .hero-background.hero-background-70-percent {
    width: 70%
  }
}

.hero-background img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  font-family: "object-fit:cover";
  object-fit: cover
}

.hero-background-overlay {
  z-index: 6
}

.hero.hero-bg-ready .hero-foreground-gradient-black-bottom {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, .5) 0, rgba(0, 0, 0, 0) 100%)
}

.hero.hero-bg-ready .hero-foreground-gradient-white-bottom {
  background-image: linear-gradient(0deg, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%)
}

.hero.hero-bg-ready .hero-foreground-gradient-primary-bottom {
  background-image: linear-gradient(0deg, rgba(61, 179, 107, .5) 0, rgba(61, 179, 107, 0) 100%)
}

.hero.hero-bg-ready .hero-foreground-gradient-black-left {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, .5) 0, rgba(0, 0, 0, 0) 100%)
}

.hero.hero-bg-ready .hero-foreground-gradient-white-left {
  background-image: linear-gradient(90deg, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%)
}

.hero.hero-bg-ready .hero-foreground-gradient-primary-left {
  background-image: linear-gradient(90deg, rgba(61, 179, 107, .5) 0, rgba(61, 179, 107, 0) 100%)
}

.hero.hero-bg-ready .hero-foreground-gradient-black-right {
  background-image: linear-gradient(-90deg, rgba(0, 0, 0, .5) 0, rgba(0, 0, 0, 0) 100%)
}

.hero.hero-bg-ready .hero-foreground-gradient-white-right {
  background-image: linear-gradient(-90deg, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%)
}

.hero.hero-bg-ready .hero-foreground-gradient-primary-right {
  background-image: linear-gradient(-90deg, rgba(61, 179, 107, .5) 0, rgba(61, 179, 107, 0) 100%)
}

.hero.hero-bg-ready .hero-foreground-gradient-black-top {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, .5) 0, rgba(0, 0, 0, 0) 100%)
}

.hero.hero-bg-ready .hero-foreground-gradient-white-top {
  background-image: linear-gradient(180deg, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%)
}

.hero.hero-bg-ready .hero-foreground-gradient-primary-top {
  background-image: linear-gradient(180deg, rgba(61, 179, 107, .5) 0, rgba(61, 179, 107, 0) 100%)
}

@-webkit-keyframes hero-bg-reveal {
  0% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

@keyframes hero-bg-reveal {
  0% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

.badge-pill {
  padding-right: 1.125rem;
  padding-left: 1.125rem;
  border-radius: 10rem
}

.badge-ghost {
  background: rgba(255, 255, 255, .2)
}

.price {
  display: -ms-flexbox;
  display: flex;
  font-size: 2.5rem;
  line-height: 1;
  margin: 0 -.25rem
}

.price-currency,
.price-value {
  font-family: Arimo, sans-serif
}

.price-currency,
.price-description,
.price-value {
  padding: 0 .25rem
}

.price-currency {
  font-size: 60%;
  -ms-flex-item-align: start;
  align-self: flex-start
}

.price-description {
  -ms-flex-item-align: end;
  align-self: flex-end;
  font-size: 45%
}

.ribbon-primary {
  background: #eff9f3;
  color: #3db36b
}

.ribbon-primary:after,
.ribbon-primary:before {
  border-color: #eff9f3
}

.ribbon-secondary {
  background: #fff;
  color: #f9f9f9
}

.ribbon-secondary:after,
.ribbon-secondary:before {
  border-color: #fff
}

.ribbon-success {
  background: #eef8f0;
  color: #9DC41E
}

.ribbon-success:after,
.ribbon-success:before {
  border-color: #eef8f0
}

.ribbon-info {
  background: #f0f5fe;
  color: #4184f3
}

.ribbon-info:after,
.ribbon-info:before {
  border-color: #f0f5fe
}

.ribbon-warning {
  background: #fff9ec;
  color: #fdb813
}

.ribbon-warning:after,
.ribbon-warning:before {
  border-color: #fff9ec
}

.ribbon-danger {
  background: #fceff0;
  color: #dc3545
}

.ribbon-danger:after,
.ribbon-danger:before {
  border-color: #fceff0
}

.ribbon-light {
  background: #fefefe;
  color: #efefef
}

.ribbon-light:after,
.ribbon-light:before {
  border-color: #fefefe
}

.ribbon-dark {
  background: #efeff0;
  color: #343a40
}

.ribbon-dark:after,
.ribbon-dark:before {
  border-color: #efeff0
}

.ribbon {
  position: relative;
  font-family: Arimo, sans-serif;
  letter-spacing: .125rem;
  font-size: .75rem;
  line-height: 2.5;
  text-transform: uppercase;
  font-weight: 700;
  padding: 0 1.125rem
}

.ribbon:after,
.ribbon:before {
  content: '';
  position: absolute;
  top: 0;
  border-width: 1.25em;
  border-style: solid;
  display: none
}

.ribbon:before {
  left: 0;
  margin-left: -1.25em;
  border-right-width: 1.25em;
  border-left-color: transparent
}

.ribbon:after {
  right: 0;
  margin-right: -1.25em;
  border-left-width: 1.25em;
  border-right-color: transparent
}

.ribbon-right {
  margin-right: 2.5em
}

.ribbon-right:after {
  display: block
}

.ribbon-left {
  margin-left: 2.5em
}

.ribbon-left:before {
  display: block
}

.ribbon-float-top-left {
  position: absolute;
  top: 0;
  left: 0
}

.ribbon-float-top-right {
  position: absolute;
  top: 0;
  right: 0
}

.ribbon-float-bottom-left {
  position: absolute;
  bottom: 0;
  left: 0
}

.ribbon-float-bottom-right {
  position: absolute;
  bottom: 0;
  right: 0
}

.big-quote {
  text-align: center;
  max-width: 35.9375rem;
  margin: 0 auto
}

.big-quote-icon {
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
  font-size: 2.25rem;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: .75rem;
  background: #3db36b;
  border-radius: 50%;
  color: #fff;
  margin-bottom: 2.5rem
}

.big-quote-icon>i {
  font-size: inherit
}

.big-quote-contents {
  font-size: 2.5rem;
  line-height: 1.25;
  font-weight: 300;
  font-style: italic
}

.big-quote-contents p {
  color: #000
}

.big-quote-footer {
  margin-top: 2.5rem;
  color: #9b9b9b;
  font-size: 1.125rem;
  font-weight: 400;
  font-style: normal;
  line-height: 1.5
}

.gmap {
  position: relative;
  overflow: hidden
}

.gmap-embed {
  position: relative;
  max-height: 100vh;
  overflow: hidden
}

.gmap-embed .gmap-embed-sizing-helper {
  display: block;
  padding-top: 56.25%
}

.gmap-embed .gmap-embed-inner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5
}

.gmap-infoboxes {
  position: absolute;
  visibility: hidden;
  opacity: 0
}

.gmap-infobox {
  background: #fff;
  border-radius: 3px;
  color: #000;
  margin-bottom: 1rem;
  font-size: .875rem;
  line-height: 1.625;
  box-shadow: 0 5px 30px 0 rgba(45, 66, 82, .1);
  width: 350px;
  max-width: calc(100vw - 75px);
  -webkit-animation: gmap-infobox .3s forwards;
  animation: gmap-infobox .3s forwards
}

.gmap-infobox-details {
  overflow: auto;
  max-height: 300px;
  padding: 0;
  color: #adb5bd;
  border-top: 1px solid #e9ecef
}

.gmap-infobox-details a {
  transition: none;
  color: #343a40;
  text-decoration: underline
}

.gmap-infobox-details-item {
  padding: 1rem
}

.gmap-infobox-details-item sub,
.gmap-infobox-details-item sup {
  left: .25em
}

.gmap-infobox-details-item+.gmap-infobox-details-item {
  margin-top: -1rem
}

.gmap-infobox-header {
  position: relative;
  padding: 1rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer
}

.gmap-infobox-header:before {
  content: '';
  position: absolute;
  top: 50%;
  right: 100%;
  height: 0;
  width: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border: 10px solid transparent;
  border-right: 10px solid #fff
}

.gmap-infobox-label {
  line-height: 1;
  margin: 0;
  font-size: 1.125rem
}

@-webkit-keyframes gmap-infobox {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }
}

@keyframes gmap-infobox {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }
}

.star-rating {
  color: #fdb813;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 1.25rem
}

.star-rating .material-icons {
  font-size: inherit
}

.faq {
  padding: 1.375rem 0;
  border-bottom: .0625rem solid #dee2e6
}

.faq-header {
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between
}

.faq-header .faq-title {
  font-weight: 400;
  font-size: 1.25rem;
  color: #4a4a4a;
  margin: 0 1.25rem 0 0
}

.faq-header .faq-toggle {
  line-height: 1
}

.faq-header .faq-toggle .material-icons {
  color: #9b9b9b
}

.faq-header .faq-title,
.faq-header .faq-toggle .material-icons {
  transition: all .2s ease-in-out
}

.faq-body:before {
  content: '';
  display: block;
  height: 1.125rem
}

.faq-header:hover .faq-title,
.faq-header:hover .faq-toggle .material-icons,
.faq-open .faq-title,
.faq-open .faq-toggle .material-icons {
  color: #3db36b
}

.faq:not(.faq-open) .faq-toggle-open {
  display: none
}

.faq.faq-open .faq-toggle-closed {
  display: none
}

.image-slider {
  position: relative;
  overflow: hidden
}

.image-slider .flickity-viewport {
  transition: height .5s
}

.image-slider-contents {
  position: relative;
  z-index: 10
}

.image-slider-item {
  width: 100%;
  height: auto
}

.image-slider-next,
.image-slider-prev {
  position: absolute;
  top: 50%;
  margin-top: -1.5625rem;
  z-index: 15;
  background: #fff;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 3.4375rem;
  height: 3.125rem;
  transition: all .2s ease-in-out
}

.image-slider-next,
.image-slider-next:hover,
.image-slider-prev,
.image-slider-prev:hover {
  color: #000
}

.image-slider-next.disabled,
.image-slider-prev.disabled {
  color: #9b9b9b
}

.image-slider-prev {
  left: -.3125rem;
  padding-left: .3125rem
}

.image-slider-prev:not(.disabled):hover {
  -webkit-transform: translateX(.3125rem);
  transform: translateX(.3125rem)
}

.image-slider-next {
  right: -.3125rem;
  padding-right: .3125rem
}

.image-slider-next:not(.disabled):hover {
  -webkit-transform: translateX(-.3125rem);
  transform: translateX(-.3125rem)
}

.jumbotron {
  padding: 3.125rem 1.5625rem
}

@media (max-width:991.98px) {
  .jumbotron {
    padding: 2.25rem 1.125rem
  }
}

.jumbotron-fluid {
  padding-left: 0;
  padding-right: 0
}

.jumbotron-title {
  font-size: 1.875rem;
  line-height: 1
}

@media (min-width:768px) {
  .jumbotron-title {
    font-size: 2.25rem
  }
}

@media (min-width:992px) {
  .jumbotron-title {
    font-size: 3.125rem
  }
}

.calendar-month-selector {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 1rem
}

.calendar-month-selector>.current-month {
  margin: 0 1.125rem
}

.calendar-weekdays-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  color: #343a40;
  margin-bottom: 1rem
}

.calendar-weekdays-container>.weekday {
  -ms-flex: 0 0 14.28571%;
  flex: 0 0 14.28571%
}

.calendar-dates-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-top: .0625rem solid #d3d3d3;
  border-left: .0625rem solid #d3d3d3
}

.calendar-dates-container>.calendar-date {
  -ms-flex: 0 0 calc(100%/7 - 1px);
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(100%/7 - 1px);
  box-sizing: content-box
}

.calendar-date {
  position: relative;
  border-right: .0625rem solid #d3d3d3;
  border-bottom: .0625rem solid #d3d3d3;
  color: #000;
  cursor: pointer;
  transition: all .2s ease-in-out;
  transition-duration: .1s
}

.calendar-date:before {
  content: '';
  display: block;
  padding-top: 75%
}

.calendar-date:after {
  content: '';
  position: absolute;
  top: -.0625rem;
  left: -.0625rem;
  bottom: -.0625rem;
  right: -.0625rem;
  border: .125rem solid #3db36b;
  z-index: 1;
  opacity: 0;
  transition: all .2s ease-in-out;
  transition-duration: .1s
}

.calendar-date>.calendar-date-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2
}

.calendar-date.disabled {
  cursor: default;
  font-weight: 400
}

.calendar-date.disabled>.calendar-date-content {
  color: #ced4da;
  pointer-events: none
}

.calendar-date.selected {
  color: #3db36b
}

.calendar-date.selected:after {
  opacity: 1
}

.calendar-date-content {
  text-align: right;
  padding: 1rem
}

.calendar-date-content>.day,
.calendar-date-content>.month-short {
  display: inline-block
}

.calendar-date-content>.month-short {
  margin-right: .3125rem;
  display: none
}

.calendar-date.current-month.last-date-of-month+.calendar-date .calendar-date-content>.month-short,
.calendar-date.prev-month.last-date-of-month+.calendar-date .calendar-date-content>.month-short,
.calendar-date.prev-month:first-child .calendar-date-content>.month-short {
  display: inline-block
}

@media (min-width:768px) {
  .calendar-native-input {
    position: absolute;
    left: -9999px;
    visibility: hidden;
    opacity: 0
  }
}

@media (max-width:767.98px) {

  .calendar-dates-container,
  .calendar-month-selector,
  .calendar-weekdays-container {
    display: none
  }
}

.time-slot {
  position: relative;
  display: block;
  width: 100%;
  background: 0 0;
  line-height: 1.375;
  border: .0625rem solid #d3d3d3;
  padding: .5rem;
  transition: all .2s ease-in-out
}

.time-slot:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: .0625rem solid transparent;
  z-index: -1;
  transition: all .2s ease-in-out
}

.time-slot.selected {
  border-color: #3db36b;
  color: #3db36b
}

.time-slot.selected:after {
  border-color: #3db36b
}

.shuffle-grid::after {
  display: block;
  clear: both;
  content: ""
}

.shuffle-grid-item {
  float: left
}

.shuffle-grid-gap-0 {
  margin: 0
}

.shuffle-grid-gap-0 .shuffle-grid-item {
  padding: 0
}

.shuffle-grid-gap-1 {
  margin: -.125rem
}

.shuffle-grid-gap-1 .shuffle-grid-item {
  padding: .125rem
}

.shuffle-grid-gap-2 {
  margin: -.1875rem
}

.shuffle-grid-gap-2 .shuffle-grid-item {
  padding: .1875rem
}

.shuffle-grid-gap-3 {
  margin: -.25rem
}

.shuffle-grid-gap-3 .shuffle-grid-item {
  padding: .25rem
}

.shuffle-grid-gap-4 {
  margin: -.3125rem
}

.shuffle-grid-gap-4 .shuffle-grid-item {
  padding: .3125rem
}

.shuffle-grid-gap-5 {
  margin: -.375rem
}

.shuffle-grid-gap-5 .shuffle-grid-item {
  padding: .375rem
}

.shuffle-grid-gap-6 {
  margin: -.4375rem
}

.shuffle-grid-gap-6 .shuffle-grid-item {
  padding: .4375rem
}

.shuffle-grid-gap-7 {
  margin: -.5rem
}

.shuffle-grid-gap-7 .shuffle-grid-item {
  padding: .5rem
}

.shuffle-grid-gap-8 {
  margin: -.5625rem
}

.shuffle-grid-gap-8 .shuffle-grid-item {
  padding: .5625rem
}

.shuffle-grid-gap-9 {
  margin: -.625rem
}

.shuffle-grid-gap-9 .shuffle-grid-item {
  padding: .625rem
}

.shuffle-grid-gap-10 {
  margin: -.6875rem
}

.shuffle-grid-gap-10 .shuffle-grid-item {
  padding: .6875rem
}

.shuffle-grid-gap-11 {
  margin: -.75rem
}

.shuffle-grid-gap-11 .shuffle-grid-item {
  padding: .75rem
}

.shuffle-grid-gap-12 {
  margin: -.8125rem
}

.shuffle-grid-gap-12 .shuffle-grid-item {
  padding: .8125rem
}

.shuffle-grid-gap-13 {
  margin: -.875rem
}

.shuffle-grid-gap-13 .shuffle-grid-item {
  padding: .875rem
}

.shuffle-grid-gap-14 {
  margin: -.9375rem
}

.shuffle-grid-gap-14 .shuffle-grid-item {
  padding: .9375rem
}

.shuffle-grid-gap-15 {
  margin: -1rem
}

.shuffle-grid-gap-15 .shuffle-grid-item {
  padding: 1rem
}

.shuffle-grid-gap-16 {
  margin: -1.0625rem
}

.shuffle-grid-gap-16 .shuffle-grid-item {
  padding: 1.0625rem
}

.shuffle-grid-gap-17 {
  margin: -1.125rem
}

.shuffle-grid-gap-17 .shuffle-grid-item {
  padding: 1.125rem
}

.shuffle-grid-gap-18 {
  margin: -1.1875rem
}

.shuffle-grid-gap-18 .shuffle-grid-item {
  padding: 1.1875rem
}

.shuffle-grid-gap-19 {
  margin: -1.25rem
}

.shuffle-grid-gap-19 .shuffle-grid-item {
  padding: 1.25rem
}

.shuffle-grid-gap-20 {
  margin: -1.5625rem
}

.shuffle-grid-gap-20 .shuffle-grid-item {
  padding: 1.5625rem
}

.shuffle-grid-gap-21 {
  margin: -1.875rem
}

.shuffle-grid-gap-21 .shuffle-grid-item {
  padding: 1.875rem
}

.shuffle-grid-gap-22 {
  margin: -2.1875rem
}

.shuffle-grid-gap-22 .shuffle-grid-item {
  padding: 2.1875rem
}

.shuffle-grid-gap-23 {
  margin: -2.5rem
}

.shuffle-grid-gap-23 .shuffle-grid-item {
  padding: 2.5rem
}

.shuffle-grid-gap-24 {
  margin: -2.8125rem
}

.shuffle-grid-gap-24 .shuffle-grid-item {
  padding: 2.8125rem
}

.shuffle-grid-gap-25 {
  margin: -3.125rem
}

.shuffle-grid-gap-25 .shuffle-grid-item {
  padding: 3.125rem
}

.shuffle-grid-cols-1 .shuffle-grid-item {
  width: 100%
}

.shuffle-grid-cols-2 .shuffle-grid-item {
  width: 50%
}

.shuffle-grid-cols-3 .shuffle-grid-item {
  width: 33.33333%
}

.shuffle-grid-cols-4 .shuffle-grid-item {
  width: 25%
}

@media (min-width:576px) {
  .shuffle-grid-cols-sm-1 .shuffle-grid-item {
    width: 100%
  }

  .shuffle-grid-cols-sm-2 .shuffle-grid-item {
    width: 50%
  }

  .shuffle-grid-cols-sm-3 .shuffle-grid-item {
    width: 33.33333%
  }

  .shuffle-grid-cols-sm-4 .shuffle-grid-item {
    width: 25%
  }
}

@media (min-width:768px) {
  .shuffle-grid-cols-md-1 .shuffle-grid-item {
    width: 100%
  }

  .shuffle-grid-cols-md-2 .shuffle-grid-item {
    width: 50%
  }

  .shuffle-grid-cols-md-3 .shuffle-grid-item {
    width: 33.33333%
  }

  .shuffle-grid-cols-md-4 .shuffle-grid-item {
    width: 25%
  }
}

@media (min-width:992px) {
  .shuffle-grid-cols-lg-1 .shuffle-grid-item {
    width: 100%
  }

  .shuffle-grid-cols-lg-2 .shuffle-grid-item {
    width: 50%
  }

  .shuffle-grid-cols-lg-3 .shuffle-grid-item {
    width: 33.33333%
  }

  .shuffle-grid-cols-lg-4 .shuffle-grid-item {
    width: 25%
  }
}

@media (min-width:1200px) {
  .shuffle-grid-cols-xl-1 .shuffle-grid-item {
    width: 100%
  }

  .shuffle-grid-cols-xl-2 .shuffle-grid-item {
    width: 50%
  }

  .shuffle-grid-cols-xl-3 .shuffle-grid-item {
    width: 33.33333%
  }

  .shuffle-grid-cols-xl-4 .shuffle-grid-item {
    width: 25%
  }
}

.sds-block {
  display: -ms-flexbox;
  display: flex;
  position: relative
}

@media (min-width:1200px) {
  .sds-block {
    padding: 3.125rem 0
  }
}

.sds-block-background {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 70%;
  z-index: 5;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat
}

@media (max-width:1199.98px) {
  .sds-block-background {
    display: none
  }
}

.sds-block-background.sds-block-background-left {
  left: 0
}

.sds-block-background.sds-block-background-right {
  right: 0
}

.sds-block-foreground {
  position: relative;
  z-index: 10;
  background: #fff;
  width: 45%;
  padding: 3.75rem 2.125rem 3.75rem 4.375rem
}

@media (max-width:1199.98px) {
  .sds-block-foreground {
    padding: 0 15px;
    width: 960px;
    margin: 0 auto
  }
}

@media (max-width:991.98px) {
  .sds-block-foreground {
    width: 720px
  }
}

@media (max-width:767.98px) {
  .sds-block-foreground {
    width: 540px
  }
}

@media (max-width:575.98px) {
  .sds-block-foreground {
    width: auto
  }
}

.sds-block-foreground.sds-block-foreground-left {
  margin-right: auto
}

.sds-block-foreground.sds-block-foreground-right {
  margin-left: auto
}

@media screen and (max-width: 767px) {
  .iconsSmallScreen {
    display: none;
  }
}


@media screen and (max-height: 484px) {
  .iconsSmallScreen {
    display: none;
  }
}

@media screen and (max-width: 387px) {
  .heroSmallScreen {
    font-size: 2.5rem;
  }

  .heroSmallScreen2 {
    font-size: medium;
  }
}

@media screen and (max-height: 460px) {
  .heroSmallScreen {
    font-size: 2.5rem;
  }

  .heroSmallScreen2 {
    font-size: medium;
  }
}

@media screen and (max-height: 375px) {
  .heroSmallScreen {
    font-size: 2rem;
  }

  .heroSmallScreen2 {
    font-size: small;
  }
}